const ArrivalAtMessageLine = ({ time, to }: { time: string, to: string }) => {



    return (
        <div className='d-flex justify-content-center align-items-center w-full' style={{ fontSize: "1.95vw", fontWeight: 400, whiteSpace: 'nowrap' }}>
            <b className="me-1">Arrival</b> at <b className='mx-1'>{time}</b> in <b className='mx-1' > {to}</b>
        </div>
    )
}

export default ArrivalAtMessageLine;