import { Vector3 } from 'three';
import Constants from './constants.js';
import { LngLatLike } from 'maplibre-gl';
import { Position } from '@turf/turf';

export function projectToWorld(coords: Position) {
  var projected = [
    -Constants.MERCATOR_A *
      Constants.DEG2RAD *
      coords[0] *
      Constants.PROJECTION_WORLD_SIZE,
    -Constants.MERCATOR_A *
      Math.log(Math.tan(Math.PI * 0.25 + 0.5 * Constants.DEG2RAD * coords[1])) *
      Constants.PROJECTION_WORLD_SIZE,
  ];

  if (!coords[2]) projected.push(0);
  else {
    var pixelsPerMeter = projectedUnitsPerMeter(coords[1]);
    projected.push(coords[2] * pixelsPerMeter);
  }

  var result = new Vector3(projected[0], projected[1], projected[2]);

  return result;
}

function projectedUnitsPerMeter(latitude: number) {
  return Math.abs(
    Constants.WORLD_SIZE /
      Math.cos(Constants.DEG2RAD * latitude) /
      Constants.EARTH_CIRCUMFERENCE,
  );
}

export function unprojectFromWorld(worldUnits: Vector3) {
  var unprojected = [
    -worldUnits.x /
      (Constants.MERCATOR_A *
        Constants.DEG2RAD *
        Constants.PROJECTION_WORLD_SIZE),
    (2 *
      (Math.atan(
        Math.exp(
          worldUnits.y /
            (Constants.PROJECTION_WORLD_SIZE * -Constants.MERCATOR_A),
        ),
      ) -
        Math.PI / 4)) /
      Constants.DEG2RAD,
  ];

  var pixelsPerMeter = projectedUnitsPerMeter(unprojected[1]);

  //z dimension
  var height = worldUnits.z || 0;
  unprojected.push(height / pixelsPerMeter);

  return unprojected as LngLatLike;
}
