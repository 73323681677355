import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ActionsCreator from '~redux/actions';
import useStyles from './style';
import { LoadingButton } from '@mui/lab';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from '~/redux/reducers';
import { useAuth } from '~/managers/AuthContext';
import { RootState } from '~/redux/reducers';
import CustomSnackbar from '../CustomSnackbar';
import { useDispatch } from '~redux/store';

interface UserData {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  profilePicture: File | null;
}

/**
 * Settings component for user profile updates.
 * @component
 * @returns {JSX.Element} Settings component
 */
const Settings = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { updateUserData } = useAuth();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isDigit, setIsDigit] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [isMinimum8Character, setIsMinimum8Character] = useState(false);
  const [isPasswordInput, setIsPasswordInput] = useState(false);
  const [selectedProfilePicture, setSelectedProfilePicture] =
    useState<File | null>(null);

  const userEmail: string = useSelector(
    (state: RootState) => state.MapReducers.userEmail,
  );

  const profilePictureURL: string = useSelector(
    (state: RootState) => state.MapReducers.profileImgURL,
  );

  const initialState: UserData = {
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
    profilePicture: null,
  };

  const [formData, setFormData] = useState(initialState);
  const [formUpdated, setFormUpdated] = useState(false);
  /**
   * Navigates to the homepage.
   */
  const handleCloseSettingsPage = () => {
    navigate('/homepage');
  };

  /**
   * useEffect to set the default profile picture if none is provided.
   */
  useEffect(() => {
    if (!profilePictureURL) {
      const defaultProfilePicture = '/icons/croppedLogoVizualTravel.svg';
      fetch(defaultProfilePicture)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], 'defaultProfilePicture.svg', {
            type: blob.type,
          });
          setSelectedProfilePicture(file);
        });
    }
  }, [profilePictureURL]);
  /**
   * Handles password input change.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    const newPassword = event.target.value;

    setPassword(newPassword);
    setIsPasswordInput(newPassword.length > 0);
    const isValid = passwordRegex.test(newPassword);
    setIsLowercase(/[a-z]/.test(newPassword));
    setIsUppercase(/[A-Z]/.test(newPassword));
    setIsDigit(/\d/.test(newPassword));
    setIsSpecialCharacter(/[!@#$%^&*()_+]/.test(newPassword));
    setIsMinimum8Character(newPassword.length >= 8);
    if (isValid) {
      setFormData({ ...formData, password: event.target.value });
    }
  };

  /**
   * Handles confirm password input change.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(event.target.value);
    if (
      password === event.target.value ||
      formData.fullName ||
      formData.profilePicture
    ) {
      setFormUpdated(true);
    } else setFormUpdated(false);

    setFormData({
      ...formData,
      confirmPassword: event.target.value,
    });
  };

  /**
   * Handles form submission for updating user data.
   * @param {React.FormEvent} event - The form submit event.
   */

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const isPasswordValid =
      isLowercase &&
      isUppercase &&
      isDigit &&
      isSpecialCharacter &&
      isMinimum8Character;

    if (isPasswordValid || (!password && !confirmPassword)) {
      if (password === confirmPassword) {
        try {
          const response = await updateUserData(formData);

          if (response.success === true) {
            dispatch(
              ActionsCreator.setSnackbarMessage(
                'User data updated successfully',
              ),
            );
            dispatch(ActionsCreator.setSnackbarSeverity(true));
            dispatch(ActionsCreator.setSnackbarOpen(true));
            ActionsCreator.setUserProfileImageURL(
              response.data.user.user_metadata.profile_picture,
            );
            ActionsCreator.setUserName(
              response.data.user.user_metadata.full_name,
            );
            setFormData({
              ...initialState,
              email: formData.email,
            });
            setConfirmPassword('');
            setPassword('');
            setIsPasswordInput(false);
            setIsLowercase(false);
            setIsUppercase(false);
            setIsDigit(false);
            setIsSpecialCharacter(false);
            setIsMinimum8Character(false);
            setFormUpdated(false);
          } else {
            dispatch(ActionsCreator.setSnackbarMessage(response.error));
            dispatch(ActionsCreator.setSnackbarSeverity(false));
            dispatch(ActionsCreator.setSnackbarOpen(true));
          }
        } catch (error) {
          dispatch(
            ActionsCreator.setSnackbarMessage(
              'An error occurred while updating user data',
            ),
          );
          dispatch(ActionsCreator.setSnackbarSeverity(false));
          dispatch(ActionsCreator.setSnackbarOpen(true));
        }
      } else {
        setLoading(false);
        dispatch(ActionsCreator.setSnackbarMessage('Passwords do not match'));
        dispatch(ActionsCreator.setSnackbarSeverity(false));
        dispatch(ActionsCreator.setSnackbarOpen(true));
      }
    } else {
      setLoading(false);
      dispatch(
        ActionsCreator.setSnackbarMessage('Passwords is not strong enough'),
      );
      dispatch(ActionsCreator.setSnackbarSeverity(false));
      dispatch(ActionsCreator.setSnackbarOpen(true));
    }

    setLoading(false);
  };

  /**
   * Handles profile picture change.
   * @param event - The file input change event.
   */

  const handleProfilePictureChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];

    setSelectedProfilePicture(file as File);

    setFormData((prevFormData) => ({
      ...prevFormData,
      profilePicture: file as File,
    }));
    setFormUpdated(true);
  };

  return (
    <Box className={classes.mainContainer}>
      <CustomSnackbar handleClosePopup={() => {}} />

      <div>
        <Typography className={classes.title}>Settings</Typography>
        <Button
          style={{ fontFamily: 'Futura Md BT' }}
          className={classes.goBackButton}
          onClick={handleCloseSettingsPage}
        >
          {' '}
          Go Back
        </Button>

        <Divider variant="middle" className={classes.divider} />
      </div>
      <Box className={classes.containerBox}>
        <Container
          className={classes.container}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          maxWidth="md"
        >
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.topAdjustment}>
              <input
                type="file"
                accept="image/*"
                id="profilePictureInput"
                style={{ display: 'none' }}
                onChange={handleProfilePictureChange}
              />
              <label
                htmlFor="profilePictureInput"
                className={classes.imageContainer}
              >
                <div className={classes.circularImageContainer}>
                  <img
                    className={classes.circularImage}
                    src={
                      selectedProfilePicture
                        ? URL.createObjectURL(selectedProfilePicture)
                        : profilePictureURL
                    }
                    alt="Profile"
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.changePicture}
                  // onClick={handleProfilePictureChange}
                >
                  Change Picture
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.itemClass}>
              <Typography className={classes.headings}>Full Name</Typography>
              <TextField
                className={classes.textField}
                autoComplete="given-name"
                name="fullName"
                id="fullName"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                value={formData.fullName}
                onChange={(event) => {
                  // Replace multiple spaces with a single space
                  const sanitizedValue = event.target.value.replace(
                    /\s+/g,
                    ' ',
                  );

                  // Update the fullName state on change
                  setFormData({ ...formData, fullName: sanitizedValue });

                  // Set formUpdated to true if there is at least one non-space character typed
                  setFormUpdated(sanitizedValue.trim().length > 0);

                  // if (
                  //   (sanitizedValue.trim().length === 0 &&
                  //     password === confirmPassword) ||
                  //   formData.profilePicture
                  // ) {
                  //   setFormUpdated(true);
                  // }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.itemClass}>
              <Typography className={classes.headings}>Email</Typography>
              <TextField
                className={classes.textField}
                autoComplete="email"
                name="email"
                id="email"
                fullWidth
                value={userEmail}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.itemClass}>
              <Typography className={classes.headings}>New Password</Typography>
              <TextField
                className={classes.textField}
                name="password"
                type="password"
                id="password"
                fullWidth
                autoComplete="new-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={handlePasswordChange}
              />
              {isPasswordInput && (
                <Box className={classes.passwordChecklist}>
                  {[
                    { label: 'One lowercase letter', checked: isLowercase },
                    { label: 'One uppercase letter', checked: isUppercase },
                    { label: 'One digit', checked: isDigit },
                    {
                      label: 'One special character',
                      checked: isSpecialCharacter,
                    },
                    {
                      label: 'Minimum 8 characters',
                      checked: isMinimum8Character,
                    },
                  ].map((item, index) => (
                    <Box key={index} className={classes.checklistItem}>
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={item.checked}
                        className={
                          item.checked ? classes.check : classes.uncheck
                        }
                        disableRipple
                      />
                      <Typography className={classes.checklistItemText}>
                        {item.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.itemClass}>
              <Typography className={classes.headings}>
                Confirm New Password
              </Typography>
              <TextField
                className={classes.textField}
                name="password2"
                type="password"
                id="password2"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
              />
            </Grid>
          </Grid>
          <LoadingButton
            className={classes.saveButton}
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
            disabled={!formUpdated}
          >
            Save
          </LoadingButton>
        </Container>
      </Box>
    </Box>
  );
};

export default Settings;
