import { ModelConfigProps } from '~/utility/models';
/**
 * An enumeration of various model types, potentially used for 3D objects or map markers.
 * Each member represents a specific model type with a corresponding numeric value for potential use in calculations or comparisons.
 */
export enum TransitModels {
  /** Represents a map pointer, potentially used for location identification on a map. */
  Train = 'Train',
  Bus = 'Bus',
  Subway = 'Subway',
  Metro = 'Metro',
  Tram = 'Tram',
}

export const transitModelsConfiguration = new Map<
  TransitModels,
  ModelConfigProps
>([
  [
    TransitModels.Train,
    {
      name: 'train.glb',
      path: './models/train.glb',
      UIname: 'Train',
    },
  ],
  [
    TransitModels.Bus,
    {
      name: 'Bus.glb',
      path: './models/Bus.glb',
      UIname: 'Bus',
    },
  ],
  [
    TransitModels.Subway,
    {
      name: 'metro sign.glb',
      path: './models/metro sign.glb',
      UIname: 'Subway',
    },
  ],
  [
    TransitModels.Tram,
    {
      name: 'tram.glb',
      path: './models/tram.glb',
      UIname: 'Tram',
    },
  ],
  [
    TransitModels.Metro,
    {
      name: 'metro sign.glb',
      path: './models/metro sign.glb',
      UIname: 'Metro',
    },
  ],
]);
