import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { gsap } from 'gsap';
import { Map } from 'maplibre-gl';
import * as THREE from 'three';
import { Text } from 'troika-three-text';
import ResourceLoader from './ResourceLoader';
import { getScaleFromZoom } from './utility/utils';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { FormData, Model } from '~/utility/models';
import { MarkerModels } from './utility/enums/MarkerModels';

dayjs.extend(advancedFormat);

interface ChildWithOpacity {
  mesh: THREE.Mesh; // Assuming THREE is imported from three.js
  identifier: string;
}

type Dimensions = {
  group: THREE.Group;
  width: number;
  height: number;
};

/**
 * Represents a 3D marker on a map, with associated text and animations.
 */
class Marker {
  /** Text content of the marker. */
  text: string;

  /** 3D coordinates of the marker in the scene. */
  coordinates: THREE.Vector3;

  /** GSAP timeline used for animating the marker. */
  animationTimeline: GSAPTimeline;

  /** Three.js group containing the text elements of the marker. */
  // textContainer!: THREE.Group | null;

  /** Custom wrapper class for Three.js functionality. */
  tb: CustomThreeJSWrapper;

  /** Map object (likely a custom map implementation). */
  map: Map;

  /** Static render order for the marker (used for sorting during rendering). */
  static renderOrder: number = 0;

  /** Three.js group containing the model or visual representation of the marker. */
  markerContainer!: THREE.Group | null;

  /** Temporary vector used for scaling purposes. */
  tempScaleVector: THREE.Vector3;

  /** Temporary vector used for linear interpolation. */
  tempLerpVec: THREE.Vector3 = new THREE.Vector3();

  /** Marker's individual render order within its layer (potentially for further sorting). */
  myRenderOrder: number;

  /** Array of child elements that have opacity properties for efficient handling. */
  childrenWithOpacity: ChildWithOpacity[] = [];

  /** Three.js group containing the model of the marker (if applicable). */
  modelGroup!: THREE.Group;

  /** FormData object containing time-related data for the marker. */
  timeData!: FormData;

  /** Type of marker (e.g., "text", "icon", "3D model"). */
  type: string;

  /** Three.js mesh or object representing the marker's model (if applicable). */
  model!: THREE.Object3D;

  /** Index of the marker within its collection (potentially for identification). */
  index: number;

  /** Height of the marker (likely in world units). */
  height!: number;

  /** Width of the marker (likely in world units). */
  width!: number;

  /** Position of the cloned text container (used for text manipulation). */
  clonedTextContainerPosition!: THREE.Vector3;

  /**
   * Constructs a new Marker instance.
   *
   * @param map - The Map instance where the marker is to be placed.
   * @param text - The text associated with the marker.
   * @param type - The type of the marker.
   * @param timeData - The time data related to the marker.
   * @param coordinates - The coordinates where the marker is to be placed.
   * @param tb - CustomThreeJSWrapper instance for 3D handling.
   * @param index - The index of the marker.
   */
  constructor(
    map: Map,
    text: string,
    type: string,
    timeData: FormData,
    coordinates: THREE.Vector3,
    tb: CustomThreeJSWrapper,
    index: number,
  ) {
    // this.markerModel = markerModel;

    this.map = map;
    this.text = text;
    this.type = type;
    this.coordinates = coordinates;
    this.timeData = timeData;
    this.tempScaleVector = new THREE.Vector3();
    this.tb = tb;
    Marker.renderOrder += 1;
    this.myRenderOrder = Marker.renderOrder;
    this.index = index;
    this.animationTimeline = gsap.timeline();
  }

  /**
   * This function asynchronously sets up the marker by loading the model resource
   * and positioning it on the map.
   *
   * @returns A promise that resolves with the marker object or rejects with an error
   */
  async setup(): Promise<THREE.Group> {
    return new Promise(async (resolve, reject) => {
      /** The model enum for the marker, used for resource loading */
      const modelEnum = MarkerModels.MapPointer;

      /** Reference to the resource loader instance */
      const resourceManager = ResourceLoader.getInstance();

      try {
        /**
         * Loads the marker model using the resource manager
         *
         * @param modelEnum The enum key for the marker model
         * @param onModelLoaded Callback function called when the model is loaded
         * @param onProgress Callback function called during the loading process (optional)
         * @param onError Callback function called if an error occurs during loading
         */
        await resourceManager.loadModel(
          modelEnum,
          (model: Model) => {
            this.model = model.scene;
            const group = new THREE.Group();
            this.model.name = 'model';
            group.add(this.model);
            group.rotateZ(Math.PI);
            group.position.set(this.coordinates.x, this.coordinates.y, 0);

            const box = new THREE.Box3().setFromObject(this.model);

            this.height = box.max.y - box.min.y;
            this.width = box.max.x - box.min.x;

            this.model.position.y += this.height / 2;

            this.modelGroup = group;

            // Resolve the promise with the marker object
            resolve(group);
          },
          () => {},
          (error: ErrorEvent) => {
            console.log('error', error);
            reject(error);
          },
        );
      } catch (error) {
        // Handle potential errors during loading
        console.error('Error loading marker model:', error);
        reject(error);
      }
    });
  }

  /**
   * Asynchronously loads text content and sets up the text container for display on the map.
   *
   * @returns A promise that resolves with the loaded text container object
   */
  async setupText(showMarker: boolean) {
    if (!showMarker) {
      this.model.visible = false;
    } else {
      this.model.visible = true;
    }

    // this.textContainer = await this.loadText();

    // this.textContainer.name = 'text';
    this.markerContainer = this.modelGroup.clone();
    // this.markerContainer.add(this.textContainer);

    const radians = this.map.getPitch() * (Math.PI / 180);
    this.markerContainer.rotateX(radians);

    // this.textContainer.position.x += this.width;
    // this.textContainer.position.y -= this.height;

    // this.clonedTextContainerPosition = this.textContainer.clone().position;

    this.markerContainer.visible = false;
  }

  /**
   * Formats a date from a FormData object according to the provided timezone offset.
   *
   * This function takes a FormData object containing a `dateTime` field (presumably in ISO 8601 format)
   * and a `timezone` field representing the desired timezone offset. It uses the `dayjs` library
   * (https://day.js.org/) to create a date object with the specified timezone and then formats it
   * according to the 'dddd, MMM Do' format (e.g., "Friday, Oct 27").
   *
   * @param timeData A FormData object containing `dateTime` and `timezone` fields
   * @returns The formatted date string
   */
  formatDate(timeData: FormData) {
    const dateWithTimeZone = dayjs(timeData.dateTime).tz(timeData.timezone);

    const formattedString = dateWithTimeZone.format('dddd, MMM Do');

    return formattedString;
  }

  /**
   * Formats a time from a FormData object according to the provided timezone offset.
   *
   * This function takes a FormData object containing a `dateTime` field (presumably in ISO 8601 format)
   * and a `timezone` field representing the desired timezone offset. It uses the `dayjs` library
   * (https://day.js.org/) to create a date object with the specified timezone and then formats it
   * according to the 'h:mm A' format (e.g., "6:00 PM").
   *
   * @param timeData A FormData object containing `dateTime` and `timezone` fields
   * @returns The formatted time string
   */
  formatTime(timeData: FormData) {
    const dateWithTimeZone = dayjs(timeData.dateTime).tz(timeData.timezone);
    const formattedDate = dateWithTimeZone.format('h:mm A');

    return formattedDate;
  }

  /**
   * Creates a new Text object with specified content and formatting options.
   *
   * @param content The text content to be displayed.
   * @param iconText A flag indicating whether the text is for an icon or a larger label.
   * @returns A new Text object.
   */
  createText(content: string, iconText: boolean) {
    const myText = new Text();
    // const formattedDate = this.formatDate(this.dateTime);
    // const formattedTime = this.formatTime(this.dateTime);

    const font = iconText ? './Figtree-Regular.ttf' : './Figtree-Bold.ttf';
    const fontSize = iconText ? 3200 : 3800;

    myText.text = content;
    myText.fontSize = fontSize;
    // myText.color = 0xffffff;
    myText.depthOffset = -0.1;
    myText.renderOrder = this.myRenderOrder;
    myText.font = font;
    myText.anchorX = '50%';
    myText.anchorY = '50%';
    myText.maxWidth = 40000;

    return myText;
  }

  /**
   * Creates a text-based icon using the provided icon name.
   *
   * This function can be used to create simple icons for markers or other visual elements.
   * It creates a new Text object, sets its text content to the provided icon name, and configures
   * various properties for styling and rendering.
   *
   * @param iconName The name of the icon to be displayed (e.g., "star", "home").
   * @returns A new Text object representing the icon.
   */
  createIcon(iconName: string) {
    const iconText = new Text();
    iconText.text = iconName; // Replace with your icon or load an image
    iconText.fontSize = 4800;
    // iconText.color = 0xffffff;
    iconText.depthOffset = -0.1;
    iconText.renderOrder = this.myRenderOrder;
    iconText.font =
      'https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff';
    iconText.maxWidth = 40000;
    iconText.anchorX = '50%';
    iconText.anchorY = '50%';

    return iconText;
  }

  /**
   * Creates a rounded plane geometry with a specified width, height, radius, and opacity.
   *
   * This function is likely used for creating UI elements or visual aids within a Three.js scene.
   *
   * @param width The width of the plane (in scene units)
   * @param height The height of the plane (in scene units)
   * @param radius The corner radius of the plane (in scene units)
   * @param opacity The opacity of the plane material (between 0 and 1)
   * @returns A new THREE.Mesh object representing the rounded plane
   */
  createRoundedPlane(
    width: number,
    height: number,
    radius: number,
    opacity: number,
  ) {
    let shape = new THREE.Shape();
    shape.moveTo(-width / 2 + radius, -height / 2);
    shape.lineTo(width / 2 - radius, -height / 2);
    shape.quadraticCurveTo(
      width / 2,
      -height / 2,
      width / 2,
      -height / 2 + radius,
    );
    shape.lineTo(width / 2, height / 2 - radius);
    shape.quadraticCurveTo(
      width / 2,
      height / 2,
      width / 2 - radius,
      height / 2,
    );
    shape.lineTo(-width / 2 + radius, height / 2);
    shape.quadraticCurveTo(
      -width / 2,
      height / 2,
      -width / 2,
      height / 2 - radius,
    );
    shape.lineTo(-width / 2, -height / 2 + radius);
    shape.quadraticCurveTo(
      -width / 2,
      -height / 2,
      -width / 2 + radius,
      -height / 2,
    );

    let geometry = new THREE.ShapeGeometry(shape);
    const planeMaterial = new THREE.MeshBasicMaterial({
      color: 'black',
      transparent: true,
      opacity: opacity,
    });

    const planeMesh = new THREE.Mesh(geometry, planeMaterial);

    return planeMesh;
  }

  /**
   * Calculates the adjusted width and height of an InstancedBufferGeometry after considering its bounding box.
   *
   * This function is especially useful when working with instanced geometries, where the object's scale might not
   * directly reflect its visual size in the scene. By calculating the adjusted size based on the bounding box,
   * you can ensure more accurate positioning and sizing of the instanced objects.
   *
   * @param geometry The InstancedBufferGeometry to calculate the adjusted size for
   * @returns An object containing the adjusted width and height properties
   */
  calculateAdjustedSize(geometry: THREE.InstancedBufferGeometry) {
    const boundingBox = new THREE.Box3();
    boundingBox.copy(geometry.boundingBox as THREE.Box3);

    let height = boundingBox.max.y - boundingBox.min.y;
    let width = boundingBox.max.x - boundingBox.min.x;

    return { width, height };
  }

  /**
   * Creates a group containing an icon and optional additional text.
   *
   * This function asynchronously creates a THREE.Group containing an icon and
   * optionally some additional text. It first fetches the icon using the
   * `createIcon` function and then calculates its width and height. The text
   * (if provided) is created using the `createText` function and its dimensions
   * are also calculated. Finally, the icon and text are positioned within the
   * group and the group is returned along with the dimensions of both the icon
   * and the text.
   *
   * **Important Notes:**
   * - The `createIcon` function likely uses a synchronous call to retrieve the
   *   icon data. Consider refactoring to use asynchronous methods for better
   *   performance.
   *
   * @param iconName The name of the icon to create.
   * @param additionalText Optional additional text to display next to the icon.
   * @returns An object containing the following properties:
   *   - `group`: The THREE.Group containing both the icon and text.
   *   - `icon`: An object with `width` and `height` properties for the icon.
   *   - `text`: An object with `width` and `height` properties for the text (if provided).
   */
  async createIconGroup(iconName: string, additionalText: string) {
    const iconGroup = new THREE.Group();

    //ICON.SYNC CALL, GET BOUNDING BOX AND FIND WIDTH AND HEIGHT. RETURNS A GROUP WITH THE PIVOT BOTTOM LEFT.

    //GET WIDTH OF THE ICON GROUP  AND SET THE VALUE OF X FOR THE TEXT GROUP.

    const iconPromise = new Promise((resolve) => {
      const icon = this.createIcon(iconName);
      const group = new THREE.Group();

      icon.sync(() => {
        const { width, height } = this.calculateAdjustedSize(icon.geometry);
        icon.position.set(width / 2, height / 2, 0);
        group.add(icon);

        resolve({ group, width, height } as Dimensions); // Pass relevant width and height values
      });
    });

    const textPromise = new Promise((resolve) => {
      const text = this.createText(additionalText, true);
      const group = new THREE.Group();
      text.sync(() => {
        const { width, height } = this.calculateAdjustedSize(text.geometry);
        text.position.set(width / 2, height / 2, 0);
        group.add(text);
        // Resolve the promise with the text group
        resolve({ group, width, height } as Dimensions); // Pass relevant width and height values
      });
    });

    const [iconResult, textResult] = await Promise.all([
      iconPromise,
      textPromise,
    ]);

    (textResult as Dimensions).group.position.setX(
      (iconResult as Dimensions).width + 2000,
    );
    const verticalOffset =
      ((iconResult as Dimensions).height - (textResult as Dimensions).height) /
      2;

    (textResult as Dimensions).group.position.setY(verticalOffset);

    iconGroup.add((iconResult as Dimensions).group);
    iconGroup.add((textResult as Dimensions).group);

    return {
      group: iconGroup,
      icon: {
        width: (iconResult as Dimensions).width,
        height: (iconResult as Dimensions).height,
      },
      text: {
        width: (textResult as Dimensions).width,
        height: (textResult as Dimensions).height,
      },
    };
  }

  /**
   * Asynchronously creates a Three.js group containing a text object.
   *
   * This function takes a text string as input and performs the following steps:
   * 1. Creates a new Three.js group.
   * 2. Creates a promise that resolves with an object containing the text object, its width, and height.
   * 3. Calls the `createText` function (assumed to be defined elsewhere) to create the text object.
   * 4. Uses the `sync` method on the text object to wait for its geometry to be calculated.
   * 5. Calculates the adjusted width and height of the text object using the `calculateAdjustedSize` function (assumed to be defined elsewhere).
   * 6. Positions the text object in the center of the group.
   * 7. Resolves the promise with the text object and its dimensions.
   * 8. Adds the text object to the group.
   * 9. Returns an object containing the group and the text object's dimensions.
   *
   * @param textValue The text string to be displayed.
   * @returns A promise that resolves with an object containing the group and the text object's dimensions.
   */
  async createAsyncTextGroup(textValue: string) {
    const textPromise = new Promise((resolve) => {
      const group = new THREE.Group();
      const text = this.createText(textValue, false);

      text.sync(() => {
        const { width, height } = this.calculateAdjustedSize(text.geometry);

        text.position.set(width / 2, height / 2, 0);
        group.add(text);
        // Resolve the promise with the text group
        resolve({ group, width, height } as Dimensions); // Pass relevant width and height values
      });
    });

    const textResult = await textPromise;

    return {
      group: (textResult as Dimensions).group,
      text: {
        width: (textResult as Dimensions).width,
        height: (textResult as Dimensions).height,
      },
    };
  }

  /**
   * This function asynchronously loads and formats text elements to create a text group
   * for the travel animation.
   *
   * @returns A promise that resolves to a TextAnimation object containing the formatted text group
   */
  async loadText() {
    const formattedDate = this.formatDate(this.timeData);
    const formattedTime = this.formatTime(this.timeData);

    const locationText = await this.createAsyncTextGroup(this.text);
    const calendarGroup = await this.createIconGroup('event', formattedDate);
    const clockGroup = await this.createIconGroup(
      'query_builder',
      `${this.type} at ${formattedTime}`,
    );

    // Calculate the heights of the calendar and clock group bounding boxes
    const clockGroupBoundingBox = new THREE.Box3().setFromObject(
      clockGroup.group,
    );
    const clockGroupHeight =
      clockGroupBoundingBox.max.y - clockGroupBoundingBox.min.y;

    const calendarGroupBoundingBox = new THREE.Box3().setFromObject(
      calendarGroup.group,
    );
    const calendarGroupHeight =
      calendarGroupBoundingBox.max.y - calendarGroupBoundingBox.min.y;

    // Position the location text group based on the heights of other groups
    locationText.group.position.setY(
      calendarGroupHeight + clockGroupHeight + 2000,
    );
    calendarGroup.group.position.setY(clockGroupHeight + 500);

    // Create a new group to hold all text elements
    const struct = new THREE.Group();
    struct.position.z += 10 * this.myRenderOrder;

    const parent = new THREE.Group();
    parent.renderOrder = this.myRenderOrder;
    struct.add(locationText.group, calendarGroup.group, clockGroup.group);

    // Calculate the bounding box dimensions of the entire structure
    const structBoundingBox = new THREE.Box3().setFromObject(struct);
    const structWidth = structBoundingBox.max.x - structBoundingBox.min.x;
    const structHeight = structBoundingBox.max.y - structBoundingBox.min.y;

    // Add padding around the structure
    const paddingX = structWidth * 0.06; // adjust as needed
    const paddingY = structHeight * 0.07; // adjust as needed

    const structWidthWithPadding = structWidth + 2 * paddingX;
    const structHeightWithPadding = structHeight + 2 * paddingY;

    // Create a rounded plane as the background for the text elements
    const planeMesh = this.createRoundedPlane(
      structWidthWithPadding,
      structHeightWithPadding,
      1000,
      0,
    );

    planeMesh.position.set(structWidth / 2, structHeight / 2, 0);

    planeMesh.add(struct);

    // Adjust the position of the text elements within the structure
    struct.position.setX(-structWidth / 2);
    struct.position.setY(-structHeight / 2);

    parent.add(planeMesh);

    return parent;
  }

  /**
   * Sets up a GSAP timeline for animating the marker and text along the travel path.
   *
   * @param textPosition (Optional) The desired position for the text element in 3D space.
   * @param selectedTransport (Optional) The type of transport being animated ("Car" or "Plane").
   * @param onCompleteCallback A callback function to be executed after the animation completes.
   */
  setupGSAPTimeline(
    textPosition: THREE.Vector3 | null,
    selectedTransport: string | null,
    onCompleteCallback: () => void,
  ) {
    const frameRate = 24; // Frames per second
    const frameDuration = 1 / frameRate;

    // Marker animation timeline
    const markerAnimation = gsap
      .timeline()
      .from((this.markerContainer as THREE.Group).position, {
        y: (this.markerContainer as THREE.Group).position.y - 4000,
        duration: frameDuration * 3,
        ease: 'power2.out',
      })
      .to(
        (this.markerContainer as THREE.Group).scale,
        {
          x: (this.markerContainer as THREE.Group).scale.x * 0.9,
          y: (this.markerContainer as THREE.Group).scale.y * 1.1,
          duration: frameDuration * 1,
        },
        '>',
      )
      .to(
        (this.markerContainer as THREE.Group).scale,
        {
          x: (this.markerContainer as THREE.Group).scale.x * 1.1,
          y: (this.markerContainer as THREE.Group).scale.y * 0.9,
          duration: frameDuration * 2,
        },
        '>',
      )
      .to(
        (this.markerContainer as THREE.Group).scale,
        {
          x: (this.markerContainer as THREE.Group).scale.x * 0.95,
          y: (this.markerContainer as THREE.Group).scale.y * 1.05,
          duration: frameDuration * 3,
        },
        '>',
      )
      .to(
        (this.markerContainer as THREE.Group).scale,
        {
          x: (this.markerContainer as THREE.Group).scale.x * 1.025,
          y: (this.markerContainer as THREE.Group).scale.y * 0.975,
          duration: frameDuration * 5,
        },
        '>',
      )
      .to(
        (this.markerContainer as THREE.Group).scale,
        {
          x: (this.markerContainer as THREE.Group).scale.x * 1,
          y: (this.markerContainer as THREE.Group).scale.y * 1,
          duration: frameDuration * 4,
        },
        '>',
      );

    // // Text animation timeline
    // const textAnimation = gsap.timeline().to(
    //   (this.textContainer as THREE.Group).position,
    //   {
    //     duration: frameDuration * 16,
    //     x: (textPosition as THREE.Vector3).x,

    //     onStart: () => {
    //       (this.textContainer as THREE.Group).visible = true;
    //     },
    //   },
    //   '<',
    // );

    // Add a 2-second delay before calling the onCompleteCallback
    // if (selectedTransport === 'Car') {
    //   textAnimation.to({}, { delay: 2 });
    // }

    this.animationTimeline.add(markerAnimation);

    // Call onCompleteCallback after the delay
    this.animationTimeline.call(onCompleteCallback);
  }

  /**
   * Starts the travel animation for the specified transport mode.
   *
   * @param selectedTransport (optional) The type of transport to animate (e.g., "plane", "car").
   *  If not provided, the default transport from the animation data will be used.
   * @param onComplete Callback function to be called when the animation finishes.
   */
  startAnimation(selectedTransport: string, onComplete: () => void) {
    this.setScale(false, new THREE.Clock().getDelta());

    if (this.markerContainer) {
      // this.textContainer.position.x /= 2;

      // this.textContainer.visible = false;

      this.markerContainer.position.set(
        this.coordinates.x,
        this.coordinates.y,
        0.05,
      );

      // const meshIdentifiers = [
      //   'plane',
      //   'location',
      //   'dateIcon',
      //   'date',
      //   'timeIcon',
      //   'time',
      // ];
      // let index = 0;

      // this.textContainer.traverse((child: THREE.Object3D) => {
      //   if (child instanceof THREE.Mesh && child.material) {
      //     child.material.opacity = 1;
      //     child.material.transparent = true;

      //     this.childrenWithOpacity.push({
      //       mesh: child,
      //       identifier: meshIdentifiers[index],
      //     });
      //     index++;
      //   }
      // });

      const targetElement = this.childrenWithOpacity.find(
        (element: ChildWithOpacity) => element.identifier === 'plane',
      );
      if (
        targetElement &&
        targetElement.mesh.material instanceof THREE.MeshBasicMaterial
      ) {
        targetElement.mesh.material.opacity = 1;
        targetElement.mesh.material.transparent = true;
      }

      this.markerContainer.visible = true;
      // this.textContainer.visible = false;

      this.tb.add(this.markerContainer);

      this.setupGSAPTimeline(
        this.clonedTextContainerPosition
          ? this.clonedTextContainerPosition
          : null,
        selectedTransport ? selectedTransport : null,
        onComplete,
      );
    }
  }

  /**
   * Removes the text animation from the scene with a fading effect.
   *
   * @param onCompleteCallback A callback function to be called (if provided) when the removal animation is complete.
   */
  removeTextAnimation(onCompleteCallback?: () => void) {
    const textRemoveAnimation = gsap.timeline();

    for (let i = 0; i < this.childrenWithOpacity.length; i++) {
      const { mesh, identifier } = this.childrenWithOpacity[i];

      gsap.timeline().to(mesh.material, {
        duration: 0.5,
        opacity: 0,
        onStart: () => {
          if (identifier === 'plane') {
            // (mesh.material as THREE.Material).transparent = true;
          }
        },
      });
    }

    // Callback when the animation is complete
    textRemoveAnimation.call(() => {
      if (onCompleteCallback) {
        onCompleteCallback();
      }
      // this.tb.dispose(this.textContainer as THREE.Group);
    });
  }

  /**
   * Sets the scale of the marker container based on the current map zoom level.
   * This function lerps (linearly interpolates) the scale over time to create a smooth animation.
   *
   * @param shouldLerp A boolean flag indicating whether to lerp the scale (true) or set it directly (false).
   * @param delta A delta value representing the elapsed time since the last update (usually in seconds).
   */
  setScale(shouldLerp: boolean, delta: number): void {
    // Get the current zoom level of the map
    const zoom = this.map.getZoom();

    // Calculate the desired scale based on the zoom level using the getScaleFromZoom function (assumed to exist)
    const desiredScale = getScaleFromZoom(zoom);

    // Base factor for lerp (controls animation speed)
    const baseFactor = 0.1;

    // Base delta time (assumes 60 FPS for smooth animation across devices)
    const baseDeltaTime = 1 / 60;

    // Calculate lerp factor based on base factor, delta time, and base delta time
    const lerpFactor = 1 - Math.pow(1 - baseFactor * 3, delta / baseDeltaTime);

    // Check if the marker container exists
    if (this.markerContainer) {
      // Temporary vector to hold the desired scale
      this.tempLerpVec.set(desiredScale, desiredScale, desiredScale);

      // Apply lerp if enabled
      if (shouldLerp) {
        this.tempScaleVector.lerp(this.tempLerpVec, lerpFactor);
      } else {
        // Set the scale directly if lerp is disabled
        this.tempScaleVector.copy(this.tempLerpVec);
      }

      // Set the marker container's scale to the temporary vector
      this.markerContainer.scale.copy(this.tempScaleVector);
    }
  }

  /**
   * Updates the scale of the animation based on the provided delta value.
   *
   * @param delta The time difference (delta) to use for scaling calculations.
   */
  update(delta: number) {
    this.setScale(true, delta);
  }

  /**
   * Cleans up resources associated with the animation.
   *
   * This function removes the marker container and text container from the Three.js scene.
   */
  cleanup() {
    // Remove the markerContainer and textContainer from the scene
    if (this.tb && this.markerContainer) {
      this.tb.remove(this.markerContainer);
    }

    this.animationTimeline.clear(true);
  }

  destroy() {
    if (this.childrenWithOpacity.length > 0) {
      for (let i = 0; i < this.childrenWithOpacity.length; i++) {
        this.tb.dispose(this.childrenWithOpacity[i].mesh);
      }

      this.childrenWithOpacity = [];
    }
    if (this.markerContainer) {
      this.tb.dispose(this.markerContainer);
      this.markerContainer = null;
    }

    this.animationTimeline.clear(true);
    this.animationTimeline.call(() => {});
  }

  /**
   * Plays the animation timeline.
   */
  play() {
    this.animationTimeline.play();
  }
  /**
   * Pauses the animation timeline.
   */
  pause() {
    this.animationTimeline.pause();
  }
}
export { Marker };
