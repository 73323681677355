import React from 'react';
import Settings from '~/components/Settings';
import Navbar from '~/components/NavBar';
import FeedbackPopup from '~/components/FeedbackPopup';

/**
 * SettingsPageContainer component - Container for the Settings page.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component
 */
const SettingsPageContainer = () => {
  return (
    <>
      <Navbar />
      <FeedbackPopup />
      <Settings />
    </>
  );
};

export default SettingsPageContainer;
