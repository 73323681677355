import { useEffect, useState } from "react"
import { categoryViewSignal, destinationStateSignal, TextTicker } from "../common"

const StayPointLabelDesktop = () => {

    const [staypoint, setStaypoint] = useState<any>(null)

    useEffect(() => {
        const unsub = destinationStateSignal.subscribe((value) => {
            setStaypoint(value)
        })
        return () => {
            unsub()
        }
    }, [])

    return (
        <div className='d-flex justify-content-center align-items-center overflow-hidden' style={{ fontSize: "1.95vw", fontWeight: 400, whiteSpace: 'nowrap' }}>
            {staypoint?.duration && <><b className="me-1">Stay for</b> {staypoint?.duration} at</>} <b className='mx-1' style={{

            }}>{staypoint?.duration ? (categoryViewSignal.peek().label || '') : categoryViewSignal.peek().place}</b>
        </div>
    )
}

export default StayPointLabelDesktop;