import React from 'react';
import Button from '@mui/material/Button';
import useStyles from './styles';

interface ModeOfTransportButtonProps {
  label: string;
  icon: string;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

/**
 * Button component for selecting a mode of transport.
 * @param {string} label - The label for the button.
 * @param {React.ReactNode} icon - The icon to display in the button.
 * @param {boolean} isSelected - Whether the button is currently selected.
 * @param {() => void} onClick - Handler for button click.
 * @param {() => void} onMouseEnter - Handler for mouse enter event.
 * @param {() => void} onMouseLeave - Handler for mouse leave event.
 * @returns {JSX.Element} The rendered button component.
 */
const ModeOfTransportButton: React.FC<ModeOfTransportButtonProps> = ({
  label,
  icon,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: ModeOfTransportButtonProps) => {
  const classes = useStyles(); // Use useStyles to access the CSS classes

  return (
    <Button
      size="large"
      variant="contained"
      className={`${classes.modeButtons} ${isSelected ? classes.activeMode : ''}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img src={icon} alt={`${label} icon`} style={{ marginRight: 8 }} />
      {label}
    </Button>
  );
};

export default ModeOfTransportButton;
