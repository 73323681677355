// React Imports
import React from 'react';

// Child Components Imports
import FeedbackPopup from '~/components/FeedbackPopup';
import HistoryPage from '~/components/HistoryPage/HistoryPage';
import Navbar from '~/components/NavBar';

/**
 * HistoryPageContainer component - renders the Navbar, FeedbackPopup, and HistoryPage components.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component.
 */
const HistoryPageContainer = () => {
  return (
    <>
      <Navbar />
      <FeedbackPopup />
      <HistoryPage />
    </>
  );
};

export default HistoryPageContainer;
