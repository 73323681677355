import { AnimationState } from '~/utility/models';
import * as THREE from 'three';

export class WalkAnimation implements AnimationState {
  private action: THREE.AnimationAction;

  constructor(private mixer: THREE.AnimationMixer, clip: THREE.AnimationClip) {
    this.action = this.mixer.clipAction(clip);
  }

  onEnter(): void {
    console.log('Entering walk state');
    this.action.reset().setEffectiveWeight(1).fadeIn(0.5).play();
    this.action.setEffectiveTimeScale(0.85);
  }

  onExit(): void {
    console.log('Exiting walk state');
    this.action.setEffectiveWeight(1).fadeOut(0.5);
  }

  onUpdate(delta: number): void {
    this.mixer.update(delta);
  }
}
