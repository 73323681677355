import {
  AnimationProps,
  ConfigurationProps,
  DecodedManeuverDataProps,
  DecodedPathDataObject,
  DirectionsInput,
  ManeuverDataProps,
  ModelKeys,
  PublishableTravelData,
  PublishableTravelDataWithDecodedPath,
  TravelFormData,
} from '~models';
import tzlookup from 'tz-lookup';
import ActionsCreator from '~/redux/actions';
import {
  carZoomConfiguration,
  planeZoomConfiguration,
} from '~/map/utility/animation.config';
import { Position } from '@turf/turf';
import RouteGenerator from '~/managers/RouteGenerator';
import {
  DataTexture,
  EquirectangularReflectionMapping,
  LinearFilter,
} from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { supabase } from '~/supabase/supabaseClient';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import { AppDispatch } from '~/redux/store';
import { WalkModels } from '~/animationEngine/utility/enums/WalkModels';
import { keyframes } from '@mui/material';

export function isEditingAllowed(
  index: number,
  travelPointsLength: number,
): boolean {
  const isFirstIndex = index === 0;
  const isLastIndex = index === travelPointsLength - 1;

  return isFirstIndex || isLastIndex;
}

/**
 * Constant value representing the distance of high-quality route used from Google.
 */
export const HQ_DISTANCE: number = 2;

/**
 * This function updates the modelEnum of Travel Object and filter those model enums
 * which are required in the given travel. So that only those models load which are
 * being used in the travel
 * @param data PublishableTravelDataWithDecodedPath
 * @returns returns updated modelEnum
 */
const setupModelEnums = (
  data: PublishableTravelDataWithDecodedPath,
): ModelKeys[] => {
  if (data.selectedTransport === TravelMode.Transit) {
    // Extract Unique Transport Names
    const transportNames: ModelKeys[] = [];
    data.decodedPath.data.forEach((item: any) => {
      if (!transportNames.includes(item.transportName)) {
        // Change Walk to Char
        if (item.transportName === 'Walk') {
          transportNames.push(WalkModels.Char);
        } else {
          transportNames.push(item.transportName);
        }
      }
    });

    const updatedModelEnum = data.travelSegmentConfig.modelEnum.filter(
      (item: ModelKeys) => {
        // RF: Use 'Walk' as transportName in ModelEnums instead of 'Char'
        return transportNames.includes(item);
      },
    );

    return updatedModelEnum;
  } else {
    return data.travelSegmentConfig.modelEnum;
  }
};

export const setupPublishableTravelObjectWithDecodedPath = async (
  travelObj: TravelFormData,
  modelEnum: ModelKeys[],
  modelScale: number,
  animationSpeed: number,
) => {
  let decodedPath = await getRouteData(travelObj);

  let { encodedPath, ...restTravelObj } = travelObj;

  // Add Decoded Path in TravelObject
  let publishableData: PublishableTravelDataWithDecodedPath = {
    ...restTravelObj,
    decodedPath,
    travelSegmentConfig: {
      modelScale,
      modelEnum,
      animationSpeed,
    },
  };

  // Update ModelEnum in TravelObject only for Transit Mode
  if (travelObj.selectedTransport === TravelMode.Transit) {
    const updateModelEnum = setupModelEnums(publishableData);

    publishableData = {
      ...publishableData,
      travelSegmentConfig: {
        ...publishableData.travelSegmentConfig,
        modelEnum: updateModelEnum,
      },
    };
  }
  return publishableData;
};

export const setupPublishableTravelObject = async (
  travelObj: PublishableTravelDataWithDecodedPath,
): Promise<PublishableTravelData> => {
  const { path, data } = travelObj.decodedPath;
  const routeGenerator = RouteGenerator.getInstance();

  const stepsData = await Promise.all(
    data.map(async ({ path, ...rest }) => {
      const line = await routeGenerator.encodePath(path);
      return { ...rest, path: line };
    }),
  );

  const lineString = await routeGenerator.encodePath(path);

  const { decodedPath, ...restTravelObj } = travelObj;

  const publishableData: PublishableTravelData = {
    ...restTravelObj,
    encodedPath: { path: lineString, data: stepsData },
  };

  return publishableData;
};

export const appendPublishableTravelObjectModelEnum = (
  travelObj: PublishableTravelDataWithDecodedPath,
  modelEnum: ModelKeys[],
) => {
  travelObj.travelSegmentConfig.modelEnum = modelEnum;

  return travelObj;
};

export const appendPublishableTravelObjectAnimationConfig = (
  travelObj: PublishableTravelDataWithDecodedPath,
  modelScale: number,
  animationSpeed: number,
) => {
  travelObj.travelSegmentConfig.modelScale = modelScale;
  travelObj.travelSegmentConfig.animationSpeed = animationSpeed;

  return travelObj;
};

export function loadHDRITexture() {
  return new Promise<DataTexture>((resolve, reject) => {
    try {
      new RGBELoader()
        // .setDataType(UnsignedByteType)
        .setPath('./hdr/')
        .load(
          'industrial_sunset_puresky_2k.hdr',
          (loadedTexture: DataTexture) => {
            loadedTexture.mapping = EquirectangularReflectionMapping;
            loadedTexture.minFilter = LinearFilter;
            loadedTexture.magFilter = LinearFilter;
            loadedTexture.needsUpdate = true;
            resolve(loadedTexture); // Resolve the Promise with the data
          },
        );
    } catch (error) {
      reject(error);
    }
  });
}

export let hdrTexture: DataTexture;
loadHDRITexture().then((value: DataTexture) => {
  hdrTexture = value;
});

export function calculateTravelTime(
  departureDateTime: Date | null,
  arrivalDateTime: Date | null,
): string {
  let travelTime = '';
  if (departureDateTime && arrivalDateTime) {
    const diffInMilliseconds =
      arrivalDateTime.getTime() - departureDateTime.getTime();
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
    );
    travelTime = `${hours}h ${minutes}m`;
  }
  return travelTime;
}

export const lookupTimezone = (latitude: number, longitude: number) => {
  const timezone = tzlookup(latitude, longitude);
  return timezone;
};

export function formatTime(dateTime: Date | null | undefined): string {
  if (dateTime) {
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    return `${formattedHours}:${minutes} ${period}`;
  }
  return '';
}

export function getAnimationConfig(
  distance: number,
  option: string,
): AnimationProps | null {
  let configArray: ConfigurationProps[] = [];

  if (option === TravelMode.Plane) {
    configArray = planeZoomConfiguration;
  } else if (option === TravelMode.Car) {
    configArray = carZoomConfiguration;
  }

  if (configArray) {
    for (const config of configArray) {
      const { minDist, maxDist } = config.distanceRange;
      if (distance >= minDist && distance <= maxDist) {
        return {
          mapCurveHeight: config.mapCurveHeight,
          mapPitch: config.mapPitch,
          mapBearing: config.mapBearing,
          mapZoom: config.mapZoom,
          modelSize: config.modelSize,
          modelGrowthPercentage: config.modelGrowthPercentage,
          curveSpeed: config.curveSpeed,
        } as AnimationProps;
      }
    }
  }
  return null;
}

export const fetchAndStoreUserID = async (dispatch: AppDispatch) => {
  try {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
      console.error('Error fetching user ID:', error.message);
      return null;
    }

    if (data.session?.user) {
      const user = data.session?.user;

      dispatch(ActionsCreator.setUserID(user.id));
      dispatch(ActionsCreator.setUserEmail(user.email as string));
      dispatch(ActionsCreator.setUserName(user.user_metadata.full_name));

      if (user.user_metadata?.profile_picture)
        dispatch(
          ActionsCreator.setUserProfileImageURL(
            user.user_metadata.profile_picture,
          ),
        );

      return user.id;
    } else {
      // User is not authenticated
      console.warn('User is not authenticated');
      return null;
    }
  } catch (error: any) {
    //Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    console.error('Error fetching user ID:', error.message);
    return null;
  }
};

export async function getRouteData(
  travelData: TravelFormData,
): Promise<DecodedPathDataObject> {
  let dataLineString = [] as Position[];
  let res;
  let stepsPath: ManeuverDataProps[] = [];
  let stepsData: DecodedManeuverDataProps[] = [];

  const routeGenerator = RouteGenerator.getInstance();

  if (
    travelData.selectedTransport === TravelMode.Car ||
    travelData.selectedTransport === TravelMode.Transit ||
    travelData.selectedTransport === TravelMode.Walk ||
    travelData.selectedTransport === TravelMode.Ferry
  ) {
    // Check if encodedPath is not defined or is in the old format (a string)
    if (!travelData.encodedPath || typeof travelData.encodedPath === 'string') {
      // Generate new directions for the travel data
      const { path, data } = await routeGenerator.getDirections({
        origin: {
          coordinates: travelData.departure.location?.coordinates as Position,
          placeId: travelData.departure.location?.placeId as string,
        },
        destination: {
          coordinates: travelData.arrival.location?.coordinates as Position,
          placeId: travelData.arrival.location?.placeId as string,
        },
        travelMode: travelData.selectedTransport,
      } as DirectionsInput);

      // Assign the generated data to stepsPath and res
      stepsPath = data;
      res = path;
    } else {
      // Handle the new format where encodedPath is an object
      // Use the existing encoded path and steps data
      res = travelData.encodedPath.path || '';
      stepsPath = travelData.encodedPath.data || [];
    }

    if (stepsPath.length > 0) {
      stepsData = await Promise.all(
        stepsPath.map(async ({ path, ...rest }) => {
          const line = await routeGenerator.decodePath(path);
          return { ...rest, path: line.coordinates };
        }),
      );
    }

    if (res) {
      const lineString = await routeGenerator.decodePath(res);
      dataLineString = lineString.coordinates;
    }
  } else if (travelData.selectedTransport === TravelMode.Plane) {
    dataLineString = [
      travelData.departure.location?.coordinates as Position,
      travelData.arrival.location?.coordinates as Position,
    ];
  }

  return { path: dataLineString, data: stepsData };
}

export async function addRouteData(travelData: TravelFormData) {
  let updatedTravelPoints!: TravelFormData;
  const routeGenerator = RouteGenerator.getInstance();
  let stepsPath;

  if (
    travelData.selectedTransport === TravelMode.Car ||
    travelData.selectedTransport === TravelMode.Transit ||
    travelData.selectedTransport === TravelMode.Walk ||
    travelData.selectedTransport === TravelMode.Ferry
  ) {
    try {
      stepsPath = travelData.encodedPath;

      // routeGenerator.getDirections returns a Promise resolving to encodedPath
      if (!stepsPath.path || typeof stepsPath === 'string') {
        const { path, data } = await routeGenerator.getDirections({
          origin: {
            coordinates: travelData.departure.location?.coordinates as Position,
            placeId: travelData.departure.location?.placeId as string,
          },
          destination: {
            coordinates: travelData.arrival.location?.coordinates as Position,
            placeId: travelData.arrival.location?.placeId as string,
          },
          travelMode: travelData.selectedTransport,
        } as DirectionsInput);

        stepsPath = { path, data };
      }

      // Create a new object based on travelData with updated encodedPath
      const updatedTravelData = {
        ...travelData,
        encodedPath: stepsPath,
      };

      // Push the updatedTravelData to the updatedTravelPoints array
      updatedTravelPoints = updatedTravelData;
    } catch (error) {
      // Handle error if getting directions fails for a specific travelData
      console.error('Error getting directions:', error);
    }
  } else if (travelData.selectedTransport === TravelMode.Plane) {
    updatedTravelPoints = travelData;
  }

  return updatedTravelPoints;
}

/**
 * NOTE
 * This was created due to this import copy from 'copy-to-clipboard';
 * failing to copy when system clipboard is used
 *
 * @param text
 */
export async function copyTextToClipboard(text: string) {
  // Check if the Clipboard API is supported
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  } else {
    // Fallback for browsers that do not support Clipboard API
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // Avoid scrolling to the bottom
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Failed to copy text using fallback: ', err);
    }
    document.body.removeChild(textArea);
  }
}

/**
 * Validates the MIME type of a file against a list of valid types.
 * @param {Object} options - The options object.
 * @param {File} options.file - The file to validate.
 * @param {string[]} options.validFileTypes - An array of valid MIME types.
 * @returns {boolean} - Returns true if the file MIME type is in the valid list; false otherwise.
 */

export const validateFileMimeType = ({
  file,
  validFileTypes,
}: {
  file: File;
  validFileTypes: string[];
}) => validFileTypes.includes(file.type);

/**
 * This function uploads an image to the specified buckent name  folder in the storage
 * @param {bucketName} options.bucketName - The bucket name of the storage
 * @param {string} options.userId - The ID of the user uploading  image
 * @param {File} options.file - The file to upload
 * @returns {Promise<{ success: boolean, isNew?: boolean, fileUrl?: object, error?: string }>} - Returns an object indicating success or failure, whether the image is new or existing, and includes the image URL or an error message
 */
export const uploadFileToStorage = async ({
  bucketName,
  userId,
  file,
}: {
  bucketName: string;
  userId: string;
  file: File;
}) => {
  try {
    // Define the user's folder path in the storage
    const userFolder = `users/${userId}/`;

    // Get the image name from the file
    const fileName = file.name;

    // Check if an image with the same name already exists in the user's folder
    const { data: filesInFolder, error: filesError } = await supabase.storage
      .from(bucketName)
      .list(`${userFolder}`);

    // Handle error when checking for existing files in the folder
    if (filesError) {
      console.error('Error checking files in folder:', filesError);
      return { success: false, error: filesError.message };
    }

    // Check if the image is already uploaded
    const isFileAlreadyUploaded = filesInFolder.some(
      (file) => file.name === fileName,
    );

    // If the image is not already uploaded, upload it
    if (!isFileAlreadyUploaded) {
      const { data: uploadData, error } = await supabase.storage
        .from(bucketName)
        .upload(`${userFolder}${file.name}`, file);

      // Handle error during image upload
      if (error) {
        console.error('Error uploading image:', error);
        return { success: false, error: error.message };
      }

      // Get the public URL of the newly uploaded image
      const newFileUrl = await supabase.storage
        .from(bucketName)
        .getPublicUrl(uploadData?.path);

      return { success: true, isNew: true, fileUrl: newFileUrl };
    } else {
      // Find the existing image in the folder
      const existingFile = filesInFolder.find((file) => file.name === fileName);

      if (existingFile) {
        // Get the public URL of the existing image
        const existingFileUrl = await supabase.storage
          .from(bucketName)
          .getPublicUrl(`${userFolder}${existingFile.name}`);

        return { success: true, isNew: false, fileUrl: existingFileUrl };
      } else {
        // Handle case where the existing image is not found
        return { success: false, error: 'Existing image not found.' };
      }
    }
  } catch (error) {
    // Handle unexpected errors
    return { success: false, error: error };
  }
};

/**
 * Retrieves all files in a specified folder within the storage bucket.
 * @param {string} bucketName - The name of the storage bucket.
 * @param {string} userId - The ID of the user whose folder to retrieve files from.
 * @returns {Promise<{ success: boolean, files?: Array<object>, error?: string }>} - Returns an object indicating success or failure,
 *   and includes the array of files or an error message.
 */
export const getAllFilesInFolder = async (
  bucketName: string,
  userId: string,
) => {
  try {
    // Define the user's folder path in the storage
    const userFolder = `users/${userId}/`;

    // Get the list of files in the user's folder
    const { data: filesInFolder, error } = await supabase.storage
      .from(bucketName)
      .list(`${userFolder}`);

    // Handle error when retrieving files
    if (error) {
      console.error('Error retrieving files in folder:', error);
      return { success: false, error: error.message };
    }

    console.log({ filesInFolder });

    // Construct public URLs for each file
    const filesWithUrls = filesInFolder.map((file) => ({
      id: file.id,
      name: file.name,
      url: supabase.storage
        .from(bucketName)
        .getPublicUrl(`${userFolder}${file.name}`)?.data?.publicUrl,
    }));

    // Return the list of files successfully retrieved with URLs
    return { success: true, files: filesWithUrls };
  } catch (error) {
    // Handle unexpected errors
    console.error('Unexpected error:', error);
    return { success: false, error: error };
  }
};

/**
 * Formats a duration in seconds into a human-readable string.
 * @param seconds - The duration in seconds.
 * @returns A formatted string representing the duration in days, hours, minutes, and seconds.
 */

export const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += `${hours}:`;
  }

  if (minutes < 10) {
    formattedDuration += `${minutes}`;
  } else {
    formattedDuration += `${minutes.toString().padStart(2, '0')}`;
  }

  formattedDuration += `:${remainingSeconds.toString().padStart(2, '0')} min`;

  return formattedDuration;
};

export interface IAudioFile {
  id: string;
  name: string;
  url: string;
}

export const getAudioDuration = async (
  file: File | IAudioFile,
): Promise<number> => {
  return new Promise((resolve, reject) => {
    // Create an audio element dynamically
    const audio = document.createElement('audio');

    // Set the audio source based on whether it's a regular File or fetched AudioFile
    if ('url' in file && file.url) {
      audio.src = file.url; // For files fetched from Supabase storage
    } else {
      audio.src = URL.createObjectURL(file as File); // For regular HTML File objects
    }

    audio.onloadedmetadata = () => {
      resolve(audio.duration);
      // revoke object url
      URL.revokeObjectURL(audio.src);
      //  remove it from memory and from being interactable
      audio.remove();
    };
    audio.onerror = (error) => {
      // revoke object url
      URL.revokeObjectURL(audio.src);
      //  remove it from memory and from being interactable
      audio.remove();
      reject(error);
    };
  });
};

/**
 * Validates the MIME type of a file against a list of valid types.
 * @param {Object} options - The options object.
 * @param {File} options.file - The file to validate.
 * @param {string[]} options.validFileTypes - An array of valid MIME types.
 * @returns {boolean} - Returns true if the file MIME type is in the valid list; false otherwise.
 */

export const validateFileMimetype = ({
  file,
  validFileTypes,
}: {
  file: File;
  validFileTypes: string[];
}) => validFileTypes.includes(file.type);

/**
 * Removes a file from storage based on the provided URL.
 * @param {bucketName} options.bucketName - The bucket name of the storage
 * @param {string} options.userId - The ID of the user uploading the  image
 * @param {string} options.fileUrl - The URL of the file to delete.
 * @returns {Promise<{ success: boolean, error?: string }>} - Returns an object indicating success or failure, and includes an optional error message.
 */
export const deleteFileByUrlFromStorage = async ({
  bucketName,
  userId,
  fileUrl,
}: {
  bucketName: string;
  userId: string;
  fileUrl: string;
}) => {
  try {
    // Extract relevant information from the URL
    const urlParts = fileUrl.split('/');

    const fileName = urlParts[urlParts.length - 1]; // The last element which is the file name

    // Attempt to remove the file from storage
    const { error } = await supabase.storage
      .from(bucketName)
      .remove([`users/${userId}/${fileName}`]);

    if (error) {
      console.error('Error removing file:', error);
      return { success: false, error: error.message };
    }

    console.log(`File '${fileName}' successfully removed.`);
    return { success: true };
  } catch (error) {
    console.error('Error removing file:', error);
    return { success: false, error: error };
  }
};

/**
 * Checks if the size of the provided file exceeds 100MB.
 * @param file - The File object representing the file to check.
 * @returns {boolean} - Returns true if the file size is greater than 100MB, false otherwise.
 */
export const isFileGreaterThan100MB = (file: File): boolean => {
  const maxSizeBytes = 100 * 1024 * 1024; // 100 MB in bytes
  return file.size > maxSizeBytes;
};

// Define the pulsate animation using MUI's keyframes
export const pulsateAnimation = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
                0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
    box-shadow: none;
  }
`;

export const checkTransparency = (imageData: ImageData) => {
  const { data, width, height } = imageData;
  for (let i = 3; i < width * height * 4; i += 4) {
    if (data[i] < 255) {
      return true;
    }
  }
  return false;
};
