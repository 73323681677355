import { Fragment, memo, useEffect, useRef, useState } from 'react';
import {
  AnimatePresence,
  motion,
  useAnimation,
  useAnimationControls,
} from 'framer-motion';
import './style.css';
import { signal } from '@preact/signals-core';
import {
  categoryViewSignal,
  destinationStateSignal,
  imagesignal,
  openedImages,
  openedList,
  PauseAnimation,
  PlayAnimation,
  setOpenedList,
} from '../common';
import { isCalendarAnimating } from '../DesktopFooter/CalendarLine';
const ImageElementSignal = signal({
  src: '',
  layoutId: '',
});
let timeout: any;

export const convertToBase64 = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export default memo(() => {
  const [images, setImages] = useState<string[]>([]);
  const [hide, setHide] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const unsub = isCalendarAnimating.subscribe((value) => {
      setHide(value);
    });
    const imagesignalunsub = imagesignal.subscribe((images) => {
      if (!images) return setImages([]);
      setImages(images);
      // Promise.all(images.map((url) => convertToBase64(url)))
      //   .then((base64Images: any) => {
      //     setImages(base64Images);
      //   })
      //   .catch((error) => console.error('Error converting images', error));
    });
    return () => {
      unsub();
      imagesignalunsub();
    };
  }, []);

  useEffect(() => {
    if (opened) {
      PauseAnimation();
    } else {
      PlayAnimation();
    }
    return () => {
      // PlayAnimation();
    };
  }, [opened]);

  return (
    <AnimatePresence mode="sync">
      {!hide && (
        <Fragment>
          {!opened ? (
            <div className="desktop_pictures_overlay">
              {' '}
              <CollapsedPicturesOverlay
                images={images}
                setOpened={setOpened}
              />{' '}
            </div>
          ) : (
            <OpenedPicturesOverlayMenu images={images} setOpened={setOpened} />
          )}
        </Fragment>
      )}
      <ImageViewer images={images} />
    </AnimatePresence>
  );
});

const CollapsedPicturesOverlay = ({
  images = [],
  setOpened,
}: {
  images: string[];
  setOpened: (opened: boolean) => void;
}) => {
  if (images.length === 0) return null;
  return (
    <motion.div
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 200 }}
      transition={{ duration: 0.25 }}
      key={'collapsed-pictures-overlay'}
      layout
      className="collapsed"
      onClick={() => setOpened(true)}
    >
      {images
        .slice(0, 3)
        .reverse()
        .map((image, index) => (
          <motion.img
            transition={{ duration: 0.25 }}
            layoutId={`image-${index}`}
            className="collapsed-image"
            key={image}
            src={image}
            alt={`image-${index}`}
            animate={{
              marginBottom: index * 30 + 'px',
            }}
          />
        ))}
    </motion.div>
  );
};

const setNewOpenedImage = (image: string) => {
  openedImages.value = [...openedImages.peek(), image];
};

const OpenedPicturesOverlayMenu = ({
  images = [],
  setOpened,
}: {
  images: string[];
  setOpened: (opened: boolean) => void;
}) => {
  const openedref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // click outside the menu to close it
    const handleClickOutside = (event: any) => {
      if (ImageElementSignal.peek()?.src) return;
      if (openedref.current && !openedref.current.contains(event.target)) {
        setOpened(false);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);
  return (
    <motion.div
      key={'opened-pictures-overlay-menu'}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25 }}
      layout
      className="opened-pictures-overlay-menu"
    >
      <div className="right-side" ref={openedref}>
        {images?.map((image, index) => (
          <motion.div className="overlay-menu-image" key={image}>
            <motion.img
              transition={{ duration: 0.25 }}
              layoutId={`image-${index}`}
              id={`overlay-menu-image-${index}`}
              onClick={() => {
                const img = document.getElementById(
                  `overlay-menu-image-${index}`,
                ) as HTMLImageElement;
                if (img) {
                  img.style.opacity = '0.7';
                  img.style.filter = 'saturate(20%)';
                }

                setNewOpenedImage(image);
                ImageElementSignal.value = {
                  src: image,
                  layoutId: `image-${index}`,
                };
              }}
              style={{
                opacity: openedImages.peek().includes(image) ? 0.7 : 1,
                filter: openedImages.peek().includes(image)
                  ? 'saturate(50%)'
                  : 'none',
              }}
              key={index}
              src={image}
              alt={`image-${index}`}
            />
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export const ImageViewer = ({
  images = [],
  opened = undefined,
  setOpened = undefined,
  mobile = false,
}: {
  images: string[];
  opened?: any;
  setOpened?: any;
  mobile?: boolean;
}) => {
  const ref = useRef<HTMLImageElement>(null);

  const [image, setImage] = useState(
    opened || {
      src: '',
      layoutId: '',
    },
  );

  useEffect(() => {
    if (!opened) {
      const unsub = ImageElementSignal.subscribe((img) => {
        setImage(img);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          ImageElementSignal.value = { src: '', layoutId: '' };
        }, 3500);
      });
      return () => {
        clearTimeout(timeout);
        unsub();
      };
    }
  }, []);
  useEffect(() => {
    if (mobile) {
      PauseAnimation();
      // timeout = setTimeout(() => {
      //   setOpened({ src: '', layoutId: '' });
      //   PlayAnimation();
      //   PlayAnimation();
      // }, 3500);
    }
    return () => {
      clearTimeout(timeout);
      // PlayAnimation();
    };
  }, [opened]);
  const handleLeftImage = () => {
    const index = images.indexOf(image.src);
    if (opened) {
      if (index > 0) {
        setOpened({ src: images[index - 1], layoutId: `image-${index - 1}` });
      } else {
        setOpened({
          src: images[images.length - 1],
          layoutId: `image-${images.length - 1}`,
        });
      }
      return;
    }
    if (index > 0) {
      ImageElementSignal.value = {
        src: images[index - 1],
        layoutId: `image-${index - 1}`,
      };
    } else {
      ImageElementSignal.value = {
        src: images[images.length - 1],
        layoutId: `image-${images.length - 1}`,
      };
    }
  };
  const handleRightImage = () => {
    const index = images.indexOf(image.src);
    if (opened) {
      if (index < images.length - 1) {
        setOpened({ src: images[index + 1], layoutId: `image-${index + 1}` });
      } else {
        setOpened({ src: images[0], layoutId: `image-0` });
      }
    }
    if (index < images.length - 1) {
      ImageElementSignal.value = {
        src: images[index + 1],
        layoutId: `image-${index + 1}`,
      };
    } else {
      ImageElementSignal.value = { src: images[0], layoutId: `image-0` };
    }
  };

  const handleNextImage = () => {
    const openedImages = openedList.peek();
    const index = images.indexOf(image.src);
    const nonOpenedImages = images.filter((img) => !openedImages.includes(img));
    // pick the next image that is not opened
    if (nonOpenedImages.length > 0) {
      setOpenedList(nonOpenedImages[0]);
      setOpened({ src: '', layoutId: '' });
      setTimeout(() => {
        setOpened({ src: nonOpenedImages[0], layoutId: `image` });
      }, 100);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    if (opened) {
      setOpened({ src: '', layoutId: '' });
    }
    ImageElementSignal.value = { src: '', layoutId: '' };
    if (mobile) {
      PlayAnimation();
      setTimeout(() => {
        PlayAnimation();
      }, 100);
    }
  };

  return (
    <AnimatePresence mode="sync">
      {image.src ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
          layout
          key={'image-viewer'}
          className="image-viewer"
          style={{
            transformOrigin: 'center',
            ...(mobile && { flexDirection: 'column', columnGap: '0px' }),
          }}
        >
          <div>
            {!mobile && (
              <button onClick={handleLeftImage}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_24_896)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.06778 6.66665C4.57962 7.15481 4.57962 7.94627 5.06778 8.43442L11.0678 14.4344C11.5559 14.9226 12.3474 14.9226 12.8355 14.4344C13.3237 13.9463 13.3237 13.1548 12.8355 12.6667L7.71943 7.55054L12.8355 2.43442C13.3237 1.94627 13.3237 1.15481 12.8355 0.666654C12.3474 0.178499 11.5559 0.178499 11.0678 0.666654L5.06778 6.66665Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_24_896"
                      x="0.70166"
                      y="0.300537"
                      width="16.5"
                      height="22.5"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_24_896"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_24_896"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
            )}
          </div>
          <div
            className="image-viewer-content-panel"
            style={{
              ...(mobile && { width: '100%', columnGap: '0px' }),
            }}
          >
            {!mobile && (
              <div className="image-viewer-content-panel-close">
                <button
                  onClick={() => {
                    if (opened) {
                      setOpened({ src: '', layoutId: '' });
                    }
                    ImageElementSignal.value = { src: '', layoutId: '' };
                  }}
                >
                  &times;
                </button>
              </div>
            )}
            <motion.img
              ref={ref}
              transition={{ duration: 0.25 }}
              layoutId={image.layoutId}
              src={image.src}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
            />
            {mobile && <CountdownTimer handleNextImage={handleNextImage} />}
          </div>
          <div>
            {!mobile && (
              <button onClick={handleRightImage}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_24_899)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.16592 1.06601C5.65408 0.577851 6.44553 0.577851 6.93369 1.06601L12.9337 7.06601C13.1681 7.30043 13.2998 7.61837 13.2998 7.94989C13.2998 8.28141 13.1681 8.59935 12.9337 8.83377L6.93369 14.8338C6.44553 15.3219 5.65408 15.3219 5.16592 14.8338C4.67777 14.3456 4.67777 13.5542 5.16592 13.066L10.282 7.94989L5.16592 2.83377C4.67777 2.34562 4.67777 1.55416 5.16592 1.06601Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_24_899"
                      x="0.799805"
                      y="0.69989"
                      width="16.5"
                      height="22.5"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_24_899"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_24_899"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
            )}
          </div>
          {mobile && (
            <h3 className="mobile-picture-label">
              {categoryViewSignal.peek().place}
            </h3>
          )}
          <div className="image-viewer-backdrop" onClick={handleClose} />
        </motion.div>
      ) : (
        <div />
      )}
    </AnimatePresence>
  );
};

const CountdownTimer = ({
  handleNextImage,
}: {
  handleNextImage: () => void;
}) => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount((prev) => prev - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      handleNextImage();
      setCount(3);
      return () => {};
    }
  }, [count]);

  return (
    <div
      style={{ position: 'absolute', marginTop: '-35px', marginLeft: '-25px' }}
    >
      <AnimatePresence>
        {count > 0 && (
          <motion.div
            layout
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.5 }}
            transition={{ duration: 0.25 }}
            style={{ fontSize: '20px', fontWeight: 'bold', color: 'white' }}
          >
            {count}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
