import { Map } from 'maplibre-gl';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { PublishableTravelDataWithDecodedPath } from '~/utility/models';
import { distance, point, Position } from '@turf/turf';
import { getStraightLineBetweenTwoPoints } from '../utility/utils';

export class MonoStaticVisualizer {
  index!: number;
  map!: Map;
  tb!: CustomThreeJSWrapper;
  showDestinationStraightLine!: boolean;
  showOriginStraightLine!: boolean;
  travelDataPath!: PublishableTravelDataWithDecodedPath;
  pathIds: string[] = [];

  constructor(
    map: Map,
    index: number,
    tb: CustomThreeJSWrapper,
    travelDataPath: PublishableTravelDataWithDecodedPath,
  ) {
    if (!map) return;
    this.map = map;
    this.tb = tb;
    this.index = index;
    this.travelDataPath = travelDataPath;
  }

  addTravelLayerAndSources(
    path: Position[],
    id: string,
    isStraightLine: boolean,
  ) {
    this.addTravelSource(id, path);
    this.addTravelLayer(id, isStraightLine);
    this.pathIds.push(id);
  }

  addTravelSource(id: string, path: Position[]) {
    if (!this.map.getSource(id)) {
      this.map.addSource(id, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: path,
          },
        },
      });
    }
  }

  addTravelLayer(id: string, isStraightLine: boolean) {
    if (!this.map.getLayer(id)) {
      // Check to add layer for dottedLine for straightline
      if (isStraightLine) {
        this.map.addLayer({
          id: id,
          type: 'line',
          source: id,
          paint: {
            'line-color': '#FE7138',
            'line-width': 3,
            'line-dasharray': [0, 0.5, 1],
          },
        });
      } else {
        // Layer for solid line other than dotted line
        this.map.addLayer({
          id: id,
          type: 'line',
          source: id,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#FE7138',
            'line-width': 3,
          },
        });
      }
    }
  }

  clearTravels() {
    this.pathIds.forEach((id) => {
      this.removeTravelLayerSource(id);
    });

    this.pathIds.length = 0;
  }

  removeTravelLayerSource(id: string) {
    if (this.map.getLayer(id)) {
      this.map.removeLayer(id);
    }

    if (this.map.getSource(id)) {
      this.map.removeSource(id);
    }
  }

  showNoTransportationPart(locationPoint: Position, pathPoint: Position) {
    let showNoTransportationParts = true;

    const point1 = point(locationPoint);
    const point2 = point(pathPoint);

    const distanceBetweenPoints = distance(point1, point2);

    if (distanceBetweenPoints !== 0) {
      showNoTransportationParts = true;
    } else {
      showNoTransportationParts = false;
    }

    return showNoTransportationParts;
  }

  setOriginStraightLine() {
    const originPoint = this.travelDataPath.departure.location;
    const firstPathPoint = this.travelDataPath.decodedPath.path[0];

    this.showOriginStraightLine = this.showNoTransportationPart(
      originPoint?.coordinates as Position,
      firstPathPoint,
    );

    if (this.showOriginStraightLine) {
      // Generate Straight line between two points
      const { coordinates } = getStraightLineBetweenTwoPoints(
        originPoint?.coordinates as Position,
        firstPathPoint,
      );

      const id = `originNoTransportationStatic${this.index}`;

      this.addTravelLayerAndSources(coordinates, id, true);
    }
  }

  setDestinationStraightLine() {
    const destinationPoint = this.travelDataPath.arrival.location;
    const lastPath = this.travelDataPath.decodedPath.path.slice(-1)[0];

    this.showDestinationStraightLine = this.showNoTransportationPart(
      destinationPoint?.coordinates as Position,
      lastPath,
    );

    if (this.showDestinationStraightLine) {
      const { coordinates } = getStraightLineBetweenTwoPoints(
        destinationPoint?.coordinates as Position,
        lastPath,
      );

      const id = `destinationNoTransportationStatic${this.index}`;

      this.addTravelLayerAndSources(coordinates, id, true);
    }
  }

  visualize() {
    if (this.travelDataPath.decodedPath.path.length < 1) return;

    this.setOriginStraightLine();
    this.setDestinationStraightLine();

    let id = `static-route-${this.index}`;
    this.addTravelLayerAndSources(
      this.travelDataPath.decodedPath.path,
      id,
      false,
    );
  }
}
