import { Drawer, Modal, Box } from '@mui/material';
import React from 'react';
import TravelPage from '~/components/TravelPage';
import { useSelector } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { useDispatch } from '~/redux/store';
import useStyles from './styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RootState } from '~/redux/reducers';

/**
 * TravelItineraryContainer component - Container for displaying the travel itinerary.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component
 */
const TravelItineraryContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Select the state to determine if the travel itinerary should be open
  const openTravelItinerary = useSelector(
    (state: RootState) => state.MapReducers.travelItineraryState
  );

  /**
   * handleClose - Closes the travel itinerary and updates the schedule section state.
   */
  const handleClose = () => {
    dispatch(ActionsCreator.openTravelItinerary(false));
    dispatch(ActionsCreator.setScheduleSectionState(false));
  };

  // Determine if the screen size is small
  const isSmScreen = useMediaQuery('(max-width:600px)');
  const PopupComponent = isSmScreen ? Drawer : Modal;

  return (
    <PopupComponent
      {...(isSmScreen
        ? {
            anchor: 'bottom',
            open: openTravelItinerary,
            onClose: handleClose,
            PaperProps: { style: { borderRadius: '25px 25px 0 0' } },
            ModalProps: { keepMounted: true },
          }
        : {
            open: openTravelItinerary,
            onClose: handleClose,
            'aria-labelledby': 'modal-travelPage',
            'aria-describedby': 'modal-travelPage-description',
          })}
    >
      <Box className={isSmScreen ? '' : classes.TravelPage}>
        <TravelPage />
      </Box>
    </PopupComponent>
  );
};

export default TravelItineraryContainer;
