import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  dialog: {
    maxWidth: '400px',
    borderRadius: '10px',
  },

  fieldBox: {
    fontFamily: 'Futura Md BT',
    marginBottom: '15px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'Futura Md BT',
    fontweight: 400,
    fontSize: '17px',
    color: '#454953',
  },
  dialogTitle: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    fontFamily: 'Futura Hv BT',
    fontWeight: 400,
    fontSize: '22px',
  },
  dialogButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    gap: '15px',
  },
  dialogButton: {
    textTransform: 'none',
    borderRadius: '8px',
    width: '365px',
    height: '60px',
    fontFamily: 'Futura Md BT',
    boxShadow: 'none',
  },
  dialogConfirmButton: {
    backgroundColor: '#FE7138',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#E46532 ',
    },
  },
  dialogCancelButton: {
    backgroundColor: '#ECECED',
    color: '#000',
    marginBottom: '10px',
  },
  '@media (max-width: 768px)': {
    dialog: {
      minWidth: '310px',
    },
  },
  [theme.breakpoints.between(150, 450)]: {
    dialogCancelButton: {
      width: '100%',
    },
    dialogConfirmButton: {
      width: '100%',
    },
  },
}));
export default useStyles;
