import './style.css';
import { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, m, motion, useAnimation, useAnimationControls } from 'framer-motion';
import { DateFormatType, TravelPoint, formatDate } from '../../StatsOverlay';
import {
  PlaneIcon,
  TextTicker,
  VehicleIcon,
  computeHeading,
  computeTitle,
  durationSignal,
  formatCategory,
  generateDates,
  getActionBySelectedTransport,
  getIconBySelectedTransport,
  icons,
} from '../../common';
import Carousel from './Carousel';
import useEmblaCarousel from 'embla-carousel-react';

import { ChevronRight } from '@mui/icons-material';
import { DAYJS } from '~/map/ViewTravel';

export default ({
  step,
  data,
  currentCalendarDate,
  handleCardSelect,

  departureDates,
  startDate,
  endDate
}: {
  step: any;
  data: TravelPoint[];
  currentCalendarDate: string;

  handleCardSelect: (index: number) => void;

  departureDates: Date[],
  startDate: Date | null,
  endDate: Date | null
}) => {
  const ref = useRef() as any
  const controls = useAnimationControls()
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
    })
  }, []);
  useEffect(() => {
    const height = ref.current?.offsetHeight
    controls.start({
      y: hidden ? -(height - 25) : 0,
    })
  }, [hidden])
  return (
    <motion.div
      ref={ref}
      className="calendar-top-desktop"
      animate={controls}
      transition={{
        delay: 0.5,
        duration: 0.4,
        ease: 'linear',
        type: 'spring',
        stiffness: 1000,
        damping: 100,

      }}
      initial={{
        opacity: 0,
        y: 100,
      }}
    >
      <View
        currentdate={currentCalendarDate}
        departureDates={departureDates}
        data={data}
        step={step}

        handleCardSelect={handleCardSelect}


        startDate={startDate}
        endDate={endDate}
      />
      <motion.div
        onClick={() => setHidden((prev) => !prev)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.2 }}
        className="calendar-toggle-desktop"
      >
        <motion.span
          animate={{
            rotate: hidden ? 90 : -90,
          }}
          transition={{
            duration: 0.4,
            ease: 'easeInOut',
          }}
        >

          <ChevronRight />
        </motion.span>
      </motion.div>
    </motion.div>
  );
};

const View = ({
  currentdate,
  departureDates,
  data,
  step,

  handleCardSelect,

  startDate,
  endDate
}: {
  currentdate: string;
  departureDates: Date[]
  data: TravelPoint[];
  step: any;

  handleCardSelect: (index: number) => void;

  startDate: Date | null,
  endDate: Date | null
}) => {
  const [tempCurrentDate, setTempCurrentDate] = useState<string | undefined>()
  const dates = useMemo(() => generateDates(startDate, endDate), [data])



  const goToDepartureDate = (date: Date) => {
    setTempCurrentDate(date.toISOString())

  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTempCurrentDate(undefined)
    }, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [tempCurrentDate])


  const formatedDate2 = DAYJS(tempCurrentDate || currentdate).format('DD MMM YYYY')


  const currentday = dates.findIndex(
    (d) =>
      DAYJS(d.date).format('DD MMM YYYY') === DAYJS(tempCurrentDate || currentdate).format('DD MMM YYYY')
  ) + 1;

  const currentDateFilter = (d: TravelPoint) => {
    const date = DAYJS(d?.departure?.dateTime)
    return (
      DAYJS(date).format('DD MMM YYYY') === DAYJS(tempCurrentDate || currentdate).format('DD MMM YYYY')
    );
  }




  const items = useMemo(() => data?.sort((a, b) => DAYJS(a?.departure?.dateTime).valueOf() - DAYJS(b?.departure?.dateTime).valueOf())
    .map((d, i) => ({ ...d, index: i })).filter(currentDateFilter).map((d, i) => {
      return {
        type: d?.selectedTransport,
        title: d?.arrival?.location?.label,
        from: formatCategory(d?.departure?.category),
        to: formatCategory(d?.arrival?.category),
        departure: DAYJS(d?.departure?.dateTime).tz(d?.departure?.timezone).format('h:mm a'),
        arrival: DAYJS(d?.arrival?.dateTime).tz(d?.arrival?.timezone).format('h:mm a'),
        duration: calculateDuration(d?.departure?.dateTime, d?.arrival?.dateTime),
        departureDate: d?.departure.dateTime,
        index: d?.index
      };
    }) || [], [data, tempCurrentDate, currentdate])



  const offsetStep = { ...step, ...{ calendarStep: step.calendarStep } }

  const [currentCalendarDate, setCurrentCalendarDate] = useState<typeof currentdate>(tempCurrentDate || currentdate)

  useEffect(() => {
    setCurrentCalendarDate(tempCurrentDate || currentdate)
  }, [tempCurrentDate || currentdate])


  return (
    <div className="d-flex flex-column  desktop-calendar-view">

      <b className="daydatedesktop ps-2">
        <b>
          Day {currentday - 4} - {formatedDate2}
        </b>
      </b>

      <div
        className="d-flex justify-content-evenly my-2"
        style={{ width: window.innerWidth < 1368 ? '75%' : '52.5%', margin: 'auto' }}
      >


        <DatesBar
          dates={dates}
          currentDate={tempCurrentDate || currentCalendarDate}
          goToDepartureDate={goToDepartureDate}
          departureDates={departureDates}
        // setCurrentDate={setCurrentCalendarDate}
        />


      </div>
      <Timeline items={items} step={offsetStep} dates={dates}

        currentDate={tempCurrentDate || currentCalendarDate}
        handleCardSelect={handleCardSelect}

        // dates={dates}
        departureDates={departureDates}

      />
    </div>
  );
};

export const DatesBar = memo(({ currentDate, dates, goToDepartureDate, departureDates, mobile = false }: {
  currentDate: string, dates: { day: string, date: Date }[], goToDepartureDate: (index: Date) => void, departureDates: Date[], mobile?: boolean
}) => {

  const controls = useAnimation();
  const currentIndex = dates.findIndex((d) => DAYJS(d.date).format('DD MMM YYYY') === DAYJS(currentDate).format('DD MMM YYYY'))
  const containerRef = useRef() as any
  const [x0, setX0] = useState(0)
  const columnGap = 1
  const cardSize = (mobile ? 10 : 2.92) * window.innerWidth / 100
  const leftSideOffset = cardSize * 3
  const [timeoutstate, setTimeoutState] = useState(setTimeout(() => { }, 0))
  useEffect(() => {
    const targetOffset = (currentIndex * -cardSize) + leftSideOffset
    setX0(targetOffset)
  }, [currentIndex, controls]);

  useEffect(() => {
    controls.start({ x: x0 })
  }, [x0])

  const slideLeft = () => {
    clearTimeout(timeoutstate)
    setX0((prev) => {
      if (prev >= 0) return prev
      return prev + cardSize
    })
    if (mobile) return
    setTimeoutState(setTimeout(() => {
      const targetOffset = (currentIndex * -cardSize) + leftSideOffset
      setX0(targetOffset)
    }, 5000))
  }

  const slideRight = () => {
    clearTimeout(timeoutstate)
    setX0((prev) => {
      if (prev < -cardSize * (dates.length - 3)) return prev
      return prev - cardSize
    })
    if (mobile) return
    setTimeoutState(setTimeout(() => {
      const targetOffset = (currentIndex * -cardSize) + leftSideOffset
      setX0(targetOffset)
    }, 5000))
  }

  return (
    <>
      <motion.div
        style={{
          cursor: "pointer",
        }}
        whileTap={{ scale: 1.2 }}
        whileHover={{ scale: 1.1 }}
        onClick={slideLeft}
        className="chevron-left-desktop">
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.25 5.42627L6.75 9.92627L11.25 14.4263"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </motion.div>
      <div className="datesbar-containerdesktop" ref={containerRef}
      >
        <motion.div
          layout
          className="datesbar-innerdesktop"
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          animate={controls}

          transition={{ type: "spring", ease: "easeInOut", duration: 0.4, stiffness: 1000, damping: 100 }}
        >
          {dates.map((d, i: number) => {
            const hasActivities = departureDates.findIndex((date) => DAYJS(date).format('DD MMM YYYY') === DAYJS(d.date).format('DD MMM YYYY')) !== -1
            return (
              <Fragment key={i + 'adasdfe'}>
                <motion.div
                  style={{
                    cursor: hasActivities ? "pointer" : "not-allowed",
                    borderRadius: '10px',
                    color: currentIndex == i ? 'white' : 'inherit',
                    background: currentIndex == i ? 'transparent' : hasActivities ? '#ffffff29' : 'transparent',
                    maskImage: currentIndex !== i ? 'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0))' : 'none',
                  }}
                  whileTap={hasActivities ? { scale: 1.2 } : { scale: 1 }}
                  whileHover={hasActivities ? { scale: 1.1 } : { scale: 1 }}
                  onClick={(e) => {
                    if (!hasActivities) return
                    goToDepartureDate(d.date)
                  }}
                  key={i} className={'datecarddesktop'}>
                  <b className='mt-1 daylabel-calendar-header'
                    style={{
                      fontWeight: `${hasActivities ? 'bold' : 'normal'}`,
                      opacity: `${hasActivities ? 1 : 0.6}`
                    }}
                  >{d.day}</b>
                  <b
                    style={{
                      fontWeight: `${hasActivities ? 'bold' : 'normal'}`,
                      opacity: `${hasActivities ? 1 : 0.6}`
                    }}
                    className='mb-1 datelabel-calendar-header'>{d.date?.getDate()}</b>
                  {currentIndex == i && <motion.div
                    animate={{ scale: [1, 1.2, 1] }}
                    transition={{
                      type: 'tween',
                      duration: 0.4,
                      easings: ["easeIn", "easeOut"]
                    }}
                    layoutId='selectcard' className='datecard-selecteddesktop' />}
                </motion.div>
              </Fragment>
            )
          })}
        </motion.div>

      </div>
      <motion.div
        style={{
          cursor: "pointer",
        }}
        whileTap={{ scale: 1.2 }}
        whileHover={{ scale: 1.1 }}
        onClick={slideRight}
        className="chevron-right-desktop">
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.75 14.4263L11.25 9.92627L6.75 5.42627"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </motion.div>
    </>
  );
}, (prevProps, nextProps) => { return prevProps.currentDate == nextProps.currentDate })

export const Icon = ({ type }: { type: string }) => {
  return icons.hasOwnProperty(type) ? icons[type] : null;
};



const Timeline = memo(({ items, step, currentDate, handleCardSelect, dates, departureDates }: { departureDates: Date[]; items: any; step: any, dates: { date: Date, day: string }[], currentDate: string, handleCardSelect: (index: number) => void }) => {
  const OPTIONS = { containScroll: false } as any;
  const [temp, setTemp] = useState(0)
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);



  useEffect(() => {
    // alert(step?.calendarStep)
    // debugger;
    // emblaApi?.scrollTo(step?.calendarStep || 0)
    setTemp(step?.calendarStep || 0)
  }, [step?.calendarStep])


  useEffect(() => {
    // get scroll index from item?.index
    const scrollindex = items.findIndex((item: any) => item?.index === temp)
    emblaApi?.scrollTo(scrollindex)
  }, [temp])


  return (
    <motion.div
      layout
      transition={{
        type: 'tween',
      }}
      className="d-flex w-full ">

      <Carousel options={OPTIONS} embla={[emblaRef, emblaApi]}

      >

        {items
          .map((item: any, i: number) => {
            return (
              <CardComponent
                key={item?.index + 'asdasd'}
                item={item}
                opened={temp}
                current={item?.index}
                type={item.type}
                handleCardSelect={() => {
                  setTemp(item?.index)
                  setTimeout(() => {
                    handleCardSelect(item?.index)
                  }, 400)
                }}
                i={i}
              />
            );
          })}
      </Carousel>

      <div>
        {items.map((item: any) => {
          return (
            <div key={item?.index} className="d-flex">
              <div className="timeline-dot" />
              <div className="timeline-line" />
            </div>
          );
        })}
      </div>
    </motion.div>
  );
}, (prevProps, nextProps) => {
  return prevProps.currentDate === nextProps.currentDate;
}
)

const CardComponent = ({
  opened,
  current,
  item,
  type,
  handleCardSelect,
  i
}: {
  opened: number;
  current: number;
  item: any;
  type: any;
  handleCardSelect: () => void,
  i: number

}) => {


  const isOpen = opened === current

  return (
    <motion.div
      layout
      key={item?.index}

      className="timeline-card"
      style={{
        padding: "5px",
      }}
      whileHover={{ scaleX: 1.05, cursor: "pointer" }}
      onClick={() => handleCardSelect()}
    >
      <motion.div
        className='timeline-card-background'
        initial={{ opacity: 0 }}
        animate={{
          opacity: isOpen ? 1 : 0
        }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
      >

      </motion.div>

      <div className="timeline-closed-flexible-container">
        <div className='timeline-horizontal-flex'>
          <motion.span layoutId={'icon' + i}>

            <IconWrapper updater={type}>
              {getIconBySelectedTransport(type)}
            </IconWrapper>
          </motion.span>

          <div className="details">


            {!isOpen &&
              <motion.span
                className="details-summary"

                animate={isOpen ? { opacity: 0, y: -100 } : { opacity: 1, y: 0 }}
                transition={{ duration: 0.25 }}

              >
                {getActionBySelectedTransport(item?.type)} to
              </motion.span>
            }

            <span className="titlelabel-desktop">
              {computeHeading(item?.type, item?.title)}
            </span>

            {!isOpen &&
              <motion.span
                className="durationlabel-desktop"
                initial={isOpen ? { opacity: 0, } : { opacity: 0.95 }}
                animate={isOpen ? { opacity: 0, } : { opacity: 0.95 }}
              >
                {item.duration}
              </motion.span>
            }

          </div>

        </div>
        <AnimatePresence>
          {isOpen &&
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className='seperator' />
            </div>
          }

        </AnimatePresence>
        <AnimatePresence>

          {isOpen && <motion.div className="card-info-desktop" initial={{
            opacity: isOpen ? 1 : 0,
            display: isOpen ? "flex" : "none",
            // height: isOpen ? "inherit" : "0px"
          }}
            transition={{ duration: 0.25 }}

            animate={isOpen ? { opacity: 1, display: "flex" } : { opacity: 0, display: "none" }} >
            <div className="d-flex flex-column" style={{ width: "fit-content", maxHeight: "fit-content" }}>
              <b className="placelabel-desktop">
                {item.from}
              </b>
              <span className="timelabel-desktop">
                {item.departure}
              </span>
            </div>
            <div style={{ maxWidth: "125px", alignSelf: "center", flexGrow: 0.5, justifySelf: "center", margin: "5px" }}>
              {opened === current && <AnimatedProgress />}
              <span className="timelabel-desktop">{item.duration}</span>
            </div>
            <div className="d-flex flex-column" style={{ width: "fit-content", maxHeight: "fit-content" }}>
              <b className="placelabel-desktop">
                {item.to.length > 10
                  ? item.to.substring(0, 10) + '...'
                  : item.to}
              </b>
              <span className="timelabel-desktop">
                {item.arrival.length > 10
                  ? item.arrival.substring(0, 10) + '...'
                  : item.arrival}
              </span>
            </div>
          </motion.div >}
        </AnimatePresence>
      </div>
    </motion.div >
  );
};

export const AnimatedProgress = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
    const unsub = durationSignal.subscribe(setProgress);
    return () => {
      unsub();
    };
  }, []);

  return (
    <motion.progress
      className="progress mb-1 mt-2"
      value={progress}
      max="1"
    ></motion.progress>
  );
};

const IconWrapper = ({
  children,
  updater,
}: {
  children: any;
  updater: any;
}) => {
  const [mounted, setMounted] = useState(true);
  // useEffect(() => {
  //   setMounted(false);
  //   setTimeout(() => {
  //     setMounted(true);
  //   }, 250);
  // }, [updater]);

  return (
    <>{mounted && <div className="IconWrapper-desktop">{children}</div>}</>
  );
};

export const formatCurrentDate = (date: string) => {
  if (!date) return '';
  let newDate = DAYJS(date).toDate();
  return newDate.toISOString().split('T')[0];
};


export const calculateDuration = (start: Date, end: Date): any => {
  if (!start || !end) return '';

  const diff = Math.abs(DAYJS(end).valueOf() - DAYJS(start).valueOf());
  const minutes = Math.floor(diff / 1000 / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  // console.log(`${days}d ${remainingHours}h ${remainingMinutes}m`);
  if (days > 0)
    return (
      <>
        {' '}
        <b>{`${days}`}</b>
        <span className="me-1">d</span> <b>{`${remainingHours}`}</b>
        <span className="me-1">hr</span> <b>{`${remainingMinutes}`}</b>
        <span className="me-1">mins</span>{' '}
      </>
    );
  if (remainingHours > 0)
    return (
      <>
        {' '}
        <b>{`${remainingHours}`}</b>
        <span className="me-1">hr</span> <b>{`${remainingMinutes}`}</b>
        <span className="me-1">mins</span>{' '}
      </>
    );
  if (remainingMinutes > 0)
    return (
      <>
        {' '}
        <b>{`${remainingMinutes}`}</b>
        <span className="me-1">mins</span>{' '}
      </>
    );
};
