// React Imports
import React, { useState, useRef, useEffect } from 'react';

// MUI Imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Fab from '@mui/material/Fab';
import Slider from '@mui/material/Slider';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';

// Style Imports
import useStyles from './styles';

import MapView from '~/map/MapView';

// Redux Imports
import { RootState, store, useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import { useSelector } from '~/redux/reducers';

// Utility Imports
import {
  PublishableTravelData,
  PublishableTravelObject,
  TravelFormData,
} from '~/utility/models';

// Icon Imports
import { Icon } from '@iconify/react';

// Child Component Imports
import CarModelSelector from './CarModelSelector';
import PlaneModelSelector from './PlaneModelSelector';
import FullScreenModal from './FullScreenModal';
import AddMusicModal from './AddMusicModal';
import {
  checkTransparency,
  getAllFilesInFolder,
  getAudioDuration,
  uploadFileToStorage,
  validateFileMimeType,
} from '~/utility/utils';
import { AddLogoModal } from './AddLogoModal';
import { addLogoSignal } from '../signals/logoSignals';
import { useSignals } from '@preact/signals-react/runtime';
import { addMusicSignal } from '../signals/musicSignals';
import { initialAudioState } from './constants';

/**
 * Represents the props for the TravelForm component.
 * @interface videoPopupProps
 * @property {Function} handleClose - Handles close VideoPopup
 */
interface videoPopupProps {
  handleClose: () => void;
}

/**
 * Renders Video Popup to show animation of a Travel
 * @returns {JSX.Element} Returns VideoPopup component
 */
const VideoPopup = (props: videoPopupProps) => {
  const classes = useStyles();

  useSignals();

  // Local States
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccessMessage] = useState('');

  const [addMusicModalOpen, setAddMusicModalOpen] = useState<boolean>(false); // controls if the music modal is opened or not

  const [addLogoModalOpen, setAddLogoModalOpen] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const fullscreenMode: boolean = useSelector(
    (state: RootState) => state.MapReducers.fullscreenMode,
  );

  const travelArray: TravelFormData[] = useSelector(
    (state: RootState) => state.MapReducers.pointsArray,
  );

  const isRecording: boolean = useSelector(
    (state: RootState) => state.MapReducers.isRecording,
  );

  const index: number = useSelector(
    (state: RootState) => state.MapReducers.travelHistoryIndex,
  );

  const currentHistoryID: number = useSelector(
    (state: RootState) => state.MapReducers.travelHistoryTrackingID,
  );

  const dispatch = useDispatch();

  const userID: string = useSelector(
    (state: RootState) => state.MapReducers.userID,
  );

  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );

  const mapStyleIndex: number = useSelector(
    (state: RootState) => state.MapReducers.mapStyleIndex,
  );

  /**
   * Reset the audioState to initial
   * @returns void
   */
  const handleRemoveAudio = () => {
    addMusicSignal.value = initialAudioState;
  };

  /**
   * Reset the logo to initial
   * @returns void
   */
  const handleRemoveLogo = () => {
    // setLogo(() => '');
    addLogoSignal.value = '';
  };

  /**
   * This function opens Popup to select Map Style Types
   */
  const handleOpenMapTypes = () => {
    dispatch(ActionsCreator.openMapCustomizationPopup(true));
  };

  const baseUrl = window.location.origin;

  /**
   * This function closes the opened Snackbar
   */
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  /**
   * This function encodes the TourID
   * @param {number} tourID - Id of a Tour/Travel
   * @returns Returns excoded tour Id
   */
  function encodeTourID(tourID: number) {
    const encodedTourID = tourID * 9 + 100010;
    return encodedTourID;
  }

  const videoLength: number = useSelector(
    (state: RootState) => state.MapReducers.videoLength,
  );

  const modelSize: number = useSelector(
    (state: RootState) => state.MapReducers.modelSize,
  );

  const publishableTravelData: PublishableTravelData[] = useSelector(
    (state: RootState) => state.MapReducers.publishableTravelData,
  );

  const [videoSpeedState, setVideoSpeed] = useState(videoLength); // Initial value for video Speed
  const [modelSizeState, setModelSize] = useState(modelSize); // Initial value for modal size slider`

  const link = useRef<string | null>('');

  /**
   * This function publishes a Travel and create a link of Published Travel
   */
  const handlePublishButtonClick = async () => {
    if (publishableTravelData) {
      setLoading(true);
      const publishableTravelObject: PublishableTravelObject = {
        travelPoints: publishableTravelData,
        mapStyleIndex,
        userName,
        ...(addMusicSignal.value?.url && {
          audio: addMusicSignal.value.url,
        }),
        ...(addLogoSignal.value && {
          logo: addLogoSignal.value,
        }),
      };
      ActionsCreator.publishTravelPoints(
        publishableTravelObject,
        userID,
        currentHistoryID,
      )
        .then((data: number) => {
          setLoading(false);

          const hashedTourID = encodeTourID(data);

          link.current = `${baseUrl}/viewtravel?tourID=${hashedTourID}`;

          dispatch(ActionsCreator.setPublishedTravelLink(link.current));

          setSuccessMessage(`Updated Successfully: ${link.current}`);
          setSnackbarOpen(true);

          setTimeout(() => {
            dispatch(ActionsCreator.showSharePopup(true));
          }, 1000);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  /**
   * This function toggles Full Screen of VideoPopup or Animation
   */
  const handleFullScreenToggle = () => {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => console.log('Document Exited from Full screen mode'))
        .catch((err) => console.error(err));
    } else {
      if (document.documentElement.requestFullscreen)
        document.documentElement.requestFullscreen();
    }
    dispatch(ActionsCreator.setFullscreenAnimationMode(!fullscreenMode));
  };

  /**
   * Handles the change of file (audio) uploaded for the trip.
   * @param event - The event object representing the change event.
   * @returns void
   */
  const handleFileChangeLogo = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      setLoading(true);

      try {
        if (
          validateFileMimeType({
            file,
            validFileTypes: ['image/png', 'image/PNG'],
          })
        ) {
          const sizeInMB = Math.floor(file.size / (1024 * 1024));

          if (sizeInMB > 50) {
            setErrorMessage('Please select a file less than 50MB');
            // setAddMusicModalOpen((prev) => !prev);
            // Dispatch action to show the Snackbar
            setErrorSnackbarOpen(true);
            return;
          }

          const img = new Image();
          img.src = URL.createObjectURL(file);

          img.onload = async () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(img, 0, 0);

              const imageData = ctx.getImageData(0, 0, img.width, img.height);
              const hasTransparency = checkTransparency(imageData);

              if (!hasTransparency) {
                setErrorMessage(
                  'Please upload an image with a transparent background.',
                );
                // setAddMusicModalOpen((prev) => !prev);
                // Dispatch action to show the Snackbar
                setErrorSnackbarOpen(true);
                return;
              }
              // Check if current count of files in storage is less than 20
              const { success, files: existingFiles } =
                await getAllFilesInFolder(
                  'user-logo',
                  store.getState().MapReducers.userID,
                );
              if (!success) {
                console.error('Error fetching existing files');
                setErrorMessage('Error fetching existing files.');
                // Dispatch action to show the Snackbar
                setErrorSnackbarOpen(true);
                return;
              }

              if (existingFiles && existingFiles.length >= 20) {
                console.error(
                  'You have reached the maximum audio you can upload.',
                );
                setErrorMessage(
                  'You have reached the maximum audio you can upload.',
                );
                // Dispatch action to show the Snackbar
                setErrorSnackbarOpen(true);
                return;
              }

              // Upload to storage
              const result = await uploadFileToStorage({
                bucketName: 'user-logo',
                userId: store.getState().MapReducers.userID,
                file: file,
              });

              if (result.success && result.fileUrl) {
                // update the logo
                addLogoSignal.value = result.fileUrl.data.publicUrl;
              } else {
                setErrorMessage(
                  'An error occured while uploading your file, please try again.',
                );
                // Dispatch action to show the Snackbar
                setErrorSnackbarOpen(true);
              }
            }
            URL.revokeObjectURL(img.src); // Clean up URL object
          };
        } else {
          setErrorMessage('Invalid file, only PNG is allowed');
          // Dispatch action to show the Snackbar
          setErrorSnackbarOpen(true);
          return;
        }
      } catch (error) {
        console.error('Error uploading files:', error);
        setErrorMessage(
          'An error occured while uploading your file, please try again.',
        );
        // Dispatch action to show the Snackbar
        setErrorSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * Handles the change of file (audio) uploaded for the trip.
   * @param event - The event object representing the change event.
   * @returns void
   */
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const sizeInMB = Math.floor(file.size / (1024 * 1024));

      if (sizeInMB > 50) {
        setErrorMessage('Please select a file less than 50MB');
        // setAddMusicModalOpen((prev) => !prev);
        // Dispatch action to show the Snackbar
        setErrorSnackbarOpen(true);
        return;
      }

      setLoading(true);

      try {
        if (
          validateFileMimeType({
            file,
            validFileTypes: [
              'audio/mp3',
              'audio/mpeg',
              'audio/mpeg3',
              'audio/x-mpeg-3',
              'audio/mpg',
              'audio/x-mp3',
              'audio/x-mpeg',
            ],
          })
        ) {
          // Check if current count of files in storage is less than 20
          const { success, files: existingFiles } = await getAllFilesInFolder(
            'travel-musics',
            store.getState().MapReducers.userID,
          );
          if (!success) {
            console.error('Error fetching existing files');
            setErrorMessage('Error fetching existing files.');
            // Dispatch action to show the Snackbar
            setErrorSnackbarOpen(true);
            return;
          }

          if (existingFiles && existingFiles.length >= 20) {
            console.error('You have reached the maximum audio you can upload.');
            setErrorMessage(
              'You have reached the maximum audio you can upload.',
            );
            // Dispatch action to show the Snackbar
            setErrorSnackbarOpen(true);
            return;
          }

          // Upload to storage
          const result = await uploadFileToStorage({
            bucketName: 'travel-musics',
            userId: store.getState().MapReducers.userID,
            file: file,
          });

          if (result.success && result.fileUrl) {
            // Update audio state with uploaded file details
            addMusicSignal.value = {
              id: 'audio-1',
              name: file.name,
              type: file.type,
              size: sizeInMB, // Size in MB
              url: result.fileUrl.data.publicUrl,
              duration: await getAudioDuration(file), // Get duration (if needed)
            };
          } else {
            setErrorMessage(
              'An error occured while uploading your file, please try again.',
            );
            // Dispatch action to show the Snackbar
            setErrorSnackbarOpen(true);
          }
        } else {
          setErrorMessage('Invalid file, only mp3 is allowed');
          // Dispatch action to show the Snackbar
          setErrorSnackbarOpen(true);
          return;
        }
      } catch (error) {
        console.error('Error uploading files:', error);
        setErrorMessage(
          'An error occured while uploading your file, please try again.',
        );
        // Dispatch action to show the Snackbar
        setErrorSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * Handles the removal of file.
   * @param event - The event object representing the change event.
   * @returns void
   */
  // const handleFileRemoval = async (id: string) => {};

  /**
   * Handles the drag-over event to enable file drop functionality.
   *
   * This function prevents the default behavior of the drag-over event to allow files to be dropped
   * onto the target element. It ensures that the drop event is not blocked by default browser behavior.
   *
   * @param event - The drag-over event triggered when a file is dragged over the drop target.
   *
   * @returns {void}
   *
   * @example
   * // Example usage in a component
   * <div
   *   onDragOver={handleDragOver}
   *   onDrop={handleDrop}
   * >
   *   Drop files here
   * </div>
   */
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  /**
   * Handles the file drop event to process and upload the dropped file.
   *
   * This function processes the dropped file by performing several checks and actions:
   * - Validates the MIME type of the file.
   * - Checks if the file size exceeds the allowed limit (50MB).
   * - Verifies if the number of existing files in storage is below the maximum limit (20 files).
   * - Uploads the file to storage if all validations pass.
   * - Updates the state with the details of the uploaded file.
   * - Displays error messages if any issues occur during validation or upload.
   *
   * @param event - The drop event triggered when a file is dropped onto the target element.
   *
   * @returns {Promise<void>} A promise that resolves when the file has been processed or an error occurs.
   *
   * @example
   * // Example usage in a component
   * <div
   *   onDragOver={handleDragOver}
   *   onDrop={handleDrop}
   * >
   *   Drop files here
   * </div>
   */
  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];

    setLoading(true);

    if (file) {
      if (
        validateFileMimeType({
          file,
          validFileTypes: [
            'audio/mp3',
            'audio/mpeg',
            'audio/mpeg3',
            'audio/x-mpeg-3',
            'audio/mpg',
            'audio/x-mp3',
            'audio/x-mpeg',
          ],
        })
      ) {
        const sizeInMB = Math.floor(file.size / (1024 * 1024));

        if (sizeInMB > 50) {
          setErrorMessage('Please select a file less than 50MB');
          // Dispatch action to show the Snackbar
          setErrorSnackbarOpen(true);
          setLoading(false);

          return;
        }

        try {
          // Check if current count of files in storage is less than 20
          const { success, files: existingFiles } = await getAllFilesInFolder(
            'travel-musics',
            store.getState().MapReducers.userID,
          );
          if (!success) {
            console.error('Error fetching existing files');
            setErrorMessage('Error fetching existing files.');
            // Dispatch action to show the Snackbar
            setErrorSnackbarOpen(true);
            setLoading(false);

            return;
          }

          if (existingFiles && existingFiles.length >= 20) {
            console.error('You have reached the maximum audio you can upload.');
            setErrorMessage(
              'You have reached the maximum audio you can upload.',
            );
            // Dispatch action to show the Snackbar
            setErrorSnackbarOpen(true);
            setLoading(false);

            return;
          }

          // Upload to storage
          const result = await uploadFileToStorage({
            bucketName: 'travel-musics',
            userId: store.getState().MapReducers.userID,
            file: file,
          });

          if (result.success && result.fileUrl) {
            // Update audio state with uploaded file details
            addMusicSignal.value = {
              id: 'audio-1',
              name: file.name,
              type: file.type,
              size: sizeInMB, // Size in MB
              url: result.fileUrl.data.publicUrl,
              duration: await getAudioDuration(file), // Get duration (if needed)
            };
          } else {
            console.log({ result });
            setErrorMessage(
              'An error occurred while uploading your file, please try again.',
            );
            // Dispatch action to show the Snackbar
            setErrorSnackbarOpen(true);
            setLoading(false);

            return;
          }
        } catch (error) {
          console.error('Error uploading files:', error);
          setErrorMessage(
            'An error occurred while uploading your file, please try again.',
          );
          // Dispatch action to show the Snackbar
          setErrorSnackbarOpen(true);
        } finally {
          setLoading(false);
        }
      } else {
        setErrorMessage('Please drop an MP3 file.');
        // Dispatch action to show the Snackbar
        setErrorSnackbarOpen(true);
        setLoading(false);

        return;
      }
    }
  };

  /**
   * Handles the file drop event to process and upload the dropped file.
   *
   * This function processes the dropped file by performing several checks and actions:
   * - Validates the MIME type of the file.
   * - Checks if the file size exceeds the allowed limit (50MB).
   * - Verifies if the number of existing files in storage is below the maximum limit (20 files).
   * - Uploads the file to storage if all validations pass.
   * - Updates the state with the details of the uploaded file.
   * - Displays error messages if any issues occur during validation or upload.
   *
   * @param event - The drop event triggered when a file is dropped onto the target element.
   *
   * @returns {Promise<void>} A promise that resolves when the file has been processed or an error occurs.
   *
   * @example
   * // Example usage in a component
   * <div
   *   onDragOver={handleDragOver}
   *   onDrop={handleDropLogo}
   * >
   *   Drop files here
   * </div>
   */
  const handleDropLogo = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];

    setLoading(true);

    if (file) {
      if (
        validateFileMimeType({
          file,
          validFileTypes: ['image/png', 'image/PNG'],
        })
      ) {
        try {
          const sizeInMB = Math.floor(file.size / (1024 * 1024));

          if (sizeInMB > 50) {
            setErrorMessage('Please select a file less than 50MB');
            // Dispatch action to show the Snackbar
            setErrorSnackbarOpen(true);
            setLoading(false);
            return;
          }

          const img = new Image();
          img.src = URL.createObjectURL(file);

          img.onload = async () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(img, 0, 0);

              const imageData = ctx.getImageData(0, 0, img.width, img.height);
              const hasTransparency = checkTransparency(imageData);

              if (!hasTransparency) {
                setErrorMessage(
                  'Please upload an image with a transparent background.',
                );
                // setAddMusicModalOpen((prev) => !prev);
                // Dispatch action to show the Snackbar
                setErrorSnackbarOpen(true);
                setLoading(false);
                return;
              }

              // Check if current count of files in storage is less than 20
              const { success, files: existingFiles } =
                await getAllFilesInFolder(
                  'user-logo',
                  store.getState().MapReducers.userID,
                );
              if (!success) {
                console.error('Error fetching existing files');
                setErrorMessage('Error fetching existing files.');
                // Dispatch action to show the Snackbar
                setErrorSnackbarOpen(true);
                setLoading(false);
                return;
              }

              if (existingFiles && existingFiles.length >= 20) {
                console.error(
                  'You have reached the maximum audio you can upload.',
                );
                setErrorMessage(
                  'You have reached the maximum audio you can upload.',
                );
                // Dispatch action to show the Snackbar
                setErrorSnackbarOpen(true);
                setLoading(false);

                return;
              }

              // Upload to storage
              const result = await uploadFileToStorage({
                bucketName: 'user-logo',
                userId: store.getState().MapReducers.userID,
                file: file,
              });

              if (result.success && result.fileUrl) {
                // update the logo
                addLogoSignal.value = result.fileUrl.data.publicUrl;
              } else {
                setErrorMessage(
                  'An error occurred while uploading your file, please try again.',
                );
                // Dispatch action to show the Snackbar
                setErrorSnackbarOpen(true);
                setLoading(false);
                return;
              }
            }
            URL.revokeObjectURL(img.src); // Clean up URL object
          };
        } catch (error) {
          console.error('Error uploading files:', error);
          setErrorMessage(
            'An error occurred while uploading your file, please try again.',
          );
          // Dispatch action to show the Snackbar
          setErrorSnackbarOpen(true);
        } finally {
          setLoading(false);
        }
      } else {
        setErrorMessage('Invalid file, only PNG is allowed');
        // Dispatch action to show the Snackbar
        setErrorSnackbarOpen(true);
      }
    }
  };

  useEffect(() => {
    return () => {
      link.current = null;
    };
  }, []);

  return (
    <>
      <Box className={classes.maincontainer}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert variant="filled" severity="success">
            {success}.
          </Alert>
        </Snackbar>

        <Typography className={classes.title}>Preview Animation</Typography>
        <img
          src="/icons/cross.svg"
          alt="cancel"
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            padding: '18px',
            cursor: 'pointer',
          }}
          onClick={props.handleClose}
        />
        <div className={classes.mapSnapshot}>
          <div>
            {!fullscreenMode ? (
              <MapView isVideoPopupMap={false}></MapView>
            ) : (
              <FullScreenModal onClose={handleFullScreenToggle} />
            )}
          </div>
          <Button
            className={classes.bottomRightButton}
            onClick={handleOpenMapTypes}
          >
            Change Map Style
          </Button>
          <Fab
            className={classes.topRightFab}
            color="primary"
            aria-label="Add"
            onClick={handleFullScreenToggle}
          >
            <Icon
              className={classes.fullscreenIcon}
              icon="material-symbols:fullscreen"
              color="black"
            />
          </Fab>
          <Box className={classes.selectorContainer}>
            <PlaneModelSelector />
            <CarModelSelector />
          </Box>
        </div>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.sliderName1}>Video Speed</Typography>
            <Typography
              className={classes.sliderName1}
            >{`${videoSpeedState}x`}</Typography>
          </Box>
          <Slider
            classes={{ thumb: classes.customThumb }}
            aria-label="Video Length"
            value={videoSpeedState}
            onChange={(e, value) => setVideoSpeed(value as number)}
            onChangeCommitted={(e, value) => {
              setVideoSpeed(value as number);
              dispatch(ActionsCreator.setVideoLength(value as number));
            }}
            color="primary"
            valueLabelDisplay="off"
            min={0.25}
            max={2} //travelArray.length * 10
            disabled={isRecording}
            step={0.25}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.sliderName2}>Model Size</Typography>
            <Typography
              className={classes.sliderName2}
            >{`${modelSizeState}x`}</Typography>
          </Box>
          <Slider
            classes={{ thumb: classes.customThumb }}
            aria-label="Model Size"
            value={modelSizeState}
            onChange={(e, value) => setModelSize(value as number)}
            onChangeCommitted={(e, value) => {
              dispatch(ActionsCreator.setModelSize(value as number));
            }}
            color="primary"
            valueLabelDisplay="off"
            step={0.25}
            min={0.25}
            disabled={isRecording}
            max={2}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
          }}
        >
          {/* LOGO */}
          <Box>
            {addLogoSignal.value ? (
              <div
                style={{
                  position: 'relative',
                  height: '60px',
                  maxHeight: '60px',
                  width: 'auto',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    height: 48,
                    width: 142,
                    maxWidth: 142,
                    maxHeight: 42,
                    borderRadius: '44.32px',
                    background: 'transparent',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)', // Added shadow
                    backgroundImage: `url(${addLogoSignal.value})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    zIndex: 99,
                  }}
                  onClick={() => setAddLogoModalOpen((prev) => !prev)}
                >
                  <img
                    aria-label="close"
                    src="/icons/delete.svg"
                    alt="cancel"
                    style={{
                      position: 'absolute',
                      top: '-0.2rem',
                      right: '1rem',
                      cursor: 'pointer',
                      zIndex: 99999,
                    }}
                    title="Click to Remove"
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent the click event from propagating to the parent div
                      handleRemoveLogo();
                    }}
                  />
                </div>
              </div>
            ) : (
              <Button
                color="primary"
                startIcon={
                  <img
                    alt="Add Logo"
                    aria-label="Add logo"
                    src="/icons/plus.svg"
                  />
                }
                sx={{
                  border: 'none',
                  textTransform: 'none', // Disable uppercase transformation
                  backgroundColor: 'transparent', // Remove background color
                  '&:hover': {
                    backgroundColor: '#ececed',
                  },
                  '&:focus': {
                    backgroundColor: 'transparent', // Remove background color
                  },
                  '&:blur': {
                    backgroundColor: 'transparent', // Remove background color
                  },
                }}
                onClick={() => setAddLogoModalOpen((prev) => !prev)}
              >
                Add Logo
              </Button>
            )}
          </Box>
          {/* MUSIC */}
          <Box className={classes.addMusicButton}>
            {addMusicSignal.value?.url && addMusicSignal.value?.name ? (
              <div
                style={{
                  position: 'relative',
                  height: '60px',
                  maxHeight: '60px',
                  width: 'auto',
                }}
              >
                <img
                  alt="Music icon"
                  src="/icons/music-beat.svg"
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                  title="Click to Open"
                  onClick={() => setAddMusicModalOpen((prev) => !prev)}
                />
                <img
                  aria-label="close"
                  src="/icons/delete.svg"
                  alt="cancel"
                  style={{
                    position: 'absolute',
                    top: '-0.2rem',
                    right: '1rem',
                    cursor: 'pointer',
                  }}
                  title="Click to Remove"
                  onClick={handleRemoveAudio}
                />
              </div>
            ) : (
              <Button
                color="primary"
                startIcon={
                  <img
                    alt="Add Music"
                    aria-label="Add music"
                    src="/icons/plus.svg"
                  />
                }
                sx={{
                  border: 'none',
                  textTransform: 'none', // Disable uppercase transformation
                  backgroundColor: 'transparent', // Remove background color
                  '&:hover': {
                    backgroundColor: '#ececed',
                  },
                  '&:focus': {
                    backgroundColor: 'transparent', // Remove background color
                  },
                  '&:blur': {
                    backgroundColor: 'transparent', // Remove background color
                  },
                }}
                onClick={() => setAddMusicModalOpen((prev) => !prev)}
              >
                Add Music
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '15px',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }, // Column for mobile, Row for desktop
            justifyContent: 'space-between',
          }}
        >
          <LoadingButton
            key={index}
            loading={loading}
            disabled={loading || travelArray.length === 0}
            size="large"
            variant="contained"
            className={classes.bottombutton1}
            sx={{
              fontFamily: 'Futura Bold Italic',
            }}
            onClick={handlePublishButtonClick}
          >
            Publish
          </LoadingButton>
        </Box>
      </Box>
      <AddMusicModal
        open={addMusicModalOpen}
        handleClose={() => setAddMusicModalOpen((prev) => !prev)}
        loading={loading}
        handleFileChange={handleFileChange}
        handleRemoveAudio={handleRemoveAudio}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        isErrorOpen={errorSnackbarOpen}
        errorMessage={errorMessage}
        setIsErrorOpen={setErrorSnackbarOpen}
      />
      <AddLogoModal
        open={addLogoModalOpen}
        handleClose={() => setAddLogoModalOpen((prev) => !prev)}
        loading={loading}
        handleFileChange={handleFileChangeLogo}
        handleRemoveLogo={handleRemoveLogo}
        handleDragOver={handleDragOver}
        handleDrop={handleDropLogo}
        isErrorOpen={errorSnackbarOpen}
        errorMessage={errorMessage}
        setIsErrorOpen={setErrorSnackbarOpen}
      />
    </>
  );
};
export default VideoPopup;
