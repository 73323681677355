// constants.ts
export const mobileSize = 768;

// Define constants for social media links and email
export const SOCIAL_MEDIA_LINKS = {
  facebook: 'https://www.facebook.com/profile.php?id=61555207093836',
  twitter: 'https://twitter.com/VizualTravel',
  instagram: 'https://www.instagram.com/vizualtravelcom/',
  youtube: 'https://www.youtube.com/channel/UCercgvba-LPZLFIaXg5loBw',
};

export const SUPPORT_EMAIL = 'support@vizualtravel.com';
