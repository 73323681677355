import { LandTransportAnimationConfig } from '../AnimationController';
import {
  Config,
  MultiTransportStates,
  MultiTransportAnimationController,
} from '../MultiTransportAnimationController';
import { WalkAnimationController } from '../Walk/WalkAnimationController';

export class WalkState implements MultiTransportStates {
  private controller: WalkAnimationController;

  constructor(
    private map: any,
    private index: number,
    private tb: any,
    private stateMachine: MultiTransportAnimationController,
    private config: Config,
  ) {
    this.controller = new WalkAnimationController(
      this.map,
      this.index,
      this.tb,
    );
    this.stateMachine.streetLevelZoom = this.stateMachine.walkStreetLevelZoom;
    this.controller.setup(this.config, this.index, false);
  }

  onEnter(): void {
    this.startAnimation();
  }

  onReset() {
    if (this.controller) {
      this.controller.cleanup();
    }
  }

  setLineLayerAndSources() {
    this.controller.setLineLayerAndSources();
  }

  onCleanup() {
    if (this.controller) {
      this.controller.cleanup();
    }
  }

  onExit(): void {
    // Cleanup or transition logic here
    // if (this.controller) {
    //   this.controller.cleanup();
    // }
    this.stateMachine.streetLevelZoom = 15;
  }

  onPause() {
    if (this.controller) {
      this.controller.onPause();
    }
  }

  onPlay() {
    if (this.controller) {
      this.controller.onPlay();
    }
  }

  onUpdate(delta: number): void {
    this.controller.update(delta);
  }

  startAnimation(): void {
    const animationConfig: LandTransportAnimationConfig = {
      duration: this.stateMachine.durationForEachStep, // Replace with actual duration
      streetLevelZoom: 17,
      onCompleteCallback: () => {
        this.stateMachine.setState(this.stateMachine.transitionState); // Transition to the next state
      },
    };
    this.controller.setupAnimation(animationConfig);
  }
}
