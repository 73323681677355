import React from 'react';
import { useSelector } from '~/redux/reducers';
import { Snackbar, Slide, Alert } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { RootState } from '~/redux/store'; // Update with the correct path to your store
import ActionsCreator from '~/redux/actions';
import { useDispatch } from '~redux/store';

interface CustomSnackbarProps {
  handleClosePopup: () => void;
}

/**
 * CustomSnackbar component to display notifications.
 *
 * @component
 * @example
 * <CustomSnackbar />
 *
 * @returns {JSX.Element} The rendered CustomSnackbar component
 */
const CustomSnackbar = ({ handleClosePopup }: CustomSnackbarProps) => {
  const dispatch = useDispatch();

  const setCustomSnackbarState: boolean = useSelector(
    (state: RootState) => state.MapReducers.snackbarOpen,
  );

  const handleClose = () => {
    // Dispatch an action to close the dialog
    dispatch(ActionsCreator.setSnackbarOpen(false));
    if (handleClosePopup) handleClosePopup();
  };

  const customSnackbarMessage: string = useSelector(
    (state: RootState) => state.MapReducers.snackbarMessage,
  );

  const customSnackbarSeverity: boolean = useSelector(
    (state: RootState) => state.MapReducers.snackbarSeverity,
  );

  return (
    <Snackbar
      open={setCustomSnackbarState}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'right' } as TransitionProps}
    >
      <Alert
        variant="filled"
        severity={customSnackbarSeverity ? 'success' : 'error'}
        onClose={handleClose}
      >
        {customSnackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
