import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { RootState, useDispatch } from '~redux/store';
import Button from '@mui/material/Button';
import { CircularProgress, Grid } from '@mui/material';
import ActionsCreator from '~redux/actions';
import useStyles from './styles';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useSelector } from '~/redux/reducers';
import { useAuth } from '~/managers/AuthContext';

/**
 * Navbar component for the application.
 * @component
 */
export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const anchorRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600); // Detect initial screen width
  const { signOut } = useAuth();
  const [logoutState, setLogoutState] = useState<boolean>(false);
  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const profilePictureURL: string = useSelector(
    (state: RootState) => state.MapReducers.profileImgURL,
  );
  const selectedProfilePicture = '/icons/croppedLogoVizualTravel.svg';
  const classes = useStyles();

  /**
   * Opens the user menu.
   * @param {React.MouseEvent<HTMLElement>} event - The click event.
   */
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  /**
   * Navigates to the history page and updates the state.
   */
  const handleOpenHistoryPage = () => {
    navigate('/history');
    dispatch(ActionsCreator.openHistoryPageState(true));
    handleCloseUserMenu();
  };

  /**
   * Navigates to the settings page and updates the state.
   */
  const handleOpenSettingsPage = () => {
    navigate('/settings');
    dispatch(ActionsCreator.openSettingsPageState(true));
    handleCloseUserMenu();
  };

  /**
   * Opens the feedback popup.
   */
  const handleFeedbackPopup = () => {
    setAnchorElUser(null);
    dispatch(ActionsCreator.setFeedbackPopupState(true));
  };

  /**
   * Closes the user menu.
   */
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /**
   * Handles user logout.
   */
  const handleLogout = () => {
    setLogoutState(true);

    signOut()
      .then(() => {
        const tokenKey = process.env.REACT_APP_SUPABASE_TOKEN_KEY as string;
        localStorage.removeItem(tokenKey);
        dispatch(ActionsCreator.resetStateToInitial());
        navigate('/login');
        setAnchorElUser(null);
        setLogoutState(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * Handles window resize events to adjust mobile view state.
   */
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    // Add a listener for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    return () => {
      anchorRef.current = null;
    };
  }, []);

  return (
    <AppBar className={classes.appbar} position="static">
      <Container className={classes.containerset} maxWidth={false}>
        <Toolbar className={classes.toolbar} disableGutters>
          <Box className={classes.logoBox}>
            <img
              src="/logoVizualTravel.svg"
              alt="Logo"
              onClick={() => navigate('/homepage')}
              style={{ cursor: 'pointer' }}
            />
          </Box>

          <Box className={classes.feedbackContainer}>
            <Typography className={classes.earlyAccess}>
              👋 Welcome to the Early Access
            </Typography>
            <Box className={classes.typographyBox}>
              <Typography className={classes.suggestion}>
                If you have any suggestions,{' '}
              </Typography>
              <Button
                className={classes.suggestionButton}
                color="primary"
                onClick={handleFeedbackPopup}
              >
                Give Feedback
              </Button>
            </Box>
          </Box>

          <Box className={classes.menuBox}>
            <Button
              className={classes.menuButton}
              onClick={handleOpenUserMenu}
              disableRipple
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <div className={classes.iconButton} ref={anchorRef}>
                    {!isMobile ? (
                      <img
                        className={classes.iconButtonImg}
                        src={
                          !profilePictureURL
                            ? selectedProfilePicture
                            : profilePictureURL
                        }
                        alt="Icon Button"
                      />
                    ) : (
                      <img src="/icons/MobileMenuTop.svg" alt="add here" />
                    )}
                  </div>
                </Grid>
                {!isMobile && (
                  <Grid item>
                    <Typography className={classes.userName}>
                      {userName} {'     '}
                      <img src="/icons/nameIcon.svg" alt="add here" />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Button>
            <Menu
              className={classes.menu}
              id="menu-appbar"
              anchorEl={anchorRef.current}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                className={classes.menuItem}
                onClick={handleOpenHistoryPage}
              >
                <img src="/icons/History.svg" alt="Add a Travel" />
                <Typography className={classes.menuItemtext} textAlign="center">
                  History
                </Typography>
              </MenuItem>

              <Divider variant="middle" className={classes.divider} />

              <MenuItem
                className={classes.menuItem}
                onClick={handleOpenSettingsPage}
              >
                <img src="/icons/Settings.svg" alt="Add a Travel" />
                <Typography className={classes.menuItemtext} textAlign="center">
                  Settings
                </Typography>
              </MenuItem>
              <Divider variant="middle" className={classes.divider} />

              <MenuItem
                className={classes.menuItem}
                onClick={handleFeedbackPopup}
                sx={{
                  '@media (min-width: 850px)': { display: 'none' },
                }}
              >
                <Icon
                  icon="fluent:person-feedback-32-regular"
                  color="#fe7138"
                  width={25}
                />
                <Typography className={classes.menuItemtext} textAlign="center">
                  Feedback
                </Typography>
              </MenuItem>
              <Divider
                className={classes.divider}
                variant="middle"
                sx={{
                  '@media (min-width: 850px)': { display: 'none' },
                }}
              />
              <MenuItem className={classes.menuItem} onClick={handleLogout}>
                <Box className={classes.menuItemBox}>
                  <img src="/icons/Logout.svg" alt="Add a Travel" />
                  <Typography
                    className={classes.menuItemtext}
                    textAlign="center"
                  >
                    Logout
                  </Typography>
                </Box>
                {logoutState && (
                  <Box className={classes.logoutStateBox}>
                    <CircularProgress className={classes.circular} size={24} />
                  </Box>
                )}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
