// React Imports
import React from 'react';

// MUI Imports
import { Modal } from '@mui/material';

// Child Component Imports
import PointSchedule from '~/components/PointSchedule';

// Redux Imports
import { useSelector } from '~/redux/reducers';
import { useDispatch } from '~redux/store';
import ActionsCreator from '~redux/actions';
import { RootState } from '~/redux/reducers';

// Utility Imports
import { TravelFormData, FormData } from '~/utility/models';

/**
 * Renders a PointScheduleFormContainer
 * @param {Object} props - The props object.
 * @param {TravelFormData[]} travelData - Travel Form Data array contain travels
 * @param {FormData} currentPointData - Current Point Data contains location,dateTime,category and timezone
 * @returns {JSX.Element} Returns PointScheduleFormContainer component
 */
const PointScheduleFormContainer = (props: {
  travelData: TravelFormData[];
  currentPointData: FormData;
}) => {
  const dispatch = useDispatch();
  const openItineraryPopUp = useSelector(
    (state: RootState) => state.MapReducers.popupState,
  );

  /**
   * Handle Close PointSchedule Modal
   */
  const handleClose = () => {
    dispatch(ActionsCreator.openPopUP(false));
  };

  // Sample Props for PointSchedule Component
  const sampleProps = {
    travelData: [],
    currentPointAirport: 'Default Current Airport',
    currentPointCity: 'Default Current City',
    currentPointCountry: 'Default Current Country',
    handleClose: () => {},
  };

  // Custom Props for PointSchedule Component
  const customProps =
    props.travelData !== undefined
      ? {
          travelData: props.travelData,
          currentPointAirport:
            props.currentPointData?.location?.label ||
            'Default Departure Airport',
          currentPointCity: props.currentPointData?.location?.city || '',
          currentPointCountry: props.currentPointData?.location?.country || '',
          currentPointCategory: props.currentPointData.category || '',
          handleClose: handleClose,
        }
      : sampleProps;

  return (
    <Modal
      open={openItineraryPopUp}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <PointSchedule {...customProps} />
      </div>
    </Modal>
  );
};

export default PointScheduleFormContainer;
