import React from 'react';
import MapView from '~/map/MapView';
import FloatingActionButtons from '~components/FloatingActionButtons';
import ControlsSpace from '~components/ControlsSpace';
import Navbar from '../NavBar';
import FeedbackPopup from '../FeedbackPopup';

/**
 * MainPageUi component - renders the main UI of the application including
 * Navbar, FloatingActionButtons, ControlsSpace, AnimationView, and FeedbackPopup.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component.
 */
export default function MainPageUi() {
  return (
    <>
      <Navbar />
      <FeedbackPopup />
      <FloatingActionButtons />
      <ControlsSpace />
      <MapView isVideoPopupMap={true} />
    </>
  );
}
