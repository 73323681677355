import React from 'react'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './CarouselButtons'

const Carousel = (props: any) => {
  const { slides, children, options } = props
  const [emblaRef, emblaApi] = props?.embla

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick

  } = usePrevNextButtons(emblaApi)



  return (
    <section className="embla" style={{
      ...props?.style
    }}>
      <div className="embla__viewport" ref={emblaRef} style={{
        ...props?.viewportStyle
      }}>
        <div className="embla__container"

          style={{
            display: 'flex',
            flexDirection: props?.vertical ? 'column' : 'row'
          }}
        >
          {/* {slides.map((index:any) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">{index + 1}</div>
            </div>
          ))} */}
          {
            children.map((child: any, index: any) => (
              <div className="embla__slide" key={index}
                style={{ ...props?.slideStyle }}
              >
                {child}
              </div>
            ))
          }
        </div>
      </div>

      {/* <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div> */}
    </section>
  )
}

export default Carousel
