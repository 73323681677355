import { State } from '../models';
import { TravelAnimation } from '../TravelAnimation';

/**
 * This class represents the `MoveToSegment` state within the `TravelAnimation` state machine.
 * It's responsible for handling the logic of transitioning between animation segments.
 */
export class MoveToSegment implements State {
  private stateMachine: TravelAnimation;

  constructor(stateMachine: TravelAnimation) {
    this.stateMachine = stateMachine;
  }

  /**
   * Called when entering the `MoveToSegment` state.
   * - Increments the `travelSegmentIndex` to move to the next segment,
     or resets it to 0 if it's the last segment.
   * - Clears the animation data if the segment index is 0.
   * - Transitions the state machine to the `animateCameraToOrigin` state.
   */
  onEnter() {
    if (this.stateMachine.devMode) console.log('Entering MoveToSegment state');

    const totalSegments = this.stateMachine.animationTravelData.length;

    if (this.stateMachine.currentSegmentIndex < totalSegments - 1)
      this.stateMachine.currentSegmentIndex += 1;
    else if (
      this.stateMachine.currentSegmentIndex ===
      this.stateMachine.animationTravelData.length - 1
    ) {
      if (!this.stateMachine.isPublishedTravelPath) {
        this.stateMachine.currentSegmentIndex = 0;
      } else {
        this.stateMachine.callbackOnTravelAnimationEnd();
        return;
      }
    }

    if (this.stateMachine.currentSegmentIndex === 0) {
      this.stateMachine.clean();
    }

    this.stateMachine.setState(this.stateMachine.states.animateCameraToOrigin);
  }

  /**
   * Called when exiting the `MoveToSegment` state.
   */
  onExit() {
    if (this.stateMachine.devMode) console.log('Exiting MoveToSegment state');
  }
}
