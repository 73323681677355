import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { Popover, Typography } from '@mui/material';
import useStyles from './styles';

/**
 * AttributionControl component - Displays an information icon that shows attribution details in a popover.
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.placement - Determines the placement of the control
 * @returns {React.ReactElement} - Rendered component
 */
const AttributionControl = ({ placement }: { placement: boolean }) => {
  const classes = useStyles({ placement });
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  /**
   * handleTogglePopover - Toggles the state of the popover.
   *
   * @param {React.MouseEvent} event - The click event
   */
  const handleTogglePopover = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.bottomRight}>
      <Icon
        className={classes.infoButton}
        icon="mdi:information-outline"
        onClick={handleTogglePopover}
        width={20}
        height={20}
      />

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleTogglePopover} // Close the popover on click
      >
        <Typography className={classes.infoText}>
          {' '}
          ©{' '}
          <a
            href="http://www.openstreetmap.org/copyright"
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            rel="noopener noreferrer"
          >
            OpenStreetMap
          </a>{' '}
        </Typography>
      </Popover>
    </div>
  );
};

export default AttributionControl;
