// React Imports
import React, { useState, useEffect, useRef } from 'react';

// MUI Imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';

// Redux Imports
import ActionsCreator from '~redux/actions';
import { useDispatch } from '~redux/store';
import { useSelector } from '~/redux/reducers';
import { CombinedState } from 'redux';
import { MapProps } from '~/redux/reducers/MapReducers';
import { TravelProps } from '~/redux/reducers/TravelReducers';
import { AnimationProps } from '~/redux/reducers/AnimationReducers';

// Child Component Imports
import ScheduleSection from '~components/ScheduleSection';
import EditMenu from '~components/EditMenu';

// Utility Imports
import { TravelFormData } from '~/utility/models';

// Style Imports
import useStyles from './styles';

/**
 * This function concatenates the suffix st,nd,rd,th with the provided index
 * @param {number} index - index to concatenate st, nd and rd
 * @returns {string} Returns the suffix based on the index provided
 */
function getSuffix(index: number) {
  const suffixes = ['st', 'nd', 'rd'];
  const num = index + 1;
  const lastDigit = num % 10;
  const secondLastDigit = Math.floor(num / 10) % 10;

  if (secondLastDigit === 1) {
    return 'th';
  }

  return suffixes[lastDigit - 1] || 'th';
}

/**
 * Renders Travel Itinerary Page to show Travel Schedule of a Travel
 * @returns {JSX.Element} Returns TravelPage component
 */
export default function TravelPage() {
  const dispatch = useDispatch();

  // TravelPoints array from Redux
  const travelPoints: TravelFormData[] = useSelector(
    (
      state: CombinedState<{
        MapReducers: MapProps;
        TravelReducers: TravelProps;
        AnimationReducers: AnimationProps;
      }>,
    ) => state.MapReducers.pointsArray,
  );

  const [isMobile] = useState(window.innerWidth <= 600); // Detect initial screen width

  // Local States
  const [openedInMobileMode, setOpenedInMobileMode] = useState(isMobile);
  const [isTravelPageVisible, setIsTravelPageVisible] = useState(true);

  /**
   * This function closes the Travel Itinerary Popup
   */
  const handleClose = () => {
    dispatch(ActionsCreator.openTravelItinerary(false));
  };

  /**
   * This function opens Video Popup to show animation of travel
   */
  const handleOpenVideoPopup = () => {
    dispatch(ActionsCreator.openTravelItinerary(false));
    dispatch(ActionsCreator.openVideoPopup(true));
  };

  const classes = useStyles();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  /**
   * useEffect to draw dotted line vertically on leftside in Travel Itinery Popup to show Travel Schedule
   * @param {TravelFormData[]} travelPoints - Travel Points Array contain complete travel data
   */
  useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const ctx2 = canvas.getContext('2d');
    const indexSpan = document.getElementById('index');
    const parentCard = document.getElementById('parent-card');

    if (canvas && indexSpan && parentCard) {
      const indexMidY = indexSpan.offsetTop + indexSpan.offsetHeight / 2;
      const parentCardBottom = parentCard.offsetHeight - indexMidY; // Calculate bottom of parent-card

      const divHeight = parentCardBottom - indexMidY;

      canvas.height = divHeight + 14;
      canvas.width = 17;

      /**
       * Draw dots on top and bottom of the dashed line
       * @param {number[]} - pattern is an array of numbers
       */
      const drawDashedLine = function (pattern: number[]) {
        if (ctx && ctx2) {
          const canvasMidX = canvas.width / 2;
          //top circle
          ctx.beginPath();
          ctx.arc(canvasMidX, indexMidY, 7, 0, 2 * Math.PI); // Top dot
          ctx.strokeStyle = '#FE7138';
          ctx.stroke();
          //top dot
          ctx.beginPath(); // Start a new sub-path for the filled circle
          ctx.arc(canvasMidX, indexMidY, 4, 0, 2 * Math.PI); // Top dot
          ctx.fillStyle = '#FE7138';
          ctx.fill();
          //bottom circle
          ctx.beginPath();
          ctx.arc(canvasMidX, parentCardBottom - 10, 7, 0, 2 * Math.PI); // Bottom dot
          ctx.strokeStyle = '#FE7138';
          ctx.stroke();
          //bottom dot
          ctx.beginPath();
          ctx.arc(canvasMidX, parentCardBottom - 10, 5, 0, 2 * Math.PI); // Bottom dot
          ctx.fillStyle = '#FE7138';
          ctx.fill();

          //dashed line
          ctx.beginPath();
          ctx.arc(canvasMidX, indexMidY, 4, 0, 2 * Math.PI); // Top dot
          ctx.fillStyle = '#FE7138';
          ctx.fill();
          ctx.setLineDash(pattern);
          ctx.strokeStyle = '#FE7138'; // Set the color of the dashed line
          ctx.beginPath();
          ctx.moveTo(canvasMidX, indexMidY); // Move the starting point to the center of the canvas
          ctx.lineTo(canvasMidX, parentCardBottom); // Draw a vertical line to the bottom of the parent-card
          ctx.stroke();
        }
      };

      // Initial draw of dashed lines
      drawDashedLine([5, 5]);
      return () => {
        if (ctx) ctx.clearRect(0, 0, canvas.width, canvas.height);
      };
    }
  }, [travelPoints]);

  /**
   * useEffect to clean canvasRef before component unmount
   */
  useEffect(() => {
    return () => {
      canvasRef.current = null;
    };
  }, []);

  /**
   * This function opens Travel Itinerary to add/edit/delete a travel point from start or end
   */
  const addTravelSchedule = () => {
    if (travelPoints.length > 0) {
      dispatch(ActionsCreator.setIsEditingTravelForm(false));
      dispatch(ActionsCreator.setIndexForModifyTravelForm(travelPoints.length));
      dispatch(ActionsCreator.openModifyTravelForm(true));
      dispatch(ActionsCreator.setTravelFormSaveState(false));
      dispatch(ActionsCreator.setDisabledState(true));
    } else if (travelPoints.length === 0) {
      dispatch(ActionsCreator.openTravelForm(true));
    }
    handleClose(); // Close the menu
  };

  /**
   * This function add a travel point at start of a Travel
   */
  const addTravelPointAtStart = () => {
    if (travelPoints.length > 0) {
      // Open TravelItinery Popup if Travel Selected
      dispatch(ActionsCreator.setIsEditingTravelForm(true));
      dispatch(ActionsCreator.setIndexForModifyTravelForm(-1));
      dispatch(ActionsCreator.openModifyTravelForm(true));
      dispatch(ActionsCreator.setTravelFormSaveState(false));
      dispatch(ActionsCreator.setDisabledState(true));
    } else if (travelPoints.length === 0) {
      // Open Add Travel Form if Travel not selected
      dispatch(ActionsCreator.openTravelForm(true));
    }
    handleClose(); // Close the menu
  };

  /**
   * useEffect to make Travel Itinerary responsive on Mobile and Desktop
   */
  useEffect(() => {
    const handleResize = () => {
      setOpenedInMobileMode(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * This function opens Travel Form in editing state and close the EditMenu
   * @param {number} index - The index of the travel item to be edited.
   */
  const handleEditClick = (index: number) => {
    dispatch(ActionsCreator.setIndexForModifyTravelForm(index));
    dispatch(ActionsCreator.setTravelFormSaveState(false));
    dispatch(ActionsCreator.openModifyTravelForm(true));
    dispatch(ActionsCreator.setIsEditingTravelForm(true));
    handleClose();
  };

  /**
   * This function opens Travel Form in editing state and close the EditMenu
   * @param {number} index - The index of the travel item to be edited.
   */
  const handleDeleteClick = (index: number) => {
    deleteObjectFromArray(index);
    if (travelPoints.length === 1) {
      dispatch(ActionsCreator.setTravelFormSaveState(false));
    }
  };

  /**
   * This functions deletes the delete the selected travel point from TravelPoints array based on index
   * @param {number} index The index of the travel item to be deleted
   */
  function deleteObjectFromArray(index: number) {
    const updatedTravelPoints = [...travelPoints];
    updatedTravelPoints.splice(index, 1);

    dispatch(ActionsCreator.updateTravelPoints(updatedTravelPoints));
  }

  useEffect(() => {
    return () => {
      canvasRef.current = null;
    };
  }, []);

  return (
    <div>
      <Box
        className={
          openedInMobileMode
            ? classes.mobileContainerStyle
            : classes.containerStyle
        }
        top="0"
        right="0"
        bgcolor="#ffffff"
        display="flex"
        flexDirection="column"
        padding="20px"
      >
        <Button
          onClick={() => setIsTravelPageVisible(!isTravelPageVisible)}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 2, // Ensure the button is on top of the content
          }}
        ></Button>
        <Typography className={classes.title}>Travel Itinerary</Typography>
        <img
          src="/icons/cross.svg"
          alt="cancel"
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            padding: '24px',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />

        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <div
            id="parent-card"
            style={{ display: 'inline-block', position: 'relative' }}
          >
            <canvas
              ref={canvasRef}
              width={4}
              height="100%"
              style={{ position: 'absolute' }}
            />
            {travelPoints.map((travel, index) => (
              <div
                id="carddiv"
                key={index}
                style={{
                  marginLeft: '25px',
                  marginBottom: '25px',
                  fontFamily: 'Futura Hv BT',
                  position: 'relative',
                }}
              >
                <EditMenu
                  index={index}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  travelPointsLength={travelPoints.length}
                />
                <span id="index" className={classes.indexnumber}>
                  {index + 1}
                  {getSuffix(index)}
                </span>
                <Typography className={classes.fromtext}>
                  From {travel.departure.location?.city} to{' '}
                  {travel.arrival.location?.city}
                </Typography>
                <ScheduleSection travelPointItem={travelPoints[index]} />

                <Divider
                  id="divider"
                  sx={{ bgcolor: 'gray', marginTop: '25px' }}
                />
              </div>
            ))}
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Button
              className={classes.bottombutton1}
              variant="contained"
              onClick={addTravelPointAtStart}
            >
              Add another point at the start
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              className={classes.bottombutton1}
              variant="contained"
              onClick={addTravelSchedule}
            >
              Add another point at the end
            </Button>
          </Grid>
        </Grid>
        <Button
          className={classes.bottombutton2}
          onClick={handleOpenVideoPopup}
          variant="contained"
        >
          Generate Video Preview
        </Button>
      </Box>
    </div>
  );
}
