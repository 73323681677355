import { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FilledInput,
  Snackbar,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import copy from 'copy-to-clipboard';
import { useSelector } from '~/redux/reducers';
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import useStyles from './styles';
import { RootState } from '~/redux/reducers';

/**
 * SharePopup component for displaying and copying a shareable link.
 * @param {Object} props - The component props.
 * @param {string} props.link - The shareable link to display.
 * @returns {JSX.Element} The rendered SharePopup component.
 */
const SharePopup = ({
  link,
  onClose,
  setSnackbarOpen,
  snackbarOpen,
}: {
  link: string;
  onClose?: () => void;
  setSnackbarOpen?: (value: React.SetStateAction<boolean>) => void;
  snackbarOpen?: boolean;
}): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const [success, setSuccessMessage] = useState('');

  const sharePopupState: boolean = useSelector(
    (state: RootState) => state.AnimationReducers.sharePopupState,
  );

  /**
   * Handles copying the link to the clipboard and updates the copied state.
   */
  const handleCopyLink = () => {
    copy(link);
    setIsCopied(true);
    setSuccessMessage?.(`Copied Successfully: ${link}`);
    setSnackbarOpen?.(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <Dialog
      maxWidth="md"
      open={sharePopupState}
      classes={{ paper: classes.paper }}
      onClose={() => {
        dispatch(ActionsCreator.showSharePopup(false));
        onClose?.();
      }}
    >
      {snackbarOpen && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen?.(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            zIndex: '1500 !important',
          }}
        >
          <Alert variant="filled" severity="success">
            {success}.
          </Alert>
        </Snackbar>
      )}
      <DialogTitle className={classes.headingContainer}>
        <Typography sx={{ fontFamily: 'Futura Hv BT', fontSize: ' 22px' }}>
          Share
        </Typography>
        <Icon
          icon="material-symbols:close"
          width="30"
          height="30"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(ActionsCreator.showSharePopup(false));
            onClose?.();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontFamily: 'Futura Medium' }}>
          Link for sharing:
        </Typography>
        <FilledInput
          fullWidth
          value={link}
          disableUnderline
          sx={{ input: { padding: '8px', fontFamily: 'Futura Bk BT Italic' } }}
          endAdornment={
            <Button
              sx={{
                cursor: 'pointer',
                width: '150px',
                fontFamily: 'Futura Medium',
                border: 1,
              }}
              onClick={handleCopyLink}
            >
              {isCopied ? 'Copied ✅' : 'Copy Link'}
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  );
};


export default SharePopup;
