import { signal, effect } from '@preact/signals-core';

//  Initialize signals
export const continueBlinkSignal = signal<boolean>(true);
export const getStartedBlinkSignal = signal<number>(0);
export const feedbackBlinkSignal = signal<boolean>(false);

//  Set up effects to automatically update signals

// When `continueBlinkSignal` becomes false, `getStartedBlinkSignal` becomes true
effect(() => {
  const continueBlink = continueBlinkSignal.value;
  if (!continueBlink) {
    getStartedBlinkSignal.value = 1;
  }
});

// When `getStartedBlinkSignal` becomes false, `feedbackBlinkSignal` becomes true

effect(() => {
  const getStartedBlink = getStartedBlinkSignal.value;

  // Declare a variable to hold the timeout ID
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  if (getStartedBlink === 2) {
    // Set a timeout to update feedbackBlinkSignal after 30 seconds
    timeoutId = setTimeout(() => {
      feedbackBlinkSignal.value = true;
    }, 30000); // 30 seconds
  }

  // Cleanup function to clear the timeout if the effect re-runs or the component unmounts
  return () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };
});
