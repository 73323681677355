import { Fragment, memo, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './style.css';
import {
  ImageViewer,
} from '../DesktopPicturesOverlay';
import { isCalendarAnimating } from '../DesktopFooter/CalendarLine';
import { autoOpenImagesSignal, imagesignal, mobile4thImagePostionSignal, openedList, setOpenedList } from '../common';

export default memo(() => {
  const [images, setImages] = useState<string[]>([]);
  const [hidden, setHidden] = useState(false);
  const [opened, setOpened] = useState({
    src: '',
    layoutId: '',
  });
  const [mounted, setMounted] = useState(false);
  const [timeOut, setTimeOut] = useState(null) as any;
  useEffect(() => {
    setImages([]);

    setTimeOut(
      setTimeout(() => {
        setMounted(true);
      }, 2000),
    );
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    const unsub = isCalendarAnimating.subscribe((e) => {
      setHidden(e);
    });
    const imagesignalunsub = imagesignal.subscribe((images) => {
      if (!images) return;
      setImages([])
      setImages(images)
    });
    const autoOpenImageSignalSub = autoOpenImagesSignal.subscribe((value) => {
      if (!value) return;
      const imgs = imagesignal.peek();

      if (!imgs || imgs.length === 0) return;
      if (imgs.some((img) => openedList.peek().includes(img))) return;
      setTimeout(() => {
        setOpened({ src: imgs[0], layoutId: imgs[0] });
        setOpenedList(imgs[0]);
      }, 1000)
    })
    return () => {
      unsub();
      imagesignalunsub();
      autoOpenImageSignalSub();
    };
  }, []);
  if (!mounted) return null;



  return (
    <AnimatePresence mode="sync">
      {opened.src ? <ImageViewer
        mobile
        images={images}
        opened={opened}
        setOpened={setOpened}
      /> : <Fragment>
        {!hidden && (
          <Fragment>
            <motion.div className="mobile-pictures-overlay" layout>
              <div className="mobile-pictures-overlay-row">
                {images.slice(0, 2).map((image, index) => (
                  <motion.div key={index} className="mobile-pictures-image-wrapper">
                    <motion.img
                      onClick={() => {
                        setOpened({ src: image, layoutId: image });
                        setOpenedList(image);
                      }}
                      key={`mobile-pic-overlay-row-1-${index}`}
                      loading="lazy"
                      initial={{ rotate: 0, opacity: 0, scale: 1 }}
                      animate={{
                        rotate: index === 0 ? -10 : 15,
                        opacity: openedList.peek().includes(image) ? 0.7 : 1,
                        scale: 1,
                      }}
                      transition={{ duration: 0.25 }}
                      exit={{ rotate: 0, opacity: 0, scale: 1 }}
                      layoutId={image}
                      src={image}
                      style={{
                        opacity: openedList.peek().includes(image) ? 0.7 : 1,
                        filter: openedList.peek().includes(image)
                          ? 'grayscale(80%)'
                          : 'none',
                      }}
                    />
                  </motion.div>
                ))}
              </div>
              <div className="mobile-pictures-overlay-row"> <div className="mobile-pictures-image-wrapper" /> </div>
              <div className="mobile-pictures-overlay-row">
                {images.slice(2, 3).map((image, index) => (
                  <motion.div key={index} className="mobile-pictures-image-wrapper">
                    <motion.img
                      onClick={() => {
                        setOpened({ src: image, layoutId: image });
                        setOpenedList(image);
                      }}
                      loading="lazy"
                      key={`mobile-pic-overlay-row-2-${index}`}
                      exit={{ rotate: 0, opacity: 0, scale: 1 }}
                      initial={{ rotate: 0, opacity: 0, scale: 1 }}
                      animate={{
                        rotate: index === 0 ? -15 : 25,
                        opacity: openedList.peek().includes(image) ? 0.7 : 1,
                        scale: 1,
                      }}
                      transition={{ duration: 0.25 }}
                      layoutId={image}
                      src={image}
                      style={{
                        filter: openedList.peek().includes(image)
                          ? 'grayscale(80%)'
                          : 'none',
                      }}
                    />
                  </motion.div>
                ))}
              </div>





            </motion.div>
            {images.slice(3, 4).map((image, index) => (
              <motion.div key={index} className="mobile-pictures-image-wrapper"
                style={{
                  position: 'absolute',
                  right: '20px',
                  bottom: mobile4thImagePostionSignal.peek()
                }}
              >
                <motion.img
                  onClick={() => {
                    setOpened({ src: image, layoutId: image });
                    setOpenedList(image);
                  }}
                  loading="lazy"
                  key={`mobile-pic-overlay-row-2-${index}`}
                  exit={{ rotate: 0, opacity: 0, scale: 1 }}
                  initial={{ rotate: 0, opacity: 0, scale: 1 }}
                  animate={{
                    rotate: index === 0 ? -15 : 25,
                    opacity: openedList.peek().includes(image) ? 0.7 : 1,
                    scale: 1,
                  }}
                  transition={{ duration: 0.25 }}
                  layoutId={image}
                  src={image}
                  style={{
                    filter: openedList.peek().includes(image)
                      ? 'grayscale(80%)'
                      : 'none',
                  }}
                />
              </motion.div>
            ))}
          </Fragment>
        )}
      </Fragment>
      }
    </AnimatePresence>
  );
});
