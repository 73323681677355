import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ModeOfTransportButton from '../ModeOfTransportButton';
import useStyles from './styles';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import {
  planeTransport,
  carTransport,
  ferryTransport,
  transitTransport,
  walkTransport,
  transportSelectorsArray,
} from './constants'; // Import the constants
import ImageContainer from '~/containers/ImageContainer';

/**
 * Props for the TransportSelector component
 */
interface TransportSelectorProps {
  onTransportChange: (transportType: TravelMode) => void;
  isPlaneSelected: boolean;
  isCarSelected: boolean;
  isTransitSelected: boolean;
  isFerrySelected: boolean;
  isWalkSelected: boolean;
  isPlaneHovered: boolean;
  isCarHovered: boolean;
  isTransitHovered: boolean;
  isWalkHovered: boolean;
  isFerryHovered: boolean;
  onMouseEnter: (transportType: TravelMode) => void;
  onMouseLeave: (transportType: TravelMode) => void;
  images: string[];
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveImage: (index: number) => void;
  isLoading: boolean;
  selectedTransport: TravelMode | null;
}

/**
 * Component for selecting mode of transport
 * @component
 * @returns {JSX.Element} The rendered TransportSelector component.
 */
const TransportSelector: React.FC<TransportSelectorProps> = ({
  onTransportChange,
  isPlaneSelected,
  isCarSelected,
  isTransitSelected,
  isFerrySelected,
  isWalkSelected,
  isPlaneHovered,
  isCarHovered,
  isTransitHovered,
  isWalkHovered,
  isFerryHovered,
  onMouseEnter,
  onMouseLeave,
  onFileChange,
  handleRemoveImage,
  images,
  isLoading,
  selectedTransport,
}: TransportSelectorProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      p={2}
      spacing={2}
      textAlign="center"
      className={classes.modeOfTransport}
      sx={{ width: '100%', margin: '0 auto' }}
    >
      <Grid
        item
        container
        spacing={1}
        xs={12}
        md={6}
        direction={{ xs: 'column', md: 'row' }}
        className={classes.formContainer}
      >
        <Typography className={classes.modeHeading}>
          Select Mode of Transport
        </Typography>
        <div
          className={classes.transportButtonsContainer}
          style={{
            marginTop: '1rem',
          }}
        >
          {transportSelectorsArray.slice(0, 5).map((method, index) => (
            <ModeOfTransportButton
              key={method.label + method.icon + `${index}`}
              label={method.label}
              icon={method.icon}
              isSelected={
                selectedTransport ===
                TravelMode[method.label as keyof typeof TravelMode]
              }
              onClick={() =>
                onTransportChange(
                  TravelMode[method.label as keyof typeof TravelMode],
                )
              }
              onMouseEnter={() =>
                onMouseEnter(
                  TravelMode[method.label as keyof typeof TravelMode],
                )
              }
              onMouseLeave={() =>
                onMouseLeave(
                  TravelMode[method.label as keyof typeof TravelMode],
                )
              }
            />
          ))}
          {/* <ModeOfTransportButton
            label={planeTransport.label}
            icon={
              isPlaneSelected || isPlaneHovered
                ? planeTransport.selectIcon
                : planeTransport.icon
            }
            isSelected={isPlaneSelected}
            onClick={() => onTransportChange(TravelMode.Plane)}
            onMouseEnter={() => onMouseEnter(TravelMode.Plane)}
            onMouseLeave={() => onMouseLeave(TravelMode.Plane)}
          />
          <ModeOfTransportButton
            label={transitTransport.label}
            icon={
              isTransitSelected || isTransitHovered
                ? transitTransport.selectIcon
                : transitTransport.icon
            }
            isSelected={isTransitSelected}
            onClick={() => onTransportChange(TravelMode.Transit)}
            onMouseEnter={() => onMouseEnter(TravelMode.Transit)}
            onMouseLeave={() => onMouseLeave(TravelMode.Transit)}
          />
          <ModeOfTransportButton
            label={carTransport.label}
            icon={
              isCarSelected || isCarHovered
                ? carTransport.selectIcon
                : carTransport.icon
            }
            isSelected={isCarSelected}
            onClick={() => onTransportChange(TravelMode.Car)}
            onMouseEnter={() => onMouseEnter(TravelMode.Car)}
            onMouseLeave={() => onMouseLeave(TravelMode.Car)}
          />
          <ModeOfTransportButton
            label={walkTransport.label}
            icon={
              isWalkSelected || isWalkHovered
                ? walkTransport.selectIcon
                : walkTransport.icon
            }
            isSelected={isWalkSelected}
            onClick={() => onTransportChange(TravelMode.Walk)}
            onMouseEnter={() => onMouseEnter(TravelMode.Walk)}
            onMouseLeave={() => onMouseLeave(TravelMode.Walk)}
          />
          <ModeOfTransportButton
            label={ferryTransport.label}
            icon={
              isFerrySelected || isFerryHovered
                ? ferryTransport.selectIcon
                : ferryTransport.icon
            }
            isSelected={isFerrySelected}
            onClick={() => onTransportChange(TravelMode.Ferry)}
            onMouseEnter={() => onMouseEnter(TravelMode.Ferry)}
            onMouseLeave={() => onMouseLeave(TravelMode.Ferry)}
          /> */}
        </div>
      </Grid>
      <Grid item container xs={12} md={6} spacing={1}>
        <ImageContainer
          images={images}
          handleRemoveImage={handleRemoveImage}
          type="transport"
          onFileChange={onFileChange}
          isDisabled={!selectedTransport}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default TransportSelector;
