import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

const useStyles = makeStyles((theme: typeof MuiTheme) => ({
    bottomRight: ({ placement }: { placement: boolean }) => ({
        position: 'fixed',
        bottom: theme.spacing(2),
        right: placement ? 0 : 20,
        cursor: 'pointer',
        zIndex: 99,
        display: 'flex',
        alignContent: 'center',
        [theme.breakpoints.between(100, 600)]: {
          right: -5,
          top: 150,
        },
      }),
      infoButton: {
        position: 'relative',
        background: 'none',
        textAlign: 'center',
        lineHeight: '30px',
        marginLeft: '-40px',
        opacity: 0.7,
        cursor: 'pointer',
      },
      popover: ({ placement }: { placement: boolean }) => ({
        marginLeft: placement ? '-30px' : '-50px',
        opacity: 0.7,
        [theme.breakpoints.between(300, 600)]: {
          marginLeft: '-30px',
        },
      }),
      infoText: {
        fontSize: '12px',
        backgroundColor: '#333',
        color: 'white',
        padding: '2px',
        borderRadius: theme.shape.borderRadius,
      },

}));

export default useStyles;
