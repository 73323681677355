/**
 * Import statements for required modules.
 */

import { projectToWorld } from '~/CustomThreeJsWrapper/utility/utils';
import {
  AnimationTravelData,
  DecodedPathDataObject,
  MarkerInstances,
  ModelDict,
} from '~/utility/models';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { BusState } from './multiTransportStates/BusState';
import { SubwayState } from './multiTransportStates/SubwayState';
import { WalkState } from './multiTransportStates/WalkState';
import { Marker } from './Marker';
import { Map } from 'maplibre-gl';
import { Position } from '@turf/turf';
import { TravelMode } from './utility/enums/TravelMode';
import { TrainState } from './multiTransportStates/TrainState';
import { TransitionState } from './multiTransportStates/TransitionState';
import { LandTransportAnimationConfig } from './AnimationController';
import { MetroState } from './multiTransportStates/MetroState';
import { TramState } from './multiTransportStates/TramState';
import { FerryState } from './multiTransportStates/FerryState';
import { StraightLineState } from './multiTransportStates/StraightLineState';
import { TransitModels } from './utility/enums/TransitModels';
import { FerryModels } from './utility/enums/FerryModels';
import { WalkModels } from './utility/enums/WalkModels';
import { CarModels } from './utility/enums/CarModels';
import { CarState } from './multiTransportStates/CarState';

/**
 * Interface defining the structure of a travel map entry.
 */
interface TravelMapEntry {
  TransitType: string;
  config: Config;
}

/**
 * Interface defining the configuration for a travel segment.
 */
interface TravelSegmentConfig {
  modelsArray: ModelDict[] | null;
  modelScale: number;
  animationSpeed: number;
}

/**
 * Interface defining the configuration object for the MultiTransportAnimationController.
 */
export interface Config {
  decodedPath: {
    path: Position[];
  };
  selectedTransport: TravelMode;
  travelSegmentConfig: TravelSegmentConfig;
}

/**
 * Interface defining the structure of a MultiTransportStates object.
 */
export interface MultiTransportStates {
  onEnter(): void;
  onExit(): void;
  onUpdate(delta: number): void;
  onCleanup?(): void;
  onDestroy?(): void;
  setLineLayerAndSources?(): void;
  onPause?(): void;
  onPlay?(): void;
}

/**
 * Manages the animation of multiple transport modes within a map.
 */
class MultiTransportAnimationController {
  states!: MultiTransportStates[];

  currentState!: MultiTransportStates;

  currentIndex!: number;

  map!: Map;

  tb!: CustomThreeJSWrapper;

  index!: number;

  travelSegment!: AnimationTravelData;

  /**
   * A number representing the current bearing (rotation) of the map in degrees.
   * A value of 0 degrees indicates north, while positive values rotate the map clockwise.
   */
  mapBearing: number = 0;

  /**
   * A number representing the current pitch (tilt) of the map in degrees.
   * A value of 0 degrees indicates a vertical view, while positive values tilt the map upwards.
   */
  mapPitch: number = 20;

  /**
   * A constant number defining the ideal zoom level for displaying the map at street level.
   * This value is  based on your map data and desired level of detail for street features.
   */
  streetLevelZoom: number = 15;
  walkStreetLevelZoom: number = 16;

  /**
   * A reference to a `MarkerAnimations` object that holds the animation definitions
   * for markers associated with the animated object.
   */
  markers: MarkerInstances | undefined;

  modelsArray!: ModelDict[];

  animationConfig!: LandTransportAnimationConfig;

  durationForEachStep!: number;

  transitData: TravelMapEntry[] = [];

  transitionState!: TransitionState;

  /**
   * Constructor for a MultiTransportAnimationController class.
   *
   * @param map - A reference to the Maplibre map instance
   * @param index - Index of the current travel segment
   * @param tb - A reference to the Three.js wrapper class
   */
  constructor(map: Map, index: number, tb: CustomThreeJSWrapper) {
    if (!map) return;
    this.map = map;
    this.tb = tb;
    this.index = index;
  }

  /**
   * Sets up the animation controller with the provided travel data.
   *
   * @param animationTravelData - Travel data for the animation
   * @param index - Index of the current segment
   * @param shouldSetupMarker - Flag indicating whether to setup markers
   */
  setup(
    animationTravelData: AnimationTravelData,
    index: number,
    shouldSetupMarker: boolean,
  ): void {
    if (shouldSetupMarker)
      this.markers = this.setupMarker(animationTravelData, index);
    this.travelSegment = animationTravelData;
    this.modelsArray = animationTravelData.travelSegmentConfig.modelsArray;
    this.transitData = this.createTravelMap(this.travelSegment.decodedPath);
    this.transitionState = new TransitionState(this, this.map);
    this.states = this.createStates(this.transitData);
    this.currentIndex = 0;
  }

  /**
   * Returns the starting zoom level for the animation.
   *
   * @returns - The starting zoom level
   */
  getAnimationStartZoom() {
    return this.streetLevelZoom;
  }

  /**
   * Sets up markers for the origin and destination of a travel segment.
   *
   * @param travelSegment - An object containing travel segment data including departure and arrival locations
   * @param index - The index of the travel segment within the animation data
   * @returns - An object containing references to the created origin and destination marker instances
   */
  setupMarker(travelSegment: AnimationTravelData, index: number) {
    const originMarkerInstance = new Marker(
      this.map,
      travelSegment.departure.location?.text as string,
      'Departure',
      travelSegment.departure,
      projectToWorld(travelSegment.departure.location?.coordinates as Position),
      this.tb,
      index,
    );

    const destinationMarkerInstance = new Marker(
      this.map,
      travelSegment.arrival.location?.text as string,
      'Arrival',
      travelSegment.arrival,
      projectToWorld(travelSegment.arrival.location?.coordinates as Position),
      this.tb,
      index,
    );

    originMarkerInstance.setup();
    destinationMarkerInstance.setup();

    return {
      origin: originMarkerInstance,
      destination: destinationMarkerInstance,
    };
  }

  /**
   * Starts the walk animation along the travel path.
   *
   * @param animationConfig - Configuration object for the walk animation
   */
  setupAnimation(animationConfig: LandTransportAnimationConfig) {
    this.animationConfig = animationConfig;

    this.durationForEachStep = animationConfig.duration;

    this.setState(this.transitionState);
  }

  /**
   * Creates states for each segment of the travel path.
   *
   * @param data - An array of travel map entries
   * @returns - An array of multi-transport states
   */

  createStates(data: TravelMapEntry[]): MultiTransportStates[] {
    return data.map((segment, index) => {
      let indexForRoutes = parseInt('' + this.index + index);
      if (segment.TransitType === 'Bus') {
        return new BusState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'Subway') {
        return new SubwayState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'Car') {
        return new CarState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'Train') {
        return new TrainState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'Tram') {
        return new TramState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'Metro') {
        return new MetroState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'Walk') {
        return new WalkState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'Ferry') {
        return new FerryState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'StraightLine') {
        return new StraightLineState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      } else if (segment.TransitType === 'NoTransitRoute') {
        return new BusState(
          this.map,
          indexForRoutes,
          this.tb,
          this,
          segment.config,
        );
      }

      throw new Error('Unknown segment type');
    });
  }

  /**
   * Updates the current state of the animation.
   *
   * @param delta - The time delta since the last update
   */
  update(delta: number): void {
    // RF: Todo
    this.transitionState.sourceIds.forEach((id) => {
      this.map.moveLayer(id);
    });

    if (this.map.getLayer('custom-threejs-layer'))
      this.map.moveLayer('custom-threejs-layer');

    this.currentState.onUpdate(delta);
  }

  /**
   * Sets the current state of the animation.
   *
   * @param newState - The new state to transition to
   */
  setState(newState: MultiTransportStates): void {
    if (this.currentState) this.currentState.onExit();
    this.currentState = newState;
    this.currentState.onEnter();
  }

  /**
   * Retrieves the current state of the animation.
   *
   * @returns - The current state
   */
  getCurrentState(): MultiTransportStates {
    return this.states[this.currentIndex];
  }

  /**
   * Sets line layers and sources for the current state.
   */
  setLineLayerAndSources(): void {
    if (this.currentState && this.currentState.setLineLayerAndSources)
      this.currentState.setLineLayerAndSources();
  }

  onPlay() {
    this.currentState.onPlay!();
  }

  onPause() {
    this.currentState.onPause!();
  }

  /**
   * Retrieves the point of step transition.
   *
   * @returns - The position of the step transition
   */
  getPointOfStepTransition(): Position {
    let currIndex =
      this.currentIndex > this.states.length - 1
        ? this.states.length - 1
        : this.currentIndex;
    const path = this.transitData[currIndex].config.decodedPath.path;
    const lastIndex =
      this.currentIndex > this.states.length - 1 ? path.length - 1 : 0;

    return path[lastIndex];
  }

  /**
   * Creates a travel map from the provided path data.
   *
   * @param pathData - The decoded path data object
   * @returns - An array of travel map entries
   */
  createTravelMap(pathData: DecodedPathDataObject): TravelMapEntry[] {
    // Initialize an empty array
    const travelMap: TravelMapEntry[] = [];
    if (pathData.data.length > 0) {
      // Iterate over each object in the data
      pathData.data.forEach((item) => {
        const { maneuver, path, transportName } = item;
        const config: Config = {
          decodedPath: {
            path: [],
          },
          travelSegmentConfig: {
            modelsArray: null,
            modelScale: this.travelSegment.travelSegmentConfig.modelScale,
            animationSpeed:
              this.travelSegment.travelSegmentConfig.animationSpeed,
          },
          selectedTransport: this.travelSegment.selectedTransport,
        };

        config.decodedPath.path = path;
        config.travelSegmentConfig.modelsArray = null;
        // Determine the type of transportation
        let modeDescription: string = '';
        if (transportName === 'Bus') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [
            this.modelsArray.find((item) => item.key === TransitModels.Bus),
          ] as ModelDict[];
        } else if (transportName === 'Train') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [
            this.modelsArray.find((item) => item.key === TransitModels.Train),
          ] as ModelDict[];
        } else if (transportName === 'Car') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [
            this.modelsArray.find((item) => item.key === CarModels.Car),
          ] as ModelDict[];
        } else if (transportName === 'Tram') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [
            this.modelsArray.find((item) => item.key === TransitModels.Tram),
          ] as ModelDict[];
        } else if (transportName === 'Subway') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [
            this.modelsArray.find((item) => item.key === TransitModels.Subway),
          ] as ModelDict[];
        } else if (transportName === 'Metro') {
          modeDescription = 'Metro';
          config.travelSegmentConfig.modelsArray = [
            this.modelsArray.find((item) => item.key === TransitModels.Metro),
          ] as ModelDict[];
        } else if (transportName === 'Walk') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [
            this.modelsArray.find((item) => item.key === WalkModels.Char),
          ] as ModelDict[];
        } else if (transportName === 'Ferry') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [
            this.modelsArray.find((item) => item.key === FerryModels.Ferry),
          ] as ModelDict[];
        } else if (transportName === 'StraightLine') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [];
        } else if (transportName === 'NoTransitRoute') {
          modeDescription = transportName;
          config.travelSegmentConfig.modelsArray = [];
        } else {
          modeDescription = maneuver; // Use maneuver if no specific type is found
        }

        // Add the travel mode and description to the map
        travelMap.push({ TransitType: modeDescription, config: config });
      });
    } else {
      const config: Config = {
        decodedPath: {
          path: [],
        },
        travelSegmentConfig: {
          modelsArray: null,
          modelScale: this.travelSegment.travelSegmentConfig.modelScale,
          animationSpeed: this.travelSegment.travelSegmentConfig.animationSpeed,
        },
        selectedTransport: this.travelSegment.selectedTransport,
      };

      config.decodedPath.path = pathData.path;
      config.travelSegmentConfig.modelsArray = null;
      // Determine the type of transportation
      let modeDescription = 'Bus';
      config.travelSegmentConfig.modelsArray = [
        this.modelsArray.find((item) => item.key === TransitModels.Bus),
      ] as ModelDict[];

      // Add the travel mode and description to the map
      travelMap.push({
        TransitType: modeDescription,
        config: config,
      });
    }

    return travelMap;
  }

  /**
   * Cleans up resources used by the animation controller.
   */
  cleanup() {
    for (let state of this.states) {
      if (state.onCleanup) state.onCleanup();
    }

    this.transitionState.onCleanup();
    this.currentIndex = 0;

    for (const markerInstance of Object.values(
      this.markers as MarkerInstances,
    )) {
      markerInstance.cleanup();
    }
  }

  /**
   * Destroys the animation controller and releases all resources.
   */
  destroy() {
    for (const markerInstance of Object.values(
      this.markers as MarkerInstances,
    )) {
      markerInstance.cleanup();
      markerInstance.destroy();
    }
    this.currentIndex = 0;
    this.markers = undefined;
    for (let state of this.states) {
      if (state.onDestroy) state.onDestroy();
    }
  }
}

export { MultiTransportAnimationController };
