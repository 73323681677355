import './style.css'; // Make sure to create a CSS file for additional styling
import { useAnimate } from 'react-simple-animate';
import Button from '@mui/material/Button';
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import { DAYJS } from '~/map/ViewTravel';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { useSignals } from '@preact/signals-react/runtime';
import { getStartedBlinkSignal } from '~/components/signals/blinkSignals';
import { pulsateAnimation } from '~/utility/utils';
import { RestartAnimationModal } from '../RestartAnimationModal';
import { useState } from 'react';
import { resetAllSignals, showPlayPauseButton } from '../common';

const GetStartedDesktop = ({
  setStep,
  startDate,
  endDate,
  reserve = false,
  to,
  restart,
}: {
  setStep: any;
  startDate: string;
  endDate: string;
  reserve?: boolean;
  to: string;
  restart?: (value: number) => void;
}) => {
  const navigate = useNavigate();
  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );

  useSignals();

  const { play, style, isPlaying } = useAnimate({
    duration: 0.15,
    easeType: 'ease-in-out',

    start: { transform: 'scale(1)', opacity: 1, position: 'relative' },
    end: { transform: 'scale(1.5)', opacity: 0.1, position: 'absolute' },
  });

  const [open, setOpen] = useState(reserve || false);

  const dispatch = useDispatch();

  const getStarted = () => {
    // added for blinking state
    getStartedBlinkSignal.value = 2;
    play(!isPlaying);
    showPlayPauseButton.value = true;
    dispatch(ActionsCreator.setPlayPauseState(false));

    setTimeout(() => {
      setStep('load');
    }, 100);
  };

  const handleReserve = () => {
    resetAllSignals();
    navigate('/homepage');
    setStep('idle');
  };

  const handleClose = () => {
    setOpen(false);
    restart?.(0);
    showPlayPauseButton.value = true;
  };

  return (
    <>
      <div className="getstarted-wrapper">
        {userName && (
          <div className="title">
            {/* Jess Trip <span className="mx-2"> Made by </span> Travelnow Agency{' '} */}
            Trip to {to}
            <span className="mx-2"> Made by </span> {userName}{' '}
          </div>
        )}

        <div className="date">
          From {DAYJS(startDate).format('MMM DD, YYYY')} to{' '}
          {DAYJS(endDate).format('MMM DD, YYYY')}
        </div>
        {/* <div className="rate">3500 USD</div> */}

        <Button
          style={style}
          variant="contained"
          className="get-started-button"
          onClick={reserve ? handleReserve : getStarted}
          sx={{
            ...(getStartedBlinkSignal.value && {
              '&:before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                animation: `${pulsateAnimation} 2s infinite`,
                borderRadius: '8px',
                border: '5px solid rgba(255, 255, 255, 0.75)',
                top: 0,
                left: 0,
                background: 'rgba(198, 16, 0, 0)',
                boxSizing: 'border-box',
              },
            }),
          }}
        >
          <b className="GetStarted">
            {reserve ? 'Make Your Own' : 'Get Started'}
          </b>
        </Button>
      </div>
      <RestartAnimationModal open={open} onClose={handleClose} />
    </>
  );
};

export default GetStartedDesktop;
