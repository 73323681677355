/**
 * A visual representation of an oscillating effect with three lines.
 * The component simulates a music beat by animating the lines with varying heights.
 *
 * @param rightHeight - The height of the right line in pixels. Defaults to 15px.
 * @param leftHeight - The height of the left line in pixels. Defaults to 12px.
 * @param centerHeight - The height of the center line in pixels. Defaults to 18px.
 * @param width - Optional width of the container in pixels. This is applied to the container styles.
 *
 * @returns A React component that renders an oscillating animation with three lines.
 *
 * @example
 * ```tsx
 * <Oscillator width={200} rightHeight={20} leftHeight={10} centerHeight={25} />
 * ```
 */
import React from 'react';
import { Box } from '@mui/material';
import { containerStyles, lineStyles, keyframes } from './styles';

// Define the type for the props
interface OscillatorProps {
  width?: number;
  rightHeight?: number;
  leftHeight?: number;
  centerHeight?: number;
}

// Use the defined props type in the component
const Oscillator: React.FC<OscillatorProps> = ({
  rightHeight = 15,
  leftHeight = 12,
  centerHeight = 18,
  width,
}) => {
  return (
    <Box sx={containerStyles}>
      <Box sx={lineStyles(leftHeight + 'px', '.5s', width)} />
      <Box sx={lineStyles(centerHeight + 'px', '.7s', width)} />
      <Box sx={lineStyles(rightHeight + 'px', '.3s', width)} />
      <style>{keyframes}</style>
    </Box>
  );
};

export default Oscillator;
