import React, { useRef, useState, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import useStyles from './styles';
import { RootState, useSelector } from '~/redux/reducers';
import ActionsCreator from '~/redux/actions';
import {
  TravelFormData,
  FormData,
  PublishableTravelDataWithDecodedPath,
  ModelKeys,
  Content3DLayer,
} from '~/utility/models';
import PointScheduleFormContainer from '~/containers/PointScheduleFormContainer';
import { useDispatch } from '~/redux/store';
import { CircularProgress } from '@mui/material';
import StaticTravelVisualizer from '~/animationEngine/StaticTravelVisualizer';
import { TravelAnimation } from '~/animationEngine/Travel/TravelAnimation';
import AttributionControl from '../../components/AttributionControl';
import {
  appendPublishableTravelObjectAnimationConfig,
  appendPublishableTravelObjectModelEnum,
  setupPublishableTravelObject,
  setupPublishableTravelObjectWithDecodedPath,
} from '~/utility/utils';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import Stats from 'stats.js';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import {
  initializeMapLibreMap,
  initializeTravelAnimation,
  onConfigUpdateOfPublishableTravelData,
  setStaticTravelVisualizer,
  setStyle,
} from '../helpers';
import VideoControls from '~/components/VideoControls';
import {
  pauseAnimationSignal,
  playAnimationSignal,
  useStateWithChangeCheck,
} from '~/components/ViewTravel/common';

interface MapViewProps {
  isVideoPopupMap: boolean;
}

const MapView = (props: MapViewProps) => {
  // Maplibre
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const isViewTravel = false;

  const map = useRef<maplibregl.Map | null>();

  const wrapper = useRef<CustomThreeJSWrapper | null>(null);

  const [loading, setLoading] = useState(false);

  const mapStyleIndex: number = useSelector(
    (state: RootState) => state.MapReducers.mapStyleIndex,
  );

  const travelFormData: TravelFormData[] = useSelector(
    (state: RootState) => state.MapReducers.pointsArray,
  );

  const videoLength: number = useSelector(
    (state: RootState) => state.MapReducers.videoLength,
  );

  const modelSize: number = useSelector(
    (state: RootState) => state.MapReducers.modelSize,
  );

  const planeModelEnum: ModelKeys[] = useSelector(
    (state: RootState) => state.MapReducers.planeModelEnum,
  );
  const walkModelEnum: ModelKeys[] = useSelector(
    (state: RootState) => state.MapReducers.walkModelEnum,
  );
  const ferryModelEnum: ModelKeys[] = useSelector(
    (state: RootState) => state.MapReducers.ferryModelEnum,
  );

  const transitModelEnum: ModelKeys[] = useSelector(
    (state: RootState) => state.MapReducers.transitModelEnum,
  );

  const carModelEnum: ModelKeys[] = useSelector(
    (state: RootState) => state.MapReducers.carModelEnum,
  );

  const [
    publishableAnimationTravelDataWithDecodedPath,
    setPublishableAnimationTravelDataWithDecodedPath,
  ] = useState<PublishableTravelDataWithDecodedPath[]>([]);

  const handleAnimationEnd = () => {
    // Set playPauseState to true when animation ends
    dispatch(ActionsCreator.setPlayPauseState(playPauseState));
  };

  const fullscreenMode: boolean = useSelector(
    (state: RootState) => state.MapReducers.fullscreenMode,
  );

  const classes = useStyles({
    fullscreenMode: fullscreenMode,
    scheduleSectionState: false,
  });

  const dispatch = useDispatch();

  // const stats1 = new Stats();
  // stats1.showPanel(0); // Panel 0 = fps
  // stats1.dom.style.cssText = 'position:absolute;top:0px;right:240px;'; // Change domElement to dom
  // document.body.appendChild(stats1.dom);

  const htmlCanvas = useRef<HTMLDivElement | null>(null);

  const [selectedTravelData, setTravelData] = useState<
    TravelFormData[] | undefined
  >();
  const [currentPointData, setCurrentPointData] = useState<
    FormData | undefined
  >();

  const currentTravelIndex = useSelector(
    (state) => state.AnimationReducers.currentTravelIndex,
  );

  const playPauseState = useSelector(
    (state) => state.AnimationReducers.playPauseState,
  );

  const handleMarkerClick = (
    travelData: TravelFormData[],
    index: number,
    currentMarker: string | null,
  ) => {
    let lastIndex = travelData.length - 1;
    if (currentMarker === 'origin') {
      setCurrentPointData(travelData[lastIndex].departure);
    } else {
      setCurrentPointData(travelData[lastIndex].arrival);
    }
    // setCurrentPointData(currentPointData , );
    setTravelData(travelData);
    dispatch(ActionsCreator.openPopUP(true));
  };

  // Plane Animation Controller
  const travelVisualizer = useRef<StaticTravelVisualizer | null>();
  const travelAnimation = useRef<TravelAnimation | null>();

  const content3DLayer: Content3DLayer = {
    id: 'custom-threejs-layer',
    type: 'custom',
    renderingMode: '3d',

    render() {
      if (wrapper.current) wrapper.current.update();

      travelAnimation.current?.update();

      // stats1.update();
    },
  };

  useEffect(() => {
    if (!map.current) {
      initializeMapLibreMap(
        map as React.MutableRefObject<maplibregl.Map>,
        mapContainer as React.MutableRefObject<HTMLDivElement>,
        mapStyleIndex,
        wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
        content3DLayer,
        isViewTravel,
        props.isVideoPopupMap,
        fullscreenMode,
      );
    }
  }, []);

  const onResourcesLoadingStateUpdate = (newLoadingState: boolean) => {
    setLoading(newLoadingState);
  };

  useEffect(() => {
    if (travelFormData.length > 0) {
      const travelData = Promise.all(
        travelFormData.map((data) =>
          setupPublishableTravelObjectWithDecodedPath(
            data,
            data.selectedTransport === TravelMode.Car
              ? carModelEnum
              : data.selectedTransport === TravelMode.Plane
              ? planeModelEnum
              : data.selectedTransport === TravelMode.Walk
              ? walkModelEnum
              : data.selectedTransport === TravelMode.Ferry
              ? ferryModelEnum
              : transitModelEnum,
            modelSize,
            videoLength,
          ),
        ),
      );

      travelData.then((data) => {
        setPublishableAnimationTravelDataWithDecodedPath(data);
      });
    } else {
      setPublishableAnimationTravelDataWithDecodedPath([]);
    }
  }, [travelFormData]);

  // Invoke on update of modelEnum and update in PublishableTravelData
  useEffect(() => {
    if (publishableAnimationTravelDataWithDecodedPath.length > 0) {
      setPublishableAnimationTravelDataWithDecodedPath(
        publishableAnimationTravelDataWithDecodedPath.map((data) =>
          appendPublishableTravelObjectModelEnum(
            data,
            data.selectedTransport === TravelMode.Car
              ? carModelEnum
              : data.selectedTransport === TravelMode.Plane
              ? planeModelEnum
              : data.selectedTransport === TravelMode.Walk
              ? walkModelEnum
              : data.selectedTransport === TravelMode.Ferry
              ? ferryModelEnum
              : transitModelEnum,
          ),
        ) as PublishableTravelDataWithDecodedPath[],
      );

      onConfigUpdateOfPublishableTravelData(
        true,
        travelAnimation as React.MutableRefObject<TravelAnimation>,
        publishableAnimationTravelDataWithDecodedPath,
      );
    }
  }, [planeModelEnum, carModelEnum, walkModelEnum, transitModelEnum]);

  // Invoke on update of modelScale and/or animationSpeed and update in PublishableTravelData
  useEffect(() => {
    if (publishableAnimationTravelDataWithDecodedPath.length > 0) {
      setPublishableAnimationTravelDataWithDecodedPath(
        publishableAnimationTravelDataWithDecodedPath.map((data) =>
          appendPublishableTravelObjectAnimationConfig(
            data,
            modelSize,
            videoLength,
          ),
        ) as PublishableTravelDataWithDecodedPath[],
      );
      onConfigUpdateOfPublishableTravelData(
        false,
        travelAnimation as React.MutableRefObject<TravelAnimation>,
        publishableAnimationTravelDataWithDecodedPath,
      );
      if (playPauseState) {
        dispatch(ActionsCreator.setPlayPauseState(false));
      }
    }
  }, [videoLength, modelSize]);

  useEffect(() => {
    const setPublishableTravel = async () => {
      if (publishableAnimationTravelDataWithDecodedPath.length > 0) {
        try {
          const publishableTravelData = await Promise.all(
            publishableAnimationTravelDataWithDecodedPath.map(async (data) => {
              return await setupPublishableTravelObject(data);
            }),
          );

          if (publishableTravelData.length > 0) {
            let clonedArray = JSON.parse(JSON.stringify(publishableTravelData));
            dispatch(ActionsCreator.setPublishableTravel(clonedArray));
          }
        } catch (error) {
          console.error('Error setting publishable travel data:', error);
        }
      }
    };

    setPublishableTravel();
  }, [publishableAnimationTravelDataWithDecodedPath]);

  useEffect(() => {
    if (
      !props.isVideoPopupMap &&
      publishableAnimationTravelDataWithDecodedPath.length > 0 &&
      !travelAnimation.current
    ) {
      // Tab is visible and video popup map is not active, create animation
      initializeTravelAnimation(
        travelAnimation as React.MutableRefObject<TravelAnimation>,
        publishableAnimationTravelDataWithDecodedPath,
        map as React.MutableRefObject<maplibregl.Map>,
        wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
        onResourcesLoadingStateUpdate,
        isViewTravel,
        handleAnimationEnd,
      );
      dispatch(ActionsCreator.setPlayPauseState(false));
    }
  }, [
    props.isVideoPopupMap,
    currentTravelIndex,
    publishableAnimationTravelDataWithDecodedPath,
    fullscreenMode,
    playPauseState,
    mapStyleIndex,
  ]);

  useEffect(() => {
    const playAnimationSub = playAnimationSignal.subscribe((val) => {
      if (val > 1) {
        travelAnimation.current?.setPlayPause(false);
      }
    });

    const pauseAnimationSub = pauseAnimationSignal.subscribe((val) => {
      if (val) {
        travelAnimation.current?.setPlayPause(true);
      }
    });

    return () => {
      playAnimationSub();
      pauseAnimationSub();
    };
  }, []);

  useEffect(() => {
    setStaticTravelVisualizer(
      publishableAnimationTravelDataWithDecodedPath,
      props.isVideoPopupMap,
      travelVisualizer as React.MutableRefObject<StaticTravelVisualizer>,
      map as React.MutableRefObject<maplibregl.Map>,
      wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
      handleMarkerClick,
    );
  }, [mapStyleIndex, publishableAnimationTravelDataWithDecodedPath]);

  useEffect(() => {
    setStyle(
      map as React.MutableRefObject<maplibregl.Map>,
      mapStyleIndex as number,
      travelAnimation as React.MutableRefObject<TravelAnimation>,
      publishableAnimationTravelDataWithDecodedPath,
      props.isVideoPopupMap,
      content3DLayer,
      playPauseState,
      travelVisualizer as React.MutableRefObject<StaticTravelVisualizer>,
      setLoading,
    );
    if (playPauseState) {
      dispatch(ActionsCreator.setPlayPauseState(false));
    }
  }, [mapStyleIndex]);

  // useEffect(() => {
  //   const mapElement = mapContainer.current;
  //   if (mapElement) {
  //     mapElement.appendChild(stats1.dom);
  //   }

  //   return () => {
  //     if (mapElement) {
  //       mapElement.removeChild(stats1.dom);
  //     }
  //   };
  // }, []);

  const setPlayPauseState = () => {
    travelAnimation.current?.setPlayPause(false);
  };

  useEffect(() => {
    return () => {
      if (travelAnimation.current) {
        travelAnimation.current.clean();
        travelAnimation.current.destroy();
        travelAnimation.current = null;
      }
      // dispatch(ActionsCreator.setPlayPauseState(false));
      if (map.current) {
        map.current.remove();
      }
    };
  }, []);

  return (
    <div className="body" style={{ position: 'relative' }} ref={htmlCanvas}>
      {loading && (
        <div className={classes.loadingScreen}>
          <CircularProgress size={40} thickness={4} />
        </div>
      )}
      {props.isVideoPopupMap ? (
        <>
          <div className={classes.mapsMainContainer} ref={mapContainer}></div>
          <AttributionControl placement={false}></AttributionControl>
        </>
      ) : (
        <>
          <div
            className={classes.mapsVideoPopupContainer}
            ref={mapContainer}
          ></div>
          {publishableAnimationTravelDataWithDecodedPath.length > 0 && (
            <VideoControls />
          )}
          {fullscreenMode ? (
            <AttributionControl placement={true}></AttributionControl>
          ) : (
            <></>
          )}
        </>
      )}

      <PointScheduleFormContainer
        travelData={selectedTravelData as TravelFormData[]}
        currentPointData={currentPointData as FormData}
      />
    </div>
  );
};

export default MapView;
