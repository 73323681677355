import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Dialog, Box, Fab, Button, Slide } from '@mui/material';
import ActionsCreator from '~redux/actions';
import useStyles from './styles';
import { TravelFormData } from '~/utility/models';
import { RootState } from '~/redux/reducers';

/**
 * FloatingActionButtons component for Add a Travel, Change Map or to Generate Animation.
 * @component
 * @returns {JSX.Element} The rendered FloatingActionButtons component.
 */
export default function FloatingActionButtons() {
  const [openDialog, setOpenDialog] = useState(false);
  const [icon, setIcon] = useState('/icons/GenerateVideo.svg'); // Default icon

  const classes = useStyles();
  const dispatch = useDispatch();

  // Fetch necessary states from the redux store
  const isSaved: boolean = useSelector(
    (state: RootState) => state.MapReducers.isTravelFormSaved,
  );
  const travelArray: TravelFormData[] = useSelector(
    (state: RootState) => state.MapReducers.pointsArray,
  );

  /**
   * Closes the dialog box.
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  /**
   * Handles the action of adding a travel form.
   */
  const handleAddTravelForm = () => {
    if (isSaved) {
      dispatch(ActionsCreator.toggleConfirmationDialogState(true));
    } else {
      dispatch(ActionsCreator.openTravelForm(true));
    }
  };

  /**
   * Handles the action of opening the travel itinerary.
   */
  const handleOpenTravelItinerary = () => {
    dispatch(ActionsCreator.openTravelItinerary(true));
    dispatch(ActionsCreator.setScheduleSectionState(true));
  };

  /**
   * Handles the action of opening the map customization popup.
   */
  const handleOpenMapTypes = () => {
    dispatch(ActionsCreator.openMapCustomizationPopup(true));
  };

  /**
   * Handles the action of opening the video popup.
   */
  const handleOpenVideoPopup = () => {
    if (travelArray.length === 0) {
      setOpenDialog(true);
    } else {
      dispatch(ActionsCreator.openVideoPopup(true));
    }
  };

  const handleMouseEnter = () => {
    setIcon('/icons/generateVideoHovered.svg'); // Icon on hover
  };

  const handleMouseLeave = () => {
    setIcon('/icons/GenerateVideo.svg'); // Default icon
  };

  return (
    <Box className={classes.whiteMistAtBottom}>
      <Box className={classes.containerMain}>
        <div className={classes.fabContainer}>
          <Fab
            className={`${classes.customFab} ${
              travelArray.length === 0 ? classes.flashButton : ''
            }`}
            aria-label="add travel"
            onClick={handleAddTravelForm}
          >
            <img src="/icons/AddaTravel.svg" alt="Add a Travel" />
          </Fab>
          <Typography variant="subtitle2" className={classes.customTypography}>
            Add a Travel
          </Typography>
        </div>

        <div className={classes.fabContainer}>
          <Fab
            className={classes.customFab}
            aria-label="travel itinerary"
            onClick={handleOpenTravelItinerary}
          >
            <img src="/icons/TravelItinerary.svg" alt="Travel Itinerary" />
          </Fab>
          <Typography variant="subtitle2" className={classes.customTypography}>
            Travel Itinerary
          </Typography>
        </div>

        <div className={classes.fabContainer}>
          <Fab
            className={classes.customFab}
            onClick={handleOpenMapTypes}
            aria-label="change map"
          >
            <img src="/icons/ChangeMap.svg" alt="Change Map" />
          </Fab>
          <Typography variant="subtitle2" className={classes.customTypography}>
            Change Map
          </Typography>
        </div>

        <div className={classes.fabContainer}>
          <Fab
            className={classes.diffColorCustomFab}
            aria-label="video"
            onClick={handleOpenVideoPopup}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={icon} alt="Generate Animation" />
          </Fab>
          <Typography variant="subtitle2" className={classes.customTypography}>
            Generate Animation
          </Typography>
        </div>
        <Dialog
          open={openDialog}
          TransitionComponent={Slide}
          onClose={handleCloseDialog}
          PaperProps={{ style: { borderRadius: 14 } }}
        >
          <div className={classes.dialogDiv}>
            <Typography className={classes.dialogHeading}>
              Action Required
            </Typography>
            <Typography className={classes.text} sx={{ textAlign: 'center' }}>
              Please add a travel itinerary first!
            </Typography>
            <Button
              className={classes.continue}
              onClick={handleCloseDialog}
              color="primary"
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </Dialog>
      </Box>
    </Box>
  );
}
