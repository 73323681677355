import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Analytics } from '@vercel/analytics/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import MuiTheme from '~styles/theme/MuiTheme';
import '~/index.css';
import App from '~/App';
import { store } from '~redux/store';

import { SpeedInsights } from '@vercel/speed-insights/react';

const container: HTMLElement | null = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={MuiTheme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <App />
        <Analytics />
        <SpeedInsights />
      </StyledEngineProvider>
    </ThemeProvider>
  </Provider>
);
