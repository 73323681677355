import React, { RefObject, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import OutsideClickHandler from 'react-outside-click-handler';
import { Options, FormData } from '~/utility/models';
import useStyles from './styles';
import { outlinedInputStylesSX } from '~/containers/FormContainer/constants';
/**
 * Represents the props for the AutocompleteComponent component.
 * @interface AutocompleteComponentProps
 * @property {object[]} options - The list of options for the Autocomplete component.
 * @property {FormData} formData - The form data associated with the Autocomplete component.
 * @property {Function} handleChangeAirport - Function to handle the change when selecting an airport.
 * @property {Function} handleInputChange - Function to handle the change of input value in the Autocomplete component.
 * @property {boolean} isOpen - State representing whether the Autocomplete dropdown is open.
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setIsOpen - State setter for controlling the open state of the Autocomplete dropdown.
 * @property {FormData} oppositeformData - The form data of the opposite section (e.g., if current is Arrival Form, this data is for Departure Form).
 * @property {React.MutableRefObject<boolean>} autocompleteSelected - Ref object to track if an autocomplete option is selected.
 * @property {string} inputFieldSize - used for the dynamic size of the input field
 * @property {string} type - State representing which type of section is this (Arrival/Departure).

 */
interface AutocompleteComponentProps {
  options: object[];
  formData: FormData;
  handleChangeAirport: (
    event: React.SyntheticEvent<Element, Event>,
    data: Options,
  ) => void;
  handleInputChange: (
    event: React.ChangeEvent<{}>,
    newInputValue: string,
  ) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  oppositeformData: FormData;
  autocompleteSelected: boolean;
  inputFieldSize: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  currentDebounceValueRef: RefObject<number | null>;
}

/**
 * Represents an Autocomplete component for selecting airport locations.
 * @param {AutocompleteComponentProps} props - The props object.
 * @returns {JSX.Element} AutocompleteComponent.
 */
const AutocompleteComponent: React.FC<AutocompleteComponentProps> = ({
  options,
  formData,
  handleChangeAirport,
  setInputValue,
  handleInputChange,
  isOpen,
  setIsOpen,
  oppositeformData,
  autocompleteSelected,
  inputFieldSize,
  type,
  currentDebounceValueRef,
}) => {
  const classes = useStyles();
  const [editAirport, setEditAirport] = useState(false);
  const isMobile = window.innerWidth <= 600;

  /**
   * Handles the change event when an option is selected in the Autocomplete component.
   * @param {React.SyntheticEvent<Element, Event>} event - The event object.
   * @param {Options | null} newValue - The selected option value.
   * @returns {void}
   */
  const handleSelectionChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: Options | null,
  ): void => {
    if (newValue) {
      handleChangeAirport(event, newValue);
      setIsOpen(false);
      setEditAirport(false);
      autocompleteSelected = true;
    }
  };

  /**
   * Handles the change event when the input value of the Autocomplete component changes.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event object representing the change event.
   * @param {string} newInputValue - The new input value.
   * @returns {void}
   */
  const handleInputValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newInputValue: string,
  ): void => {
    if (newInputValue !== formData?.location?.value) {
      handleInputChange(event, newInputValue);
      setIsOpen(newInputValue !== '');
      setEditAirport(true);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <Autocomplete
        disablePortal
        id={`${type}-place`}
        freeSolo // This allows the Autocomplete component to accept values that are not present in the list of options.
        options={options} // Autocomplete component options list
        className={`${classes.hideDropdownArrow} ${classes.dropDownOutlineInput} ${classes.selectBackground}`}
        autoComplete
        size={inputFieldSize as 'medium' | 'small'}
        disabled={
          type === 'arrival' && isMobile && !oppositeformData.location?.text
        }
        filterOptions={(x) => x}
        includeInputInList
        filterSelectedOptions
        value={(formData.location?.text as any) || ''} // we just have a string and the value prop takes an object we are not creating actually.
        fullWidth
        style={{
          opacity:
            type === 'arrival' && isMobile && !oppositeformData.location?.text
              ? 0.2
              : 1,
          transition: 'opacity 0.5s ease-in-out',
        }}
        open={
          isOpen &&
          options.length > 0 &&
          (formData.location === null || editAirport)
        }
        onChange={handleSelectionChange}
        onInputChange={handleInputValueChange as any} //This is important because the prop OninputChange takes a param "reason" that we dont really need in our case so we arent creating a handler according to the exact prop type.
        renderOption={(props, option: Options) => (
          <li {...props} key={option.placeId}>
            <div
              style={{
                marginLeft: '10px',
              }}
            >
              <div
                style={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
              >
                {option.text}
              </div>
              <div
                style={{
                  fontSize: '.8rem',
                  color: '#555',
                  textTransform: 'capitalize',
                }}
              >
                {option.street}
              </div>
              {/* <div
                style={{
                  fontSize: '.75rem',
                  color: '#555',
                  textTransform: 'capitalize',
                }}
              >
                {option.city ? option.city : option.code}, {option.country}
              </div> */}
            </div>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            value={formData.location?.text}
            // placeholder={
            //   type === 'departure'
            //     ? 'Enter a point of departure (e.g. JFK Airport)'
            //     : 'Enter a point of arrival (e.g. DXB Airport)'
            // }
            placeholder={
              type === 'departure'
                ? 'Enter Departure Point Name'
                : 'Enter Arrival Point Name'
            }
            fullWidth
            sx={outlinedInputStylesSX}
            InputProps={{
              ...params.InputProps,
              className: classes.subHeading,
              style: {
                height: 40,
                border: '1.5px solid #ECECED',
              },
              endAdornment: <React.Fragment></React.Fragment>,
            }}
            onClick={() => {
              setEditAirport(true);
              setIsOpen(true);
              if (formData.location) {
                setInputValue(formData.location?.text);
                if (currentDebounceValueRef.current !== null) {
                  (currentDebounceValueRef.current as number) = 0;
                }
              }
            }}
          />
        )}
      />
    </OutsideClickHandler>
  );
};

export default AutocompleteComponent;
