import React, { useEffect, useState } from 'react';
import { signal, effect } from '@preact/signals-core';
import useStyles from './styles';
import {
  animationIsPlaying,
  isCalendarAnimatingPromise,
  PauseAnimation,
  PlayAnimation,
} from '../ViewTravel/common';

interface ButtonProps {
  playPauseState: boolean; // Assume playPauseState is passed as a prop
  bottom?: number; // Bottom position in pixels
  left?: number | string; // Left position in pixels
  type?: string;
}

const PlayPauseButton: React.FC<ButtonProps> = ({
  playPauseState,
  bottom,
  left,
  type,
}) => {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(animationIsPlaying.value); // Initialize state from signal

  const handlePlayPause = () => {
    if (isPlaying) {
      PauseAnimation();
    } else {
      PlayAnimation();
      PlayAnimation();
    }
  };

  // Effect to update the component state when the signal changes
  useEffect(() => {
    const unsubscribe = effect(() => {
      setIsPlaying(animationIsPlaying.value); // Update state when signal changes
    });
    return () => unsubscribe(); // Cleanup effect subscription
  }, []);

  useEffect(() => {
    const handleBlur = () => {
      PauseAnimation();
    };
    const handleFocus = () => {
      // You can add logic for focus here if needed
    };
    const handleVisibilityChange = async () => {
      await isCalendarAnimatingPromise();
      if (document.visibilityState === 'hidden') {
        PauseAnimation();
        setIsPlaying(false);
      }
    };

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  console.log(isPlaying, 'WHY THIS');

  return (
    <button
      className={`${classes.button} ${
        type === 'desktop' ? classes.desktopButton : classes.mobileButton
      }`}
      style={{ bottom: `${bottom}px`, left: left }}
      onClick={handlePlayPause}
    >
      <img
        src={isPlaying ? './icons/pause.svg' : './icons/play.svg'}
        alt={isPlaying ? 'Pause Animation' : 'Play Animation'}
        height={type === 'desktop' ? 36 : 14}
        width={type === 'desktop' ? 28 : 11}
      />
    </button>
  );
};

export default PlayPauseButton;
