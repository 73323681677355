import { AnimationState } from '~/utility/models';
import * as THREE from 'three';
import { CharacterStateMachine } from '../CharacterStateMachine';
import { AnimationStates } from '~/animationEngine/utility/enums/AnimationStates';

export class IdleAnimation implements AnimationState {
  private action: THREE.AnimationAction;
  private stateMachine: CharacterStateMachine;

  constructor(
    private mixer: THREE.AnimationMixer,
    clip: THREE.AnimationClip,
    stateMachine: CharacterStateMachine,
  ) {
    this.action = this.mixer.clipAction(clip);
    this.stateMachine = stateMachine;

    this.onFinished = this.onFinished.bind(this);
  }

  onEnter(): void {
    console.log('Entering idle state');
    this.action
      .reset()
      .setLoop(THREE.LoopOnce, 1)
      .setEffectiveWeight(2)
      .fadeIn(0.5)
      .play();
    this.action.getMixer().addEventListener('finished', this.onFinished);
  }

  onExit(): void {
    console.log('Exiting idle state');
    this.action.setEffectiveWeight(1).stop();
    this.action.getMixer().removeEventListener('finished', this.onFinished);
  }

  onUpdate(delta: number): void {
    this.mixer.update(delta);
  }

  private onFinished(event: any): void {
    if (event.action === this.action) {
      this.stateMachine.setState(AnimationStates.Disappear);
    }
  }
}
