import { MusicButton } from '../ActiveMusicButton';
import { MusicPlaying } from '../DesktopFooter/MusicButtonDesktop';
import './style.css';

export default () => {
  return (
    <div className="musicPlayerIcon">
      <MusicButton width={25} height={25} />
      <MusicPlaying />
    </div>
  );
};
// const MusicButton = () => (<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path fillRule="evenodd" clipRule="evenodd" d="M13.107 4.78082V2.5351C13.1082 2.4518 13.0911 2.36926 13.0567 2.29337C13.0224 2.21748 12.9717 2.15011 12.9083 2.09606C12.8447 2.04217 12.77 2.00312 12.6895 1.98176C12.609 1.96039 12.5247 1.95725 12.4428 1.97255L5.72534 3.09148C5.59068 3.11185 5.46798 3.18038 5.38001 3.28435C5.29205 3.38832 5.24479 3.52067 5.247 3.65684V9.00108C4.81887 8.7539 4.32114 8.6549 3.83101 8.71943C3.34087 8.78396 2.88573 9.00841 2.53617 9.35798C2.1866 9.70755 1.96215 10.1627 1.89763 10.6528C1.83311 11.143 1.93212 11.6407 2.1793 12.0688C2.42649 12.4969 2.80804 12.8315 3.26477 13.0207C3.72151 13.2099 4.2279 13.2431 4.70541 13.1151C5.18293 12.9872 5.60488 12.7052 5.90582 12.313C6.20676 11.9208 6.36987 11.4402 6.36986 10.9459V6.36798L11.9841 5.43264V7.87766C11.556 7.63049 11.0583 7.53149 10.5682 7.596C10.0781 7.66051 9.62299 7.88493 9.27342 8.23446C8.92385 8.58399 8.69938 9.0391 8.63481 9.5292C8.57025 10.0193 8.6692 10.517 8.91632 10.9452C9.16344 11.3733 9.54492 11.7079 10.0016 11.8972C10.4583 12.0864 10.9646 12.1197 11.4422 11.9918C11.9197 11.8639 12.3417 11.5821 12.6427 11.19C12.9437 10.7978 13.1069 10.3174 13.107 9.82302V4.78082Z" fill="white" />
// </svg>
// )

// const MusicPlaying = () => (<svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M1.46484 4.31137L1.46484 10.8434" stroke="white" strokeWidth="2.3" strokeLinecap="round" />
//     <path d="M5.59253 1.86581L5.59253 13.2888" stroke="white" strokeWidth="2.3" strokeLinecap="round" />
//     <path d="M9.71997 4.31137L9.71997 10.8434" stroke="white" strokeWidth="2.3" strokeLinecap="round" />
// </svg>
// )
