import React from 'react';
import { Modal, Drawer, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import ModifyTravelForm from '~/components/ModifyTravelForm';
import ActionsCreator from '~redux/actions';
import { useDispatch } from '~redux/store';
import useStyles from './styles';
import { RootState } from '~/redux/reducers';

/**
 * ModifyTravelFormContainer component - Container for the Modify Travel Form.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component
 */
const ModifyTravelFormContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const modifyFormName = 'Modify a Travel';
  const modifyDepartureAlertText =
    'Departure point locked in as previous arrival point';
  const modifyArrivalAlertText =
    'Arrival point locked in as next departure point';

  // Get the state of the modify travel form and travel points array from the store
  const openModifyTravelForm = useSelector(
    (state: RootState) => state.MapReducers.modifyTravelFormState,
  );

  const travelPoints = useSelector(
    (state: RootState) => state.MapReducers.pointsArray,
  );

  /**
   * handleClose - Closes the modify travel form and sets the save state if there are travel points.
   */
  const handleClose = () => {
    dispatch(ActionsCreator.setSnackbarOpen(false));
    dispatch(ActionsCreator.openModifyTravelForm(false));
    if (travelPoints.length > 0) {
      dispatch(ActionsCreator.setTravelFormSaveState(true));
    }
  };

  // Determine if the screen size is small
  const isSmScreen = useMediaQuery('(max-width:600px)');
  const PopupComponent = isSmScreen ? Drawer : Modal;

  return (
    <div>
      <PopupComponent
        {...(isSmScreen
          ? {
              anchor: 'bottom',
              open: openModifyTravelForm,
              onClose: handleClose,
              PaperProps: { style: { borderRadius: '25px 25px 0 0' } },
              ModalProps: { keepMounted: false },
            }
          : {
              open: openModifyTravelForm,
              onClose: handleClose,
              'aria-labelledby': 'modal-modal-title',
              'aria-describedby': 'modal-modal-description',
            })}
      >
        <Box className={isSmScreen ? classes.smTravelForm : classes.travelForm}>
          <ModifyTravelForm
            handleClose={handleClose}
            formName={modifyFormName}
            departureAlertText={modifyDepartureAlertText}
            arrivalAlertText={modifyArrivalAlertText}
            state={true}
          />
        </Box>
      </PopupComponent>
    </div>
  );
};

export default ModifyTravelFormContainer;
