

export default ({ time, from }: { time: string, from: string }) => {



    return (
        <div className='d-flex justify-content-center align-items-center w-full' style={{ fontSize: "1.95vw", fontWeight: 400, whiteSpace: 'nowrap' }}>
            <b className="me-1"> Depart </b> at <b className='mx-1'> {time} </b> from <b className='mx-1' >{from}</b>
        </div>
    )
}