import { ModelConfigProps } from '~/utility/models';

/**
 * An enumeration of various car models.
 * Each member represents a specific car type with a corresponding numeric value for potential use in calculations or comparisons.
 */
export enum FerryModels {
  /** Represents a standard car type. */
  Ferry = 'Ferry',
}

export const ferryModelsConfiguration = new Map<FerryModels, ModelConfigProps>([
  [
    FerryModels.Ferry,
    {
      name: 'ferry.glb',
      path: './models/ferry.glb',
      UIname: 'Ferry',
    },
  ],
]);
