import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Slide,
  Snackbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useStyles from './styles';
import { getAllFilesInFolder, IAudioFile } from '~/utility/utils';
import { TransitionProps } from '@mui/material/transitions';
import { store } from '~/redux/store';
import { addLogoSignal } from '~/components/signals/logoSignals';
import { useSignals } from '@preact/signals-react/runtime';
import LogoLibrary from './LogoLibrary';

interface AddLogoModalProps {
  open: boolean;
  handleClose: () => void;
  loading: boolean;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveLogo: () => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  isErrorOpen: boolean;
  errorMessage: string;
  setIsErrorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddLogoModal: React.FC<AddLogoModalProps> = ({
  open,
  handleClose,
  loading,
  handleDragOver,
  handleDrop,
  handleFileChange,
  isErrorOpen,
  errorMessage,
  setIsErrorOpen,
  handleRemoveLogo,
}) => {
  const classes = useStyles();

  useSignals();
  const [currentLogo, setCurrentLogo] = useState<string | null>(null); // Track currently playing audio id

  const [filesInFolder, setFilesInFolder] = useState<IAudioFile[] | undefined>(
    [],
  ); // State to hold files fetched from storage  const [existingFileLoader, setExistingFileLoader] = useState<boolean>(false);
  const [existingFileLoader, setExistingFileLoader] = useState<boolean>(false);

  const [displayedFiles, setDisplayedFiles] = useState<
    IAudioFile[] | undefined
  >([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    /**
     * Asynchronously fetches a list of logos files from a specified folder in storage.
     * This function is triggered when the modal is opened. It fetches the list of files, handles
     * potential errors, and updates the state based on the results. The function also manages the
     * loading state and error display.
     *
     * The function performs the following steps:
     * 1. Checks if the modal is open.
     * 2. Retrieves the user ID from the Redux store.
     * 3. Sets the loading state to `true` while fetching files.
     * 4. Calls `getAllFilesInFolder` with the folder name and user ID to fetch files.
     * 5. If the fetch is successful, updates the state with the retrieved files.
     * 6. If the fetch fails, sets an error state to show an alert and logs the error to the console.
     * 7. Ensures that the loading state is set to `false` after the fetch operation completes, regardless of success or failure.
     *
     * @async
     * @function fetchFiles
     *
     * @throws Will set the error state and log an error if fetching files fails.
     *
     * @returns {Promise<void>} A promise that resolves when the file fetching operation is complete.
     *
     * @example
     * // Example usage within a component
     * useEffect(() => {
     *   fetchFiles();
     * }, [open]);
     */
    const fetchFiles = async () => {
      if (open) {
        const userId = store.getState().MapReducers.userID;
        setExistingFileLoader(true);
        try {
          // Fetch files from storage when modal opens
          const { success, files, error } = await getAllFilesInFolder(
            'user-logo',
            userId,
          );

          if (success) {
            setFilesInFolder(files);
          } else {
            setIsErrorOpen(true); // Show error alert if fetching files fails
            console.error('Error fetching files:', error);
          }
        } catch (error) {
          setIsErrorOpen(true); // Show error alert if fetching files fails
        } finally {
          setExistingFileLoader(false);
        }
      }
    };

    fetchFiles();
  }, [open, setIsErrorOpen]);

  useEffect(() => {
    if (filesInFolder && filesInFolder?.length > 0) {
      // Initially display only the first 4 items
      setDisplayedFiles(filesInFolder.slice(0, 8));
    }
  }, [filesInFolder]);

  /**
   * Handles the "See More" button click event to display all available files.
   *
   * When the "See More" button is clicked, this function updates the state to show
   * all items in the `filesInFolder` array and sets a flag to indicate that all items are being displayed.
   *
   * The function performs the following steps:
   * 1. Updates the state `displayedFiles` to include all files from `filesInFolder`.
   * 2. Sets the state `showAll` to `true`, indicating that all items should be shown.
   *
   * This function is typically used to reveal additional items in a list or gallery when a user clicks a "See More" button.
   *
   * @function handleSeeMore
   *
   * @returns {void}
   *
   * @example
   * // Example usage in a component
   * return (
   *   <div>
   *     {displayedFiles.map(file => (
   *       <FileItem key={file.id} file={file} />
   *     ))}
   *     {!showAll && filesInFolder.length > 4 && (
   *       <button onClick={handleSeeMore}>See More</button>
   *     )}
   *   </div>
   * );
   */
  const handleSeeMore = () => {
    // Show all items when "See More" button is clicked
    setDisplayedFiles(filesInFolder!);
    setShowAll(true);
  };

  /**
   * Closes the modal, stops audio playback, and hides the audio element.
   *
   * This function is called when the modal is closed. It resets the audio element reference,
   * hides the audio element, stops playback, and then calls `handleClose` to close the modal.
   *
   * It performs the following actions:
   * - Sets the `audioRef.current` to `null` to clear the reference to the audio element.
   * - Sets `audioPlaying` to `false` to stop any audio playback.
   * - Calls `handleClose` to perform any additional cleanup required to close the modal.
   *
   * @returns {void}
   *
   * @example
   * // Example usage in a modal's onClose event
   * <Modal onClose={onClose}>
   *  Modal content here *
   * </Modal>
   */
  const onClose = () => {
    handleClose();
  };

  const chooseFromLibrary = ({ url }: { url: string }) => {
    addLogoSignal.value = url;
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      aria-labelledby="add-logo-dialog"
    >
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={() => setIsErrorOpen(false)} // Close the Snackbar on action
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Position the Snackbar at bottom left
        TransitionComponent={Slide} // Use the Slide component for the transition
        TransitionProps={{ direction: 'right' } as TransitionProps} // Slide from right to left
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setIsErrorOpen(false)}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <div style={{ padding: '1rem' }}>
        <DialogTitle id="add-logo-title" className={classes.dialogTitle}>
          <img
            aria-label="close"
            src="/icons/delete.svg"
            alt="cancel"
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: '18px',
              marginBottom: 10,
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          style={{
            padding: 0,
            margin: 0,
            cursor: loading ? 'disabled' : 'auto',
          }}
        >
          <Box
            className={classes.fileContainer}
            onDragOver={loading ? undefined : handleDragOver}
            onDrop={loading ? undefined : handleDrop}
          >
            <Box className={classes.dragAndDropParent}>
              <div
                style={{
                  position: 'relative',
                }}
              >
                {/* NOT DISPLAYED */}
                {!addLogoSignal.value && (
                  <img
                    alt="Logo icon"
                    src="/icons/logo-image.svg"
                    style={{
                      position: 'relative',
                      height: isMobile ? '46px' : 'auto',
                      width: isMobile ? '46px' : 'auto',
                    }}
                  />
                )}
              </div>
              <div>
                {addLogoSignal.value ? (
                  <>
                    <Box className={classes.logoContainer}>
                      <img
                        style={{
                          borderRadius: '.2rem',
                          height: isMobile ? '100px' : '130px',
                          width: isMobile ? '100px' : '160px',
                          objectFit: 'contain',
                        }}
                        alt="Logo"
                        src={addLogoSignal.value}
                      />
                      <img
                        alt="Delete icon"
                        src="/icons/delete.svg"
                        className={classes.deleteIcon}
                        onClick={handleRemoveLogo}
                      />
                    </Box>
                  </>
                ) : (
                  <Box>
                    <Typography className={classes.fileSelectionText}>
                      Choose a file or drag & drop it here
                    </Typography>
                    <Typography className={classes.fileSelectionSubtext}>
                      Transparent PNG formats, up to 50MB
                    </Typography>
                  </Box>
                )}
              </div>
              {!addLogoSignal.value && (
                <label
                  htmlFor="logo-file"
                  className={classes.uploadButton}
                  style={{
                    cursor: loading ? 'disabled' : 'auto',
                  }}
                >
                  <input
                    className={classes.fileInput}
                    disabled={loading}
                    style={{ display: 'none' }}
                    id="logo-file"
                    type="file"
                    accept=".PNG"
                    onChange={handleFileChange}
                  />
                  {loading ? 'Uploading...' : ' Upload'}
                </label>
              )}
            </Box>
          </Box>

          {addLogoSignal.value && (
            <Box
              sx={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  // fontFamily: 'Futura Bold',
                  textTransform: 'capitalize',
                  width: {
                    xs: '100px',
                    lg: '150px',
                  },
                }}
                onClick={onClose}
              >
                Done
              </Button>
            </Box>
          )}

          <Box className={classes.libraryContainer}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginTop: '2rem',
              }}
              className={classes.heading}
            >
              Add from library
            </Typography>
            <div className={classes.libraryWrapper}>
              {existingFileLoader ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                <div className={classes.libraryWrapper}>
                  {displayedFiles && displayedFiles?.length > 0 ? (
                    displayedFiles.map((file) => (
                      <LogoLibrary
                        key={file.id}
                        file={file}
                        chooseFromLibrary={chooseFromLibrary}
                      />
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography
                        style={{
                          marginTop: '1rem',
                          color: '#000',
                          fontWeight: 400,
                          cursor: 'pointer',
                        }}
                      >
                        Logos you add will be shown here.
                      </Typography>
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    {!showAll && filesInFolder && filesInFolder.length > 8 && (
                      <Typography
                        onClick={handleSeeMore}
                        style={{
                          marginTop: '1rem',
                          color: '#ED6934',
                          fontWeight: 400,
                          cursor: 'pointer',
                        }}
                      >
                        See More
                      </Typography>
                    )}
                  </Box>
                </div>
              )}
            </div>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  );
};
