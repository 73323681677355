import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import useStyles from './styles';

/**
 * OrientationChangePopUp component for displaying warning to leave landscape mode on mobile.
 * @param {Object} props - The component props.
 * @param {Boolean} props.isOpem - The flag to display ths component
 * @returns {JSX.Element} The rendered OrientationChangePopUp component.
 */
const OrientationChangePopUp = ({
  isOpen,
}: {
  isOpen: boolean;
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} classes={{ paper: classes.paper }} fullScreen={true}>
      <Box className={classes.container}>
        <DialogTitle className={classes.headingContainer}>
          <Typography
            sx={{
              fontFamily: 'Futura Hv BT',
              fontSize: ' 22px',
              width: '100%',
            }}
          >
            Switch to portrait mode for an optimal experience! 📱
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: 'Futura Medium' }}>
            Portrait view ensures that you get the most out of our features with
            better layout and usability. Experience the difference—just rotate
            your device!{' '}
          </Typography>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default OrientationChangePopUp;
