import { CSSObject } from '@mui/material/styles';

// Define the keyframes for oscillation
export const keyframes: string = `
@keyframes oscillate {
  0% { transform: scaleY(1); }
  50% { transform: scaleY(1.5); }
  100% { transform: scaleY(1); }
}
`;

// Define container styles
export const containerStyles: CSSObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '.2rem', // Space between lines
};

// Function to return line styles based on height and animation duration
export const lineStyles = (
  height: string,
  duration: string,
  width = 5,
): CSSObject => ({
  width: width + 'px',
  height: height,
  backgroundColor: '#000', // Change color if needed
  animation: `oscillate ${duration} ease-in-out infinite`,
  // make lines look more like "bars"
  borderRadius: '2px',
});
