import { getActionBySelectedTransport } from "../common"

export default ({ time, type }: { time: string, type: string }) => {

    return (
        <div className='d-flex justify-content-center align-items-center w-full' style={{ fontSize: "1.95vw", fontWeight: 400, whiteSpace: 'nowrap' }}>
            <>
                {
                    getActionBySelectedTransport(type)
                }
            </> for <b className="ms-1">{time}</b>
        </div>
    )
}