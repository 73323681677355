// helper.ts
import { Position } from '@turf/turf';
import { supabase } from '~/supabase/supabaseClient';
import { TravelFormData } from '~/utility/models';
import { lookupTimezone } from '~/utility/utils';

const TABLE_NAME = 'Travel History';

/**
 * TODO-RF: In future, we can fetch timezone from Supabase instead of using tzlookup
 * This function adds timezone to each travel point in travelPoints array
 * @param {TravelFormData[]} travelPoints - Travel History Item which includes id, uuid and travelPoints
 * @returns Returns an updated array of travel points with timezone in each object
 */
const addTimezoneToTravelPoints = (travelPoints: TravelFormData[]) => {
  return travelPoints.map((point) => {
    const arrival = point.arrival || {};
    const departure = point.departure || {};

    // Add timezone property to arrival
    if (arrival.location) {
      const coordinates = arrival.location.coordinates as Position;
      arrival.timezone = lookupTimezone(coordinates[1], coordinates[0]);
    }

    // Add timezone property to departure
    if (departure.location) {
      const coordinates = departure.location.coordinates as Position;
      departure.timezone = lookupTimezone(coordinates[1], coordinates[0]);
    }

    return {
      ...point,
      arrival,
      departure,
    };
  });
};

/**
 * This function adds a travel to Travel History of a User in DB based on email provided
 * @param {TravelFormData[]} travelArray - Array of TravelFormData objects
 * @param {string} email - Email of the user adding Travel (Logged In User)
 * @returns Returns response from Database query
 */
export async function addtoTravelHistory(
  travelArray: TravelFormData[],
  email: string,
) {
  try {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .upsert({
        travelPoints: travelArray,
        email: email,
      })
      .select();

    if (error) {
      console.error('Error upserting data:', error.message);
      return { error };
    } else {
      return { data };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function fetchs data of Travel History of a user based on provided userId
 * @param {string} userId - ID of the user logged in
 * @returns Returns array of TravelPoints
 */
export async function getTravelHistory(userId: string) {
  try {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select()
      .eq('UUID', userId)
      .order('id', { ascending: true });

    const newData = [];
    if (data) {
      for (let i = 0; i < data?.length; i++) {
        const item = data[i];

        // TODO-RF: Remove updatedItem because timezone is present in Supabase
        const updatedItem = {
          ...item,
          travelPoints: addTimezoneToTravelPoints(item.travelPoints),
        };
        newData.push(updatedItem);
      }
    }

    if (error) {
      console.error('Error fetching data:', error.message);
      return { error };
    } else {
      return { data };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function deletes a travel of a user from Travel History Table in Database
 * @param {string} userId - ID of the user
 * @param {string} id - ID of travel to be deleted
 * @returns Returns updated array of TravelPoints after deletion
 */
export async function deleteTravelHistory(userId: string, id: number) {
  try {
    const { error } = await supabase
      .from(TABLE_NAME)
      .delete()
      .eq('UUID', userId)
      .eq('id', id);

    if (error) {
      console.error('Error deleting data:', error.message);
      return { error };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function updates a travel of a user in Travel History Table in Database
 * @param {TravelFormData[]} travelArray - Array of Updated Travels
 * @param {number} recordId - ID of travel to be updated
 * @returns Returns updated array of TravelPoints
 */
export async function updateTravelHistoryById(
  travelArray: TravelFormData[],
  recordId: number,
) {
  try {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .update({ travelPoints: travelArray })
      .eq('id', recordId)
      .select();

    if (error) {
      console.error('Error updating data:', error.message);
      return { error };
    } else {
      return { data };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}
