import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Model, ModelConfigProps, ModelKeys } from '~/utility/models';
import { carModelsConfiguration } from './utility/enums/CarModels';
import { markerModelsConfiguration } from './utility/enums/MarkerModels';
import { planeModelsConfiguration } from './utility/enums/PlaneModels';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import {
  WalkModels,
  walkModelsConfiguration,
} from './utility/enums/WalkModels';
import { transitModelsConfiguration } from './utility/enums/TransitModels';
import { ferryModelsConfiguration } from './utility/enums/FerryModels';

/**
 * Interface defining the properties of a model configuration entry.
 */
interface LoadedModelConfigProps {
  name: string;
  path: string;
  UIname: string;
  loadedModel?: Model | null; // New property for storing loaded model
}

/**
 * Class responsible for loading and managing 3D models.
 * Implements a singleton pattern to ensure only one instance is created.
 */
class ResourceLoader {
  /**
   * Private static member that holds the single instance of the ResourceLoader.
   */
  private static instance: ResourceLoader | null = null;

  /**
   * Map that stores model configurations keyed by their names.
   */
  private modelConfigMap: Map<ModelKeys | ModelKeys[], LoadedModelConfigProps>;

  /**
   * Private constructor to prevent direct instantiation.
   * Initializes the model configuration map.
   */
  private constructor() {
    this.modelConfigMap = new Map<ModelKeys, ModelConfigProps>();
    this.loadModelConfigurations();
  }

  /**
   * Gets the singleton instance of ResourceLoader.
   * @returns {ResourceLoader} The singleton instance of ResourceLoader.
   */
  public static getInstance(): ResourceLoader {
    if (!this.instance) {
      this.instance = new ResourceLoader();
    }
    return this.instance;
  }

  /**
   * Loads model configurations from the provided model configuration object.
   * Stores the configurations in a map for easy access.
   */
  private loadModelConfigurations() {
    // Combine all configurations into one object
    const allModelConfigurations: [ModelKeys, ModelConfigProps][] = [
      ...Array.from(carModelsConfiguration.entries()),
      ...Array.from(markerModelsConfiguration.entries()),
      ...Array.from(planeModelsConfiguration.entries()),
      ...Array.from(walkModelsConfiguration.entries()),
      ...Array.from(transitModelsConfiguration.entries()),
      ...Array.from(ferryModelsConfiguration.entries()),
    ];

    // Iterate over each configuration and add it to the map
    for (const [key, value] of allModelConfigurations) {
      const modelInfo = {
        ...value,
        loadedModel: null,
      };
      this.modelConfigMap.set(key, modelInfo);
    }
  }

  /**
   * Loads a 3D model based on the provided model enum key.
   * If the model is already loaded, it clones the stored model.
   * Otherwise, it loads the model from the specified path and stores it.
   * @param modelEnum The key of the model to load from the configuration.
   * @param onLoad Callback function to execute when the model is successfully loaded.
   * @param onProgress Optional callback function to execute during the loading process.
   * @param onError Optional callback function to execute if an error occurs during loading.
   */
  public async loadModel(
    modelEnum: ModelKeys,
    onLoad: (model: Model) => void,
    onProgress?: (event: ProgressEvent) => void,
    onError?: (event: ErrorEvent) => void,
  ) {
    const loader = new GLTFLoader();
    const parentGroup = new THREE.Group();

    const modelInfo = this.modelConfigMap.get(modelEnum);

    if (modelInfo) {
      if (modelInfo.loadedModel) {
        // If the model is already loaded, use the stored model
        let clonedModel;
        if (Object.values(WalkModels).includes(modelEnum as WalkModels)) {
          clonedModel = SkeletonUtils.clone(modelInfo.loadedModel.scene);
        } else {
          clonedModel = modelInfo.loadedModel.scene.clone();
        }

        const model = {
          ...modelInfo.loadedModel,
          scene: parentGroup.add(clonedModel),
        };

        if (onLoad) {
          onLoad(model);
        }
      } else {
        // If the model is not loaded, load it and store the loaded model
        const modelPath = modelInfo.path;

        loader.load(
          modelPath,
          (gltf) => {
            const { scene, animations } = gltf;
            modelInfo.loadedModel = {
              animations,
              scene,
            };

            let clonedModel;
            if (Object.values(WalkModels).includes(modelEnum as WalkModels)) {
              clonedModel = SkeletonUtils.clone(scene);
            } else {
              clonedModel = scene.clone();
            }

            const model = {
              animations,
              scene: parentGroup.add(clonedModel),
            } as Model;

            if (onLoad) {
              onLoad(model);
            }
          },
          onProgress,
          (error) => {
            if (onError) {
              onError(error);
            }
          },
        );
      }
    }
  }
}

export default ResourceLoader;
