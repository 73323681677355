import { Map } from 'maplibre-gl';
import { CarAnimationController } from '../CarAnimationController';
import {
  Config,
  MultiTransportStates,
  MultiTransportAnimationController,
} from '../MultiTransportAnimationController';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { LandTransportAnimationConfig } from '../AnimationController';

export class CarState implements MultiTransportStates {
  private controller: CarAnimationController;

  constructor(
    private map: Map,
    private index: number,
    private tb: CustomThreeJSWrapper,
    private stateMachine: MultiTransportAnimationController,
    private config: Config,
  ) {
    this.controller = new CarAnimationController(this.map, this.index, this.tb);
    this.controller.setup(this.config, this.index, false);
  }

  onEnter(): void {
    this.startAnimation();
  }

  onDestroy() {
    if (this.controller) {
      this.controller.destroy();
    }
  }

  onCleanup() {
    if (this.controller) {
      this.controller.cleanup();
    }
  }

  setLineLayerAndSources() {
    this.controller.setLineLayerAndSources();
  }

  onExit(): void {
    // Cleanup or transition logic here
    // if (this.controller) {
    // this.controller.cleanup();
    // }
  }

  onPause() {
    if (this.controller) {
      this.controller.onPause();
    }
  }

  onPlay() {
    if (this.controller) {
      this.controller.onPlay();
    }
  }

  onUpdate(delta: number): void {
    this.controller.update(delta);
  }

  startAnimation(): void {
    const animationConfig: LandTransportAnimationConfig = {
      duration: this.stateMachine.durationForEachStep, // Replace with actual duration
      onCompleteCallback: () => {
        this.stateMachine.setState(this.stateMachine.transitionState); // Transition to the next state
      },
    };
    this.controller.setupAnimation(animationConfig);
  }
}
