import { TravelAnimation } from '../TravelAnimation';
import { State } from '../models';
import { Map } from 'maplibre-gl';
import { Position } from '@turf/turf';
import { LandTransportAnimationConfig } from '~/animationEngine/AnimationController';
import { getStraightLineBetweenTwoPoints } from '~/animationEngine/utility/utils';
import { StraightLineController } from '~/animationEngine/StraightLineController';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { Config } from '~/animationEngine/MultiTransportAnimationController';
import { Clock } from 'three';
import { MarkerInstances } from '~/utility/models';
import { Marker } from '~/animationEngine/Marker';

export class AnimateStraightLine implements State {
  controller!: StraightLineController;
  private stateMachine: TravelAnimation;
  private clock = new Clock();
  flyToDuration: number = 5000;
  animationStartZoom!: number;
  isPaused: boolean = false;
  timeElapsed: number = 0;
  timeElapsedTillPause: number = 0;
  private markers: MarkerInstances | undefined;

  /**
   * @constructor
   * @param stateMachine - A reference to the TravelAnimation state machine instance.
   */
  constructor(
    stateMachine: TravelAnimation,
    private map: Map,
    private tb: CustomThreeJSWrapper,
  ) {
    this.stateMachine = stateMachine;

    // Bind `this.setState` to the current instance of the component to ensure
    // that it retains the correct context when it is called, especially when
    // passing it as a callback or using it in event handlers.
    this.setState = this.setState.bind(this);
  }

  onEnter() {
    this.clock.start();

    console.log('Entered AnimateStraightLine State');
    let straightLineConfig = this.setupStraightLinePath();

    this.controller = new StraightLineController(
      this.map,
      this.stateMachine.currentSegmentIndex,
      this.tb,
    );

    this.stateMachine.straightLineControllers.push(this.controller);

    this.controller.setup(
      straightLineConfig,
      this.stateMachine.currentSegmentIndex,
      false,
    );

    this.controller.getIsNextStateDestination(
      this.stateMachine.isNextStateDestination,
    );

    this.startAnimation();
  }

  onUpdate() {
    const delta = this.clock.getDelta();
    this.controller.update(delta);

    for (const controller of this.stateMachine.animationControllers) {
      this.markers = controller?.markers;
      for (const markerInstance of Object.values(
        this.markers as MarkerInstances,
      )) {
        (markerInstance as Marker).update(delta);
      }
    }
    this.stateMachine.map.repaint = true;
  }

  onReset() {
    this.flyToDuration = 5000;
  }

  onExit() {
    console.log('Exited AnimateStraightLine State.....');
  }

  setState() {
    this.stateMachine.setState(
      this.stateMachine.isNextStateDestination
        ? this.stateMachine.states.animateDestinationMarker
        : this.stateMachine.states.animateTravelSegment,
    );
  }

  setupStraightLinePath() {
    // Extract StraightLine Data from AnimationTravelData
    let travelSegmentIndex = this.stateMachine.currentSegmentIndex;
    let travelSegment =
      this.stateMachine.animationTravelData[travelSegmentIndex];

    let origin;
    let destination;
    /**
     * Check if starightline is for origin, take origin and line first point
     * If straightline is for destination, take line end point and destination
     */

    if (this.stateMachine.isNextStateDestination) {
      if (travelSegment.decodedPath.path.length > 0) {
        origin = travelSegment.decodedPath.path.slice(-1)[0];
      } else if (travelSegment.decodedPath.data.length > 0) {
        origin = travelSegment.decodedPath.data.slice(-1)[0].path.slice(-1)[0];
      }
      destination = travelSegment.arrival.location?.coordinates as Position;
    } else {
      origin = travelSegment.departure.location?.coordinates as Position;
      if (travelSegment.decodedPath.path.length > 0) {
        destination = travelSegment.decodedPath.path[0];
      } else if (travelSegment.decodedPath.data.length > 0) {
        destination = travelSegment.decodedPath.data[0].path[0];
      }
    }

    // Generate StraightLine Coordinates
    const { coordinates, distance } = getStraightLineBetweenTwoPoints(
      origin as Position,
      destination as Position,
    );

    if (distance < 1) {
      this.flyToDuration = 1000;
    } else {
      this.flyToDuration = 5000;
    }

    const straightLineConfig: Config = {
      decodedPath: {
        path: [],
      },
      travelSegmentConfig: {
        modelsArray: null,
        modelScale: travelSegment.travelSegmentConfig.modelScale,
        animationSpeed: travelSegment.travelSegmentConfig.animationSpeed,
      },
      selectedTransport: travelSegment.selectedTransport,
    };

    straightLineConfig.decodedPath.path = coordinates;
    straightLineConfig.travelSegmentConfig.modelsArray = [];

    return straightLineConfig;
  }

  startAnimation() {
    console.log(this.flyToDuration, 123);

    const animationConfig: LandTransportAnimationConfig = {
      duration: this.flyToDuration, // Replace with actual duration
      onCompleteCallback: () => {
        this.stateMachine.setState(
          this.stateMachine.isNextStateDestination
            ? this.stateMachine.states.animateDestinationMarker
            : this.stateMachine.states.animateTravelSegment,
        );
      },
    };
    this.controller.setupAnimation(animationConfig);
  }

  onPause() {
    this.isPaused = true;

    this.clock.stop();

    if (this.controller) {
      this.controller.onPause();

      // hackish way to cancel all camera animations
      this.stateMachine.map.stop();
    }
  }

  onPlay() {
    this.isPaused = false;

    this.clock.start();

    this.stateMachine.map.repaint = true;
    if (this.controller) {
      this.controller.onPlay();
    }
  }
}
