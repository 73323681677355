import './style.css'

export default ({ line1, line2 }: { line1: string | any, line2?: string | any }) => {

    return (
        <div className='d-flex flex-column' style={{ width: '100%' }}>

            <div className='d-flex'>

                <div className='daydate-mobile'>
                    {line1}
                    <div style={{ maxWidth: '80vw' }}>

                        {line2 &&
                            line2
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

