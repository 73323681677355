import { TravelAnimation } from '../TravelAnimation';
import { State } from '../models';

/**
 * This class represents the "ClearPreviousTravelSegment" state within a travel animation.
 * It's responsible for removing text animations from origin and destination markers
 * associated with the current travel segment and transitioning to the "moveToSegment" state.
 */
export class ClearPreviousTravelSegment implements State {
  private stateMachine: TravelAnimation;

  constructor(stateMachine: TravelAnimation) {
    this.stateMachine = stateMachine;
  }

  /**
   * Called upon entering the "ClearPreviousTravelSegment" state.
   * - Removes text animations from origin and destination markers for the current travel segment.
   * - Transitions the state machine to the "moveToSegment" state.
   */
  onEnter() {
    if (this.stateMachine.devMode) console.log('Entering ClearPreviousTravelSegment state');

    this.stateMachine.getCurrentController()?.cleanup();
    
    if (this.stateMachine.straightLineControllers.length > 0) {
      for (const controller of this.stateMachine.straightLineControllers) {
        controller?.cleanup();
      }
    }

    this.stateMachine.setState(this.stateMachine.states.moveToSegment);
  }

  onUpdate() {
    this.stateMachine.map.repaint = true;
  }

  /**
   * Called upon exiting the "ClearPreviousTravelSegment" state.
   */
  onExit() {
    if (this.stateMachine.devMode) console.log('Exiting ClearPreviousTravelSegment state');
  }
}
