import { ModelConfigProps } from '~/utility/models';
/**
 * An enumeration of various model types, potentially used for 3D objects or map markers.
 * Each member represents a specific model type with a corresponding numeric value for potential use in calculations or comparisons.
 */
export enum MarkerModels {
  /** Represents a map pointer, potentially used for location identification on a map. */
  MapPointer = 'MapPointer',
}

export const markerModelsConfiguration = new Map<
  MarkerModels,
  ModelConfigProps
>([
  [
    MarkerModels.MapPointer,
    {
      name: 'map_pointer.glb',
      path: './map_pointer.glb',
      UIname: 'Map Pointer',
    },
  ],
]);
