import './style.css';
import CalendarView from './CalenderView';
import { TravelPoint } from '../StatsOverlay';
import { memo } from 'react';

const DesktopHeader = ({
  data,
  step,
  currentDate,
  lightBG,
  handleCardSelect,
  departureDates,
  startDate,
  endDate,
}: {
  step: any;
  data: TravelPoint[];
  currentDate: any;
  lightBG: boolean;

  handleCardSelect: (index: number) => void;
  departureDates: Date[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  // if (step?.state == 'idle') return null;

  //stores a local stage for the currentCalendar. Not a state exactly, but a step, that can be used to change the carousel

  return (
    <div className={`${lightBG ? 'desktop-header-light' : 'desktop-header'}`}>
      {step?.state === 'idle' ? null : (
        <CalendarView
          step={step}
          data={data}
          currentCalendarDate={currentDate}
          departureDates={departureDates}
          handleCardSelect={handleCardSelect}
          startDate={startDate}
          endDate={endDate}
        />
      )}

      <div className="share-button-container">
        {/* <ShareButton step={step} /> */}
      </div>
    </div>
  );
};

export default memo(DesktopHeader, (prev, curr) => { return (prev.currentDate === curr.currentDate && prev.step?.state === curr?.step.state) })

// const ShareButton = ({ step }: { step: any }) => {
//   // Local States
//   const [link, _] = useState(window.location.href);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);

//   const dispatch = useDispatch();

//   /**
//    * This function copies the url and opens the modal for further actions
//    */

//   const onClick = () => {
//     copyTextToClipboard(link);
//     if (step?.state !== 'end' && step?.state !== 'arrival') {
//       PauseAnimation();
//     }
//     dispatch(ActionsCreator.showSharePopup(true));
//   };
//   return (
//     <>
//       <SharePopup
//         link={link}
//         onClose={
//           step?.state !== 'end' && step?.state !== 'arrival'
//             ? PlayAnimation
//             : () => {}
//         }
//         setSnackbarOpen={setSnackbarOpen}
//         snackbarOpen={snackbarOpen}
//       />

//       <div
//         className="share-button-desktop"
//         style={{ backgroundColor: 'white', cursor: 'pointer' }}
//         onClick={onClick}
//       >
//         <svg
//           width="27"
//           height="23"
//           viewBox="0 0 27 23"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M14.349 6.09327V1.30072C14.349 0.220707 15.5415 -0.359799 16.3515 0.243207L16.491 0.364708L26.6094 10.3098C27.0819 10.7756 27.1246 11.5226 26.7376 12.0378L26.6094 12.1841L16.491 22.1359C15.7305 22.8829 14.4953 22.4037 14.3603 21.3844L14.349 21.1999V16.4839L13.7685 16.5356C9.71848 16.9699 5.83494 18.8396 2.09765 22.1764C1.22014 22.9594 -0.134369 22.2214 0.00963217 21.0379C1.13014 11.8353 5.82594 6.76152 13.8428 6.12702L14.349 6.09327Z"
//             fill="black"
//           />
//         </svg>
//       </div>
//     </>
//   );
// };
