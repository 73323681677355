import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import './feedbackmodal.css';
import FeedbackPopup from '~/components/FeedbackPopup';
import ActionsCreator from '~/redux/actions';
import { useDispatch } from 'react-redux';
import { useIsMobile } from '../counter/hooks/useMobile';
import { pulsateAnimation } from '~/utility/utils';
import { feedbackBlinkSignal } from '~/components/signals/blinkSignals';
import { useSignals } from '@preact/signals-react/runtime';

interface FeedBackModalProps {
  open: boolean;
  onClose: () => void;
}

export const FeedBackModal: React.FC<FeedBackModalProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useSignals();

  /**
   * Opens the feedback popup.
   */

  const handleFeedbackPopup = () => {
    // setAnchorElUser(null);
    dispatch(ActionsCreator.setFeedbackPopupState(true));
    feedbackBlinkSignal.value = false;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        // Use `BackdropComponent` to create a semi-transparent backdrop
        components={{
          Backdrop: () => (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'transparent',
                pointerEvents: 'none', // Allow clicks through the backdrop
              }}
            />
          ),
        }}
        sx={{
          position: 'absolute', // Position the element as absolute
          right: 0, // Align it to the right side of the container
          top: '50%', // Center it vertically
          transform: 'translateY(-50%)', // Offset its position by half its height
          width: 'auto', // Adjust width as needed
          height: '100%', // Or adjust height as needed
          zIndex: 500, // Ensure it is above other content
          pointerEvents: 'none', // Allow clicks through the element itself
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: 'auto',
            ...(!isMobile && { left: 0 }),
            ...(isMobile && { right: 0 }),
            top: '50%',
            transform: `translateY(-50%) translateX(${
              isMobile ? 30 : -40
            }%) rotate(270deg)`,
            // transform: 'translateY(-50%) rotate(270deg)',
            zIndex: 500,
            background: 'transparent',
            pointerEvents: 'auto', // Allow clicks on the modal content
          }}
        >
          <Button
            variant="contained"
            className="feedback-button"
            onClick={handleFeedbackPopup}
            sx={{
              ...(feedbackBlinkSignal.value && {
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  animation: `${pulsateAnimation} 3s infinite`,
                  borderRadius: '8px',
                  border: '5px solid rgba(255, 255, 255, 0.75)',
                  top: 0,
                  left: 0,
                  background: 'rgba(198, 16, 0, 0)',
                  boxSizing: 'border-box',
                },
              }),
            }}
          >
            <b className="feedback-text">Feedback</b>
          </Button>
        </Box>
      </Modal>
      <FeedbackPopup />
    </>
  );
};
