/**
 * An enumeration representing travel modes, potentially used for transportation selection or filtering.
 */
export enum TravelMode {
  /** Represents travel by car. */
  Car = 'Car',
  /** Represents travel by plane. */
  Plane = 'Plane',
  /** Represents travel by ferry. */
  Ferry = 'Ferry',
  /** Represents travel by transit. */
  Transit = 'Transit',
  /** Represents travel by transit. */
  Walk = 'Walk',
}
