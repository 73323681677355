/**
 * A component for displaying and managing individual audio files in a music library.
 * This component shows the file name, duration, and play/pause controls. It also handles fetching audio duration and playing or pausing the audio.
 *
 * @param file - An object representing the audio file, including its ID, name, and URL.
 * @param loading - A boolean indicating if a file is currently being processed.
 * @param isPlaying - A boolean indicating if the audio file is currently playing.
 * @param togglePlayPause - A function to toggle play/pause for the given audio file ID.
 * @param audioRef - A mutable reference to the HTMLAudioElement used for playback.
 * @param setAudioPlaying - A function to update the playback state of the audio.
 * @param setAudioState - A function to update the state of the currently selected audio file, including its ID, name, size, type, URL, and duration.
 *
 * @returns A React component that displays a single audio file with playback controls and duration.
 *
 * @example
 * ```tsx
 * <MusicLibrary
 *   file={{ id: '1', name: 'Song Title', url: 'path/to/audio.mp3' }}
 *   loading={false}
 *   isPlaying={false}
 *   togglePlayPause={togglePlayPauseHandler}
 *   audioRef={audioElementRef}
 *   setAudioPlaying={setAudioPlayingHandler}
 *   setAudioState={setAudioStateHandler}
 * />
 * ```
 */
import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import useStyles from '../styles';
import { formatDuration, getAudioDuration } from '~/utility/utils';

export interface IAudioFile {
  id: string;
  name: string;
  url: string;
}

interface MusicLibraryProps {
  file: IAudioFile;
  loading: boolean;
  isPlaying: boolean;
  togglePlayPause: (audioId: string) => void;
  audioRef: React.MutableRefObject<HTMLAudioElement | null>;
  setAudioPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  chooseFromLibrary: ({
    name,
    url,
    audioDuration,
  }: {
    name: string;
    url: string;
    audioDuration: number;
  }) => void;
}

const MusicLibrary: React.FC<MusicLibraryProps> = ({
  file,
  loading,
  isPlaying,
  togglePlayPause,
  audioRef,
  setAudioPlaying,
  chooseFromLibrary,
}) => {
  const classes = useStyles();

  const [audioDuration, setAudioDuration] = useState<number>(0);
  const [durationLoading, setDurationLoading] = useState(false);

  const handleAudioEnded = () => {
    setAudioPlaying(false);
  };

  useEffect(() => {
    const fetchAudioDuration = async () => {
      try {
        setDurationLoading(true);
        if (file) {
          const duration = await getAudioDuration(file);
          setAudioDuration(duration); // Set duration in state
        }
      } catch (error) {
        console.error('Error fetching audio duration:', error);
        // Handle error as needed (e.g., show error message)
      } finally {
        setDurationLoading(false);
      }
    };

    fetchAudioDuration(); // Call the async function inside useEffect
  }, [file]);

  return (
    <div
      style={{
        listStyle: 'none',
      }}
      className={classes.libraryList}
    >
      <div
        style={{
          position: 'relative',
          display: 'flex',
          gap: '.6rem',
          alignItems: 'center',
        }}
        onClick={() =>
          chooseFromLibrary({ name: file.name, url: file.url, audioDuration })
        }
      >
        <img
          alt="Music icon"
          src="/icons/music-disabled.svg"
          style={{
            position: 'relative',
          }}
        />

        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography classes={classes.fileSelectionText}>
            {file.name}
          </Typography>
          <Typography className={classes.fileSelectionSubtext}>
            {durationLoading ? (
              <CircularProgress color="primary" size={10} />
            ) : (
              <>{formatDuration(audioDuration)}</>
            )}
          </Typography>
        </div>
      </div>

      <button
        className={classes.playPauseButton}
        onClick={() => togglePlayPause(file.id)} // Pass the audio id to togglePlayPause
        disabled={loading}
        style={{
          cursor: loading ? 'disabled' : 'auto',
        }}
      >
        {isPlaying ? (
          <>
            <svg
              fill="currentColor"
              strokeWidth="0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              height="30px"
              width="30px"
              style={{
                overflow: 'visible',
                color: '#ED6934',
                cursor: 'pointer',
              }}
            >
              <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48Zm-32 288h-32V176h32Zm96 0h-32V176h32Z"></path>
            </svg>
          </>
        ) : (
          <img
            src="/icons/play-button.svg"
            alt="Play button"
            style={{
              cursor: 'pointer',
            }}
          />
        )}
      </button>
      {file?.url && audioRef && (
        <audio
          ref={audioRef}
          src={file.url}
          onEnded={handleAudioEnded}
          autoPlay={isPlaying}
          style={{ display: 'none' }}
        />
      )}
    </div>
  );
};

export default MusicLibrary;
