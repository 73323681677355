import {
  BufferGeometry,
  CatmullRomCurve3,
  Material,
  Mesh,
  Object3D,
} from 'three';
import {
  AnimationTravelData,
  MarkerInstances,
  Model,
  ModelDict,
} from '~/utility/models';
import { LngLatBounds, LngLatLike, Map } from 'maplibre-gl';
import { gsap, CSSPlugin } from 'gsap';
import { Marker } from './Marker';
import { projectToWorld } from '~/CustomThreeJsWrapper/utility/utils';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { Config } from './MultiTransportAnimationController';
import { Position } from '@turf/turf';


export interface PlaneAnimationConfig {
  duration: number; // Time in milliseconds
  curveHeight: number;
  onCompleteCallback: (() => void) | undefined;
}

export interface LandTransportAnimationConfig {
  duration: number; // Time in milliseconds
  streetLevelZoom?: number;
  onCompleteCallback: (() => void) | undefined;
}

export type AnimationConfig =
  | PlaneAnimationConfig
  | LandTransportAnimationConfig;

gsap.registerPlugin(CSSPlugin);

/**
 * Abstract base class for animation controllers handling plane and car animations.
 * Provides a foundation for concrete implementations like PlaneAnimationController
 * and CarAnimationController.
 */
abstract class AnimationController {
  /**
   * The timestamp (in milliseconds) at which the animation began.
   * This is used to calculate animation progress and determine its completion.
   * Initially set to 0.
   */
  animationStartTime: number = 0;

  /**
   * The current elapsed time of the animation.
   */
  timeElapsed: number = 0;

  /**
   * The timestamp (in milliseconds) at which the animation is expected to end.
   * This is used to check if the animation has finished playing.
   * Initially set to 0.
   */
  animationEndTime: number = 0;

  /**
   * A boolean flag indicating whether the animation has expired (finished playing).
   * This flag is typically updated based on the comparison of `animationStartTime`,
   * `animationEndTime`, and the current time. Initially set to `true`.
   */
  isAnimationExpired: boolean = true;

  /**
   * A reference to a `Material` object that defines the visual properties of the animated object.
   * This can be used to customize the appearance of the animation throughout its duration.
   */
  material!: Material;

  /**
   * The configuration object that defines the animation parameters and behavior.
   * This can be either an `ICarAnimationConfig` interface type (for car animations) or an
   * `IPlaneAnimationConfig` interface type (for plane animations).

   */
  animationConfig!: AnimationConfig | undefined;

  /**
   * A reference to a `MarkerAnimations` object that holds the animation definitions
   * for markers associated with the animated object.
   */
  markers: MarkerInstances | undefined;

  /**
   * A reference to a `CatmullRomCurve3` object that represents the path along which the
   * animated object travels. This curve defines the movement trajectory of the animation.
   */
  pathCurve: CatmullRomCurve3 | undefined;

  /**
   * A reference to a `BufferGeometry` object that represents the geometric data for the path
   * of the animation.
   */
  pathGeometry!: BufferGeometry;

  /**
   * A reference to a `Mesh` object that represents the visual representation of the path
   * (if applicable). This mesh is  created from the `pathGeometry`.
   */
  pathMesh!: Mesh | null;

  /**
   * A reference to a custom wrapper object (`CustomThreeJSWrapper`) that  encapsulates
   * functionalities specific to your project's use of the Three.js library.
   */
  tb!: CustomThreeJSWrapper;

  /**
   * A reference to a `Map` object that represent a map visualization within your
   * project.
   */
  map!: Map;

  /**
   * A reference to an `Object3D` object that  represents the 3D model being animated.
   * This object holds the geometry and visual properties of the animated entity.
   */
  model!: Object3D | null;

  /**
   * An integer value representing the index of the current animation within a larger set or
   * sequence. This can be used to identify the specific animation being played.
   */
  index!: number;

  /**
   * The current zoom level of the camera, expressed as a number.
   */
  cameraZoom!: number;

  /**
   * A constant value defining the duration (in milliseconds) of the camera zoom transition.
   * This is  used to smooth out changes in camera zoom during the animation.
   */
  ZOOM_TRANSITION_DURATION: number = 400;

  /**
   * A constant number defining the ideal zoom level for displaying the map at street level.
   * This value is  based on your map data and desired level of detail for street features.
   */
  streetLevelZoom: number = 15;

  /**
   * A number representing the current bearing (rotation) of the map in degrees.
   * A value of 0 degrees indicates north, while positive values rotate the map clockwise.
   */
  mapBearing: number = 0;

  /**
   * A number representing the current pitch (tilt) of the map in degrees.
   * A value of 0 degrees indicates a vertical view, while positive values tilt the map upwards.
   */
  mapPitch: number = 20;

  /**
   * A reference to an `AnimationTravelData` object (needs more information based on your code).
   * This object  holds data and logic related to animating travel paths or transitions on the map.
   */
  travelSegment!: AnimationTravelData | Config;

  gltf: Model | undefined;

  timeElapsedTillPause!: number;

  timeout!: NodeJS.Timeout;

  /**
   * Gets the initial zoom level for the start of the animation.
   *
   * @returns {number} The starting zoom level for the animation.
   */
  abstract getAnimationStartZoom(): number;

  /**
   * Gets the initial zoom level for the start of the animation.
   *
   * @param delta The time elapsed since the last update (in milliseconds)
   * @returns {number} The starting zoom level for the animation.
   */
  abstract update(delta: number): void;

  abstract clean(): void;

  /**
   * Sets up the animation timing based on the travel segment and configuration provided.
   *
   * @param {AnimationTravelData} travelSegment - The data representing the segment of travel to be animated.
   * @param {AnimationConfig} animationConfig - The configuration settings for the animation.
   */
  abstract setupAnimationTime(
    travelSegment: AnimationTravelData,
    animationConfig: AnimationConfig,
  ): void;

  /**
   * Starts the travel animation for the current travel segment.
   *
   * This function initiates the animation for the vehicle or plane along the travel path.
   * It takes an animation configuration object as input, which specifies properties
   * like animation duration, curve height (for flights), and a callback function
   * to be executed upon completion.
   *
   * @param animationConfig An object containing configuration parameters for the animation
   *                        - `duration` (number): The total duration of the animation in milliseconds
   *                        - `curveHeight` (number; optional for car animations): The height of the animation curve for planes
   *                        - `onCompleteCallback` (function): A callback function to be called when the animation finishes
   */
  abstract setupAnimation(animationConfig: AnimationConfig): void;

  /**
   * Sets up the animation for a specific travel segment (index)
   *
   * @param animationTravelData The travel data object for this segment
   * @param index The index of the segment within the animation data array
   */
  setup(
    animationTravelData: AnimationTravelData | Config,
    index: number,
    shouldSetupMarker: boolean,
  ): void {
    /**
     * Creates and stores markers for this travel segment
     *
     * @returns An array of markers ( GeoJSON source objects) for this segment
     */
    if (shouldSetupMarker)
      this.markers = this.setupMarker(
        animationTravelData as AnimationTravelData,
        index,
      );

    clearTimeout(this.timeout)


    this.index = index;
    console.log('setup at Index, ', this.index);
    /**
     * Stores the travel data object for this segment for easy reference
     */
    this.travelSegment = animationTravelData;

    if (
      (animationTravelData.travelSegmentConfig.modelsArray as ModelDict[])
        .length > 0
    ) {
      /**
       * Stores a reference to the 3D model associated with this segment
       */
      this.model = (
        animationTravelData.travelSegmentConfig.modelsArray as ModelDict[]
      )[0].model.scene;

      this.gltf = {
        scene: (
          animationTravelData.travelSegmentConfig.modelsArray as ModelDict[]
        )[0].model.scene,
        animations: (
          animationTravelData.travelSegmentConfig.modelsArray as ModelDict[]
        )[0].model.animations,
      };

      /**
       * Initially hides the 3D model until the animation starts
       */
      this.model.visible = false;
    }

    /**
     * Calculates the appropriate zoom level to fit the entire travel path on the map
     */
    this.calculateZoomToFitPath();
  }

  setLineLayerAndSources(): void {
    // Default implementation does nothing
  }

  /**
   * Updates the 3D model used in the animation.
   *
   * This function removes the previously loaded model from the scene
   * and adds the provided `model` to the scene. It then updates the internal
   * reference to the current model.
   *
   * @param model The new 3D model to be used in the animation
   */
  updateModel(model: ModelDict[]) {
    const modelRef = model[0].model;
    if (this.tb) {
      // Remove previous model from the scene
      if (this.model) {
        this.tb.remove(this.model);
      }

      // Update the current model reference
      this.model = modelRef.scene;

      this.gltf = modelRef;

      // Add the new model to the scene
      this.tb.add(this.model);
    }
  }

  /**
   * Cleans up resources used by the animation. This function is typically called
   * when the animation needs to be stopped or reset.
   *
   * - Resets animation start and end times.
   * - Sets the animation to expired.
   * - Calls the cleanup function on each marker instance.
   * - Removes event listeners for map movement.
   * - Removes the animation model and path mesh from the Three.js scene (if they exist).
   */
  cleanup() {
    if (this.model) this.tb.remove(this.model);
    if (this.pathMesh) this.tb.remove(this.pathMesh);

    this.clean();

    this.animationStartTime = 0;
    this.animationEndTime = 0;
    this.isAnimationExpired = true;

    if (this.markers) {
      for (const markerInstance of Object.values(
        this.markers as MarkerInstances,
      )) {
        markerInstance.cleanup();
      }
    }
  }

  onPlay() {
    this.animationStartTime = performance.now() - this.timeElapsedTillPause;
  }

  onPause() {
    this.timeElapsedTillPause = this.timeElapsed;
  }

  /**
   * Calculates the zoom level that fits the entire travel path on the map.
   * This function is  used internally by the TravelAnimation class to adjust
   * the map view to encompass the travel route.
   *
   * @internal
   */
  calculateZoomToFitPath() {
    const firstPointLngLat = this.travelSegment.decodedPath.path[0];
    const bounds = new LngLatBounds(
      firstPointLngLat as LngLatLike,
      firstPointLngLat as LngLatLike,
    );
    for (
      let index = 1;
      index < this.travelSegment.decodedPath.path.length;
      index++
    ) {
      const point = this.travelSegment.decodedPath.path[index];
      bounds.extend(point as LngLatLike);
    }
    const cameraConfigForBounds = this.map.cameraForBounds(bounds);

    if (cameraConfigForBounds) {
      this.cameraZoom =
        cameraConfigForBounds && cameraConfigForBounds.zoom
          ? cameraConfigForBounds.zoom
          : this.cameraZoom;
    }
  }

  setupModelForAnimation(): void {
    if (!this.model) {
      return;
    }

    // Adds the model and path mesh to the Three.js scene
    this.tb.add(this.model);

    // Ensures the model is visible in the scene
    if (!(this.model as Object3D).visible) {
      (this.model as Object3D).visible = true;
    }

    // Sets the initial position and orientation of the model based on the path curve
    (this.model as Object3D).up.set(0, 0, 1);
    let position = (this.pathCurve as CatmullRomCurve3).getPointAt(0);
    (this.model as Object3D).position.set(position.x, position.y, position.z);
    let nextPosition = (this.pathCurve as CatmullRomCurve3).getPointAt(
      0.000001,
    );
    const worldPos = (this.pathMesh as Mesh).localToWorld(nextPosition);
    (this.model as Object3D).lookAt(worldPos);
    (this.model as Object3D).scale.set(0, 0, 0);
  }

  /**
   * Initializes the animation for the travel path. This function adds the model and path mesh to the scene,
   * sets the initial position and orientation of the model, and prepares for animation.
   */
  async initializeModelAnimation(): Promise<void> {
    // Records the animation start and end times based on the animation configuration

    // handleAnimationState({ calendarStep: this.index });


    console.log('initializeModelAnimation at Index, ', this.index);

    const now = performance.now();
    this.animationStartTime = now;
    this.animationEndTime =
      now + (this.animationConfig as AnimationConfig).duration;
    this.isAnimationExpired = false;
  }

  /**
   * Sets up the callback function to be called when the animation completes
   */
  onAnimationCompleteCallback() {

    if ((this.animationConfig as AnimationConfig).onCompleteCallback) {
      if (this.model) (this.model as Object3D).visible = false;
      (this.animationConfig as AnimationConfig).onCompleteCallback!();
    }
  }

  /**
   * Sets up markers for the origin and destination of a travel segment
   *
   * @param travelSegment An object containing travel segment data including departure and arrival locations
   * @param index The index of the travel segment within the animation data
   * @returns An object containing references to the created origin and destination marker instances
   */
  setupMarker(travelSegment: AnimationTravelData, index: number) {
    const originMarkerInstance = new Marker(
      this.map,
      travelSegment.departure.location?.text as string,
      'Departure',
      travelSegment.departure,
      projectToWorld(travelSegment.departure.location?.coordinates as Position),
      this.tb,
      index,
    );

    const destinationMarkerInstance = new Marker(
      this.map,
      travelSegment.arrival.location?.text as string,
      'Arrival',
      travelSegment.arrival,
      projectToWorld(travelSegment.arrival.location?.coordinates as Position),
      this.tb,
      index,
    );

    originMarkerInstance.setup();
    destinationMarkerInstance.setup();

    return {
      origin: originMarkerInstance,
      destination: destinationMarkerInstance,
    };
  }

  /**
   * Disposes of resources associated with markers.
   *
   * Iterates over all marker keys in the `markers` object and checks if the corresponding marker exists.
   * If a marker exists, it's set to null, effectively disposing of it.
   */
  destroy() {
    if (this.markers) {
      for (const markerInstance of Object.values(
        this.markers as MarkerInstances,
      )) {
        markerInstance.cleanup();
        markerInstance.destroy();
      }
    }
    this.markers = undefined;
    this.clean();

    if (this.model) {
      this.tb.remove(this.model);
      this.tb.dispose(this.model);
      this.model = null;
    }

    if (this.pathMesh) {
      this.tb.remove(this.pathMesh);
      this.tb.dispose(this.pathMesh);

      this.pathMesh = null;
    }
  }
}

export { AnimationController };
