// React Imports
import React from 'react';

// MUI Imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

// Child Component Imports
import ScheduleSection from '~/components/ScheduleSection';

// Style Imports
import useStyles from './styles';

// Redux Imports
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~redux/actions';

// Utility Imports
import { TravelFormData } from '~/utility/models';

/**
 * Represents the props for the FormSection component.
 * @interface PointScheduleProps
 * @property {TravelFormData[]} travelData - Array of Travels
 * @property {string} currentPointAirport - Current point airport name
 * @property {string} currentPointCity - Current point city name
 * @property {string} currentPointCountry - Current point country name
 * @property {string} currentPointCategory - Current point airport name
 * @property {Function} handleClose - Close the PointSchedule Modal
 */
interface PointScheduleProps {
  travelData?: TravelFormData[];
  currentPointAirport?: string;
  currentPointCity?: string;
  currentPointCountry?: string;
  currentPointCategory?: string;
  handleClose?: () => void;
}

/**
 * Renders a PointSchedule Component for Travel Point Marker Detail
 * @param {PointScheduleProps} props - Props for PointSchedule Component
 * @returns {JSX.Element} Returns PointSchedule Component
 */
const PointSchedule = (props: PointScheduleProps) => {
  const dispatch = useDispatch();

  /**
   * This function handles open Travel Itinerary Popup
   */
  const handleItineraryPopUp = () => {
    dispatch(ActionsCreator.openTravelItinerary(true));
    dispatch(ActionsCreator.setScheduleSectionState(true));
    dispatch(ActionsCreator.openPopUP(false));
  };

  const classes = useStyles();

  return (
    <div>
      <Box className={classes.pointDetail}>
        <Grid container>
          <img
            src="/icons/cross.svg"
            alt="cancel"
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: '24px',
            }}
            onClick={props.handleClose}
          />
          <Grid item xs={6}>
            <Typography variant="h4" className={classes.airportName}>
              {props.currentPointAirport}
            </Typography>
            <Typography variant="h6" className={classes.location}>
              <img
                src="/icons/marker.svg"
                alt="Marker"
                className={classes.marker1}
              ></img>
              {props.currentPointCountry} - {props.currentPointCity}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button className={classes.button1}>
              {props.currentPointCategory}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.subheading}>
              Travel Associated with this Point
            </Typography>
          </Grid>
          <Grid padding={0} className={classes.gridContainer}>
            {props.travelData?.map(
              (travelPointItem: TravelFormData, index: number) => (
                <Grid item xs={12} marginBottom={2} key={index}>
                  <ScheduleSection travelPointItem={travelPointItem} />
                </Grid>
              ),
            )}
          </Grid>
          <Grid item xs={12}>
            <Button className={classes.button3} onClick={handleItineraryPopUp}>
              View Current Itinerary
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PointSchedule;
