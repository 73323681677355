import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useLayoutEffect,
} from 'react';
import {
  AnimatePresence,
  motion,
  useAnimation,
  useDragControls,
} from 'framer-motion';
import './style.css';
import { ArrowDownward, ChevronRight } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { isCalendarAnimating } from '../DesktopFooter/CalendarLine';
import { signal } from '@preact/signals-core';
import {
  mobile4thImagePostionSignal,
  PauseAnimation,
  PlayAnimation,
  playAnimationSignal,
  animationStateSignal,
  showPlayPauseButton,
} from '../common';
import PlayPauseButton from '~/components/PlayPauseButton';
const bottomsheetCollapseSignal = signal(0);
const Wrapper = memo(
  ({
    children,
    draggable,
    setOpened,
    loadState,
  }: {
    children: any;
    draggable: boolean;
    loadState: boolean;
    setOpened: any;
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    function onClose() {
      PlayAnimation();
      setTimeout(() => {
        PlayAnimation();
      }, 500);
      setIsOpen(false);
      setOpened(false);
    }

    function onOpen() {
      PauseAnimation();
      setIsOpen(true);
      setOpened(true);
    }

    const prevIsOpen = usePrevious(isOpen);
    const controls = useAnimation();
    const dragControl = useDragControls();

    function onDragEnd(event: any, info: any) {
      const shouldClose =
        info.velocity.y > 20 || (info.velocity.y >= 0 && info.point.y > 45);
      if (shouldClose) {
        controls.start('hidden');
        onClose();
      } else {
        controls.start('visible');
        onOpen();
      }
    }

    useEffect(() => {
      if (prevIsOpen && !isOpen) {
        controls.start('hidden');
      } else if (!prevIsOpen && isOpen) {
        controls.start('visible');
      }
    }, [controls, isOpen, prevIsOpen]);

    const handleDoubleClick = (e: any) => {
      switch (e.detail) {
        case 1:
          // console.log("click");
          break;
        case 2:
          if (!prevIsOpen && isOpen) {
            controls.start('hidden');
            setIsOpen(false);
            setOpened(false);
            // console.log("double click visible");
          } else if (prevIsOpen && !isOpen) {
            controls.start('visible');
            setIsOpen(true);
            setOpened(true);
            // console.log("double click hidden");
          }
          break;
        default:
          return;
      }
    };

    const [header, setHeader] = useState<any>(null);
    const [content, setContent] = useState<any>(null);
    const [collapsed, setCollapsed] = useState<any>(null);
    const [tripTo, setTripTo] = useState<any>(null);
    const [hiddenTranslateValue, setHiddenTranslateValue] = useState('100%');
    const contentRef = useRef<HTMLDivElement>(null);
    const navbarref = useRef<HTMLDivElement>(null);
    const [bottomSheetHeight, setBottomSheetHeight] = useState(0);
    const playPauseState = useSelector(
      (state: any) => state.AnimationReducers.playPauseState,
    );
    useEffect(() => {
      const unsub = bottomsheetCollapseSignal.subscribe(() => {
        setIsOpen(false);
      });
      controls.start('hidden');
      return () => {
        unsub();
      };
    }, []);

    useEffect(() => {
      children.forEach((child: any) => {
        if (child.type.displayName === 'Content') {
          setContent(React.cloneElement(child, { ref: contentRef }));
        } else if (child.type.displayName === 'Collapsed') {
          setCollapsed(child);
        } else if (child.type.displayName === 'Header') {
          setHeader(React.cloneElement(child, { ref: navbarref }));
        } else if (child.type.displayName === 'TripTo') {
          setTripTo(child);
        }
      });
    }, [children]);

    useEffect(() => {
      const updateTranslateValue = () => {
        if (contentRef.current && navbarref.current) {
          const contentHeight = getFooterAnimatorHiddenHeight(
            contentRef.current.clientHeight,
            navbarref.current.clientHeight,
          );
          setHiddenTranslateValue(contentHeight);
          mobile4thImagePostionSignal.value =
            contentRef.current.clientHeight +
            navbarref.current.clientHeight +
            50 +
            'px';
          setBottomSheetHeight(
            contentRef.current.clientHeight +
              navbarref.current.clientHeight +
              25,
          );
        }
      };
      setTimeout(updateTranslateValue, 1000);
    }, []);

    return (
      <AnimatePresence mode="sync">
        <div style={{ position: 'relative' }}>
          {showPlayPauseButton.value && !isOpen && (
            <PlayPauseButton
              playPauseState={playPauseState}
              bottom={bottomSheetHeight}
              left={'10px'}
              type={'mobile'}
            />
          )}

          {
            <motion.div
              // {...(draggable && { drag: 'y' })}
              drag="y"
              onDragEnd={onDragEnd}
              dragListener={false}
              initial={{ y: hiddenTranslateValue }}
              animate={controls}
              transition={{
                type: 'tween',
                duration: 0.2,
                ease: 'easeInOut',
              }}
              dragControls={dragControl}
              variants={{
                visible: { y: 0 },
                hidden: { y: hiddenTranslateValue },
                closed: { y: '100%' },
              }}
              key={hiddenTranslateValue}
              dragConstraints={{ top: 0 }}
              dragElastic={0.2}
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 999,
                backgroundColor: 'white',
                width: '100%',
                height: getFooterAnimatorMaxHeight(),
                border: '1px solid #E0E0E0',
                boxShadow:
                  '0px 2px 5px rgba(0, 0, 0, 0.06), 0px 2px 13px rgba(0, 0, 0, 0.12)',
                borderRadius: '15px 15px 0px 0px',
                paddingRight: '5px',
                paddingLeft: '5px',
                overflow: 'hidden',
                fontFamily: 'Manrope',
              }}
            >
              <>
                <motion.div
                  ref={navbarref}
                  onPointerDown={(e) => dragControl.start(e)}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.15 }}
                  onClick={() => {
                    if (isCalendarAnimating.peek()) {
                      return;
                    }
                    if (isOpen) {
                      onClose();
                    } else {
                      onOpen();
                    }
                  }}
                  style={{
                    borderBottom: loadState ? 'none' : '1px solid #E0E0E0',
                  }}
                  className="Navbar p-1 d-flex"
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.15 }}
                    className="DragHandleEdge"
                  >
                    <div className="DragHandle" />
                  </motion.div>
                  <motion.span
                    className="flex-1"
                    animate={{ opacity: loadState ? 0 : 1 }}
                    transition={{ delay: 0.15 }}
                  >
                    {' '}
                    {draggable ? header : tripTo}{' '}
                  </motion.span>
                  <motion.div
                    initial={{ rotate: -45 }}
                    animate={isOpen ? { rotate: 90 } : { rotate: -90 }}
                    transition={{ delay: 0.15 }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <ChevronRight />
                  </motion.div>
                </motion.div>
              </>
              <div
                ref={contentRef}
                className="p-1"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: draggable ? 'hidden' : 'visible',
                }}
              >
                {isOpen ? content : collapsed}
              </div>
            </motion.div>
          }
        </div>
      </AnimatePresence>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.children === nextProps.children;
  },
);

function Collapsed({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

Collapsed.displayName = 'Collapsed';

function Header({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

Header.displayName = 'Header';

function Content({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

Content.displayName = 'Content';

function usePrevious(value: any) {
  const previousValueRef = useRef();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
}

const TripTo = ({
  to,
  startDate,
  endDate,
}: {
  to: string;
  startDate: string;
  endDate: string;
}) => {
  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  return (
    <>
      <div className="TripTOMobileBottomSheet flex-1">
        <div className="d-flex">
          <span>Trip to </span>&nbsp;<b> {to} </b>
        </div>
        <div className="d-flex">
          {userName && (
            <>
              <span>Made by&nbsp;</span>
              <b>{userName}</b>
            </>
          )}
        </div>
      </div>
    </>
  );
};

TripTo.displayName = 'TripTo';

export const collapseBottomSheet = () => {
  bottomsheetCollapseSignal.value = bottomsheetCollapseSignal.peek() + 1;
};

export default {
  Wrapper,
  Collapsed,
  Header,
  Content,
  TripTo,
};

const getFooterAnimatorMaxHeight = () => {
  const windowHeight = window.innerHeight;
  const getHeightToSubtract = 100;
  return `${windowHeight - getHeightToSubtract}px`;
};

const getFooterAnimatorHiddenHeight = (
  contentHeight?: number,
  navbarHeight?: number,
) => {
  if (!contentHeight || !navbarHeight) {
    return '100%';
  }

  const mustHaveHeight = contentHeight + navbarHeight;
  const totalHeight = getFooterAnimatorMaxHeight();
  const hiddenHeight = parseInt(totalHeight) - mustHaveHeight;
  return `${hiddenHeight - 20}px`;
};

const getFooterAnimatorMaxHeightold = () => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  switch (true) {
    case windowWidth < 350:
      return '450px';
    case windowWidth < 400:
      return '500px';
    case windowWidth < 500:
      return '600px';
    case windowWidth < 600:
      return '700px';
    case windowWidth < 700:
      return '800px';
    default:
      return '900px';
  }
};
