import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '~/supabase/supabaseClient';
import {
  Content3DLayer,
  PublishableTravelData,
  PublishableTravelDataWithDecodedPath,
  PublishableTravelObject,
} from '~/utility/models';
import useStyles from './styles';
import { RootState, useSelector } from '~/redux/reducers';
import maplibregl, { Map } from 'maplibre-gl';
import { TravelAnimation } from '~/animationEngine/Travel/TravelAnimation';
import StaticTravelVisualizer from '~/animationEngine/StaticTravelVisualizer';
import { setupPublishableTravelObjectWithDecodedPath } from '~/utility/utils';
import ActionsCreator from '~/redux/actions';
import { AppDispatch, useDispatch } from '~/redux/store';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import {
  initializeMapLibreMap,
  initializeTravelAnimation,
  onConfigUpdateOfPublishableTravelData,
  setStaticTravelVisualizer,
} from '../helpers';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import {
  clickedIndexDirection,
  clickedIndexSignal,
  destinationStateSignal,
  durationSignal,
  emptyStateSignal,
  getDestinationPointData,
  pauseAnimationSignal,
  PlayAnimation,
  playAnimationSignal,
  provokeEmptyState,
  provokeEndState,
  setAnimationStateSignal,
  setImagesBySignal,
  showPlayPauseButton,
} from '~/components/ViewTravel/common';
import OrientationChangePopUp from '~/components/OrientationChangePopUp';
import { SplashScreen } from './SplashScreen';
import { FeedBackModal } from '~/components/ViewTravel/FeedbackModal';
import Tracker from '@openreplay/tracker';
import { LogoModal } from '~/components/ViewTravel/LogoModal';
import StatsOverlay from '~/components/ViewTravel/StatsOverlay';

export const DAYJS = dayjs;
DAYJS.extend(duration);
DAYJS.extend(isSameOrBefore);
DAYJS.extend(customParseFormat);
let lastClickedIndex = 0;


if(process.env.REACT_APP_OPENREPLAY_PROJECT_KEY) {
  /**
   * OpenReplay Tracker
   */
  const tracker = new Tracker({
    projectKey: process.env.REACT_APP_OPENREPLAY_PROJECT_KEY ?? '',
    __DISABLE_SECURE_MODE: false,
  });
  tracker.start();
}

export default function ViewTravel() {
  const location = useLocation();
  const classes = useStyles();

  const map = useRef<Map | null>();

  const isViewTravel = true;
  const [travelPoints, setTravelPoints] = useState<PublishableTravelData[]>([]);
  const [mapStyleIndex, setMapStyleIndex] = useState<number | null>(null);
  const [fullscreenState, setFullScreenState] = useState<boolean>();
  const [audio, setAudio] = useState<string | undefined>(undefined);
  const [fullScreenStateforIcon, setFullScreenStateforIcon] =
    useState<boolean>(false);
  const [isMobileLandscape, setIsMobileLandscape] = useState<boolean>(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [logo, setLogo] = useState<string>('');
  const [open, setOpen] = useState(true);

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const queryParams = new URLSearchParams(location.search);
  const travelVisualizer = useRef<StaticTravelVisualizer | null>();

  const isMounted = useRef<boolean | null>(true);
  const dispatch: AppDispatch = useDispatch();

  const [
    publishableAnimationTravelDataWithDecodedPath,
    setPublishableAnimationTravelDataWithDecodedPath,
  ] = useState<PublishableTravelDataWithDecodedPath[]>([]);
  const wrapper = useRef<CustomThreeJSWrapper | null>(null);
  const [loading, setLoading] = useState(true);
  const travelAnimation = useRef<TravelAnimation | null>();
  const playPauseState = useSelector(
    (state: RootState) => state.AnimationReducers.playPauseState,
  );
  const encodedTourID = queryParams.get('tourID');
  const tourID = decodeTourID(Number(encodedTourID));

  const handleClose = () => {
    setOpen(false);
  };

  // const stats1 = new Stats();
  // stats1.showPanel(0);
  // stats1!.dom.style.cssText =
  //   'position:absolute;top:0px;left:0px; z-index: 100;';
  // const stats2 = new Stats();
  // stats2.showPanel(1);
  // stats2!.dom.style.cssText =
  //   'position:absolute;top:0px;left:80px; z-index: 100;';
  // const stats3 = new Stats();
  // stats3.showPanel(2);
  // stats3!.dom.style.cssText =
  //   'position:absolute;top:0px;left:160px; z-index: 100;';

  function decodeTourID(encodedTourID: number) {
    return (encodedTourID - 100010) / 9;
  }

  async function getTravelItinerary(tourID: number) {
    const { data } = await supabase
      .from('Publish Travel')
      .select('publishable_data')
      .eq('id', tourID);

    return data;
  }

  const handleAnimationEnd = () => {
    // Set playPauseState to true when animation ends
    provokeEndState(true);
    showPlayPauseButton.value = false;
    dispatch(ActionsCreator.setPlayPauseState(true));
  };

  useEffect(() => {
    getTravelItinerary(tourID).then(
      (data: { publishable_data: PublishableTravelObject }[] | null) => {
        if (isMounted.current) {
          setTravelPoints(data![0].publishable_data.travelPoints);
          setMapStyleIndex(data![0].publishable_data.mapStyleIndex as number);
          setAudio(data![0].publishable_data?.audio ?? undefined);
          ActionsCreator.setUserName(data![0].publishable_data.userName ?? '');
          setLogo(data![0].publishable_data.logo ?? '');
        }
      },
    );

    if (document.documentElement.requestFullscreen!) setFullScreenState(true);
    else setFullScreenState(false);
  }, [tourID]);

  const content3DLayer: Content3DLayer = {
    id: 'custom-threejs-layer',
    type: 'custom',
    renderingMode: '3d',
    render: () => {
      if (wrapper.current) wrapper.current.update();

      travelAnimation.current?.update();
    },
  };

  const onResourcesLoadingStateUpdate = (newLoadingState: boolean) => {
    setLoading(newLoadingState);
  };

  useEffect(() => {
    const setupMap = async () => {
      if (!map.current && mapStyleIndex !== null) {
        try {
          await initializeMapLibreMap(
            map as React.MutableRefObject<maplibregl.Map>,
            mapContainer as React.MutableRefObject<HTMLDivElement>,
            mapStyleIndex,
            wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
            content3DLayer,
            isViewTravel,
            false,
            false,
          );
        } finally {
          setLoading(false); // Set loading to false after initialization is complete
        }
      }
    };

    setupMap();
  }, [mapStyleIndex]);

  useEffect(() => {
    if (travelPoints.length > 0) {
      const travelData = Promise.all(
        travelPoints.map((data) =>
          setupPublishableTravelObjectWithDecodedPath(
            data,
            data.travelSegmentConfig.modelEnum,
            data.travelSegmentConfig.modelScale,
            data.travelSegmentConfig.animationSpeed,
          ),
        ),
      );
      travelData.then((data) => {
        setPublishableAnimationTravelDataWithDecodedPath(data);
      });
    }
  }, [travelPoints]);

  useEffect(() => {
    if (!playPauseState)
      if (!travelAnimation.current)
        initializeTravelAnimation(
          travelAnimation as React.MutableRefObject<TravelAnimation>,
          publishableAnimationTravelDataWithDecodedPath,
          map as React.MutableRefObject<maplibregl.Map>,
          wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
          onResourcesLoadingStateUpdate,
          isViewTravel,
          handleAnimationEnd,
        );
      else
        onConfigUpdateOfPublishableTravelData(
          false,
          travelAnimation as React.MutableRefObject<TravelAnimation>,
          publishableAnimationTravelDataWithDecodedPath,
        );
  }, [playPauseState]);

  useEffect(() => {
    const playAnimationSub = playAnimationSignal.subscribe((val) => {
      if (val > 1) {
        travelAnimation.current?.setPlayPause(false);
        // setTimeout(() => {
        //   travelAnimation.current?.setPlayPause(false);
        // }, 100);
      }
    });

    const pauseAnimationSub = pauseAnimationSignal.subscribe((val) => {
      if (val) {
        travelAnimation.current?.setPlayPause(true);
      }
    });
    // Function to handle visibility changes
    const handleVisibilityChange = async () => {
      // If the document is hidden, pause the animation
      if (document.visibilityState === 'hidden') {
        travelAnimation.current?.setPlayPause(true);
      }
      // If the document is visible, play the animation
      else {
        dispatch(ActionsCreator.setPlayPauseState(true));
      }
    };

    // Add an event listener to detect visibility changes
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to be called when the component unmounts or when the dependencies change
    return () => {
      playAnimationSub();
      pauseAnimationSub();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    setStaticTravelVisualizer(
      publishableAnimationTravelDataWithDecodedPath,
      true,
      travelVisualizer as React.MutableRefObject<StaticTravelVisualizer>,
      map as React.MutableRefObject<maplibregl.Map>,
      wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
    );
  }, [publishableAnimationTravelDataWithDecodedPath]);

  useEffect(() => {
    if (travelVisualizer.current && !playPauseState) {
      travelVisualizer.current?.clearTravel();
    }
  }, [playPauseState]);

  const setPlayPauseState = () => {
    travelAnimation.current?.setPlayPause(false);
  };

  useEffect(() => {
    return () => {
      if (travelAnimation.current) {
        travelAnimation.current.clean();
        travelAnimation.current.destroy();
        travelAnimation.current = null;
      }
      // dispatch(ActionsCreator.setPlayPauseState(true));
      if (map.current) {
        map.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      const isMobile = window.innerWidth <= 769;
      const orientation = window.screen.orientation;

      const landscape =
        orientation.angle === 90 ||
        orientation.angle === -90 ||
        (orientation.type && orientation.type.includes('landscape'));

      const shouldAlert = isMobile && landscape;

      setIsMobileLandscape(shouldAlert);
    };

    // Add event listeners for both orientation change and window resize
    window.addEventListener('orientationchange', handleOrientationChange);

    handleOrientationChange(); // Initial check

    return () => {
      // Clean up the event listeners
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  // useEffect(() => {
  //   const mapElement = mapContainer.current;
  //   if (mapElement) {
  //     mapElement.appendChild(stats1.dom);
  //     mapElement.appendChild(stats2.dom);
  //     mapElement.appendChild(stats3.dom);
  //   }

  //   return () => {
  //     if (mapElement) {
  //       mapElement.removeChild(stats1.dom);
  //       mapElement.removeChild(stats2.dom);
  //       mapElement.removeChild(stats3.dom);
  //     }
  //   };
  // });

  const handleFullScreenToggle = () => {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => {
          setFullScreenStateforIcon(false);
        })
        .catch((err) => console.error(err));
    } else {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().then(() => {
          setFullScreenStateforIcon(true);
        });
      }
    }
  };

  const startTravelAtIndex = async (index: number) => {
    if (lastClickedIndex < index) {
      clickedIndexDirection.value = 'fwd';
    } else {
      clickedIndexDirection.value = 'bwd';
    }
    lastClickedIndex = index;
    durationSignal.value = 0;
    provokeEndState(false);
    clickedIndexSignal.value = index;
    provokeEmptyState();
    destinationStateSignal.value = getDestinationPointData(index);
    setImagesBySignal([]);
    if (index === 0) {
      emptyStateSignal.value = 0;
    }
    setAnimationStateSignal((prev: any) => ({
      ...prev,
      calendarStep: index > 0 ? index : undefined,
    }));

    await travelAnimation.current?.reset(
      publishableAnimationTravelDataWithDecodedPath,
      false,
    );
    PlayAnimation();
  };

  if (tourID === undefined) {
    return <div>No tour ID provided</div>;
  }

  return (
    <div>
      <div className={classes.mapsMainContainer} ref={mapContainer} />

      {travelPoints.length > 0 && (
        <StatsOverlay
          travelPoints={travelPoints}
          handleDateSelect={startTravelAtIndex}
          mapStyleIndex={mapStyleIndex || 0}
          audio={audio}
        />
      )}

      {!showSplashScreen && (
        <OrientationChangePopUp isOpen={isMobileLandscape} />
      )}

      {!showSplashScreen && <FeedBackModal open={open} onClose={handleClose} />}

      {!showSplashScreen && logo && (
        <LogoModal open={open} onClose={handleClose} logo={logo} />
      )}

      {showSplashScreen && (
        <SplashScreen
          loading={loading}
          closeSplashScreen={() => setShowSplashScreen(false)}
        />
      )}
    </div>
  );
}
